export const mask = userInput => {
    const numbers = userInput.match(/\d/g)
    let numberLength = 0
    if (numbers) {
      numberLength = numbers.join('').length
    }
    if (numberLength == 13) {
        return [
          /[1-9]/,
          /[1-9]/,
          /[1-9]/,
          /[1-9]/,
          ' ',
          /[1-9]/,
          /[1-9]/,
          /[1-9]/,
          /[1-9]/,
          ' ',
          /[1-9]/,
          /[1-9]/,
          /[1-9]/,
          /[1-9]/,
          /[1-9]/
        ]
    }
    if (numberLength == 15) {
      return [
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        ' ',
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        ' ',
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/
      ]
    }
    if (numberLength == 19) {
      return [
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        ' ',
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/
      ]
    }
    return [
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        ' ',
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        ' ',
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        ' ',
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/
    ]
  }
  export default mask