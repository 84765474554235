import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { FirebaseContext } from "../firebase";


const UnverifiedUserRoute = props => {
    let  { claims } = useContext(FirebaseContext);
    claims = claims ? claims : JSON.parse(localStorage.getItem('claims'));
    // let user = JSON.parse(localStorage.getItem('user'));
    return (claims ? <Route {...props}/>
        : <Redirect to="/" />) 
}

export default UnverifiedUserRoute;