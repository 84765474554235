import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export const DisplayModal = (props) => {
    const { toggle, handleToggle, message, firstText, secondText= "Cancel", headerText ="Info", firstMethod = handleToggle } = props;

    return <Modal show={toggle} onHide={() => handleToggle(!toggle)}>
        <Modal.Header closeButton>
            <Modal.Title>{headerText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
            {firstText && <Button variant="secondary" onClick={() => firstMethod()}>
                {firstText}
            </Button>}
            {secondText && <Button variant="primary" onClick={() => handleToggle(!toggle)}>
                {secondText}
            </Button>}
        </Modal.Footer>
    </Modal>
}