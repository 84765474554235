import lodash from 'lodash';
import firebase from "../firebase";

export default class Teacher {
  static collectionRef() {
    return firebase.db.collection('teacherProfile');
  }

  static getAllTeachers() {
    return new Promise((resolve, reject) => {
      Teacher.collectionRef().get().then(snapshot => {
        if (snapshot.size) {
          return resolve(snapshot.docs.map(d => new Teacher(d)))
        }
        else resolve([])
      }).catch((e) => {
        reject()
      })
    })
  }

  static getProfileByUserId(userId) {
    return new Promise((resolve, reject) => {
      Teacher.collectionRef()
        .where('userId', '==', userId)
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            return resolve(new Teacher(snapshot.docs[0]))
          }
          else resolve('')
        }).catch((e) => {
          reject()
        })
    })
  }

  static getProfileById(id) {
    return new Promise((resolve, reject) => {
      Teacher.collectionRef()
        .doc(id)
        .get()
        .then(snapshot => {
          if (!snapshot.empty) {
            return resolve(new Teacher(snapshot))
          }
          else resolve('')
        }).catch((e) => {
          reject()
        })
    })
  }


  static uploadImage(fileObj, id){
    return new Promise ((resolve, reject) => {
      const rootRef = firebase.storage.ref();
      const fileRef = rootRef.child(`teacherProfile/${id}/`+fileObj.file.name);
      fileRef.put(fileObj.file).then(async snapshot => {
        if(snapshot){
          const imageUrl = await snapshot.ref.getDownloadURL();
        resolve(imageUrl)
        }
        console.log('upload successfull', snapshot);
      }).catch(err => {
        reject(err);
        console.log('error while uploading', err);
      });
    }) 
  }

  constructor(snapshot) {
    if (snapshot) {
      this._id = snapshot.id;
      this._data = snapshot.data() || {};
      this._ref = snapshot.ref;
    } else {
      this._ref = Teacher.collectionRef().doc();
      this._id = this._ref.id;
      this._data = {};
    }
  }

  save() {
    const _self = this;
    return new Promise((resolve, reject) => {
      _self._ref.set(this.data).then(() => {
        resolve(_self);
      }).catch((err)=>{
        reject(err);
      });
    });
  }


  get id() {
    return this._id;
  }

  set id(id) {
    return this._id = id;
  }

  get ref() {
    return this._ref;
  }

  get data() {
    return lodash.pickBy(this._data, (v) => v !== null && v !== undefined);
  }

  set data(data) {
    return this._data = lodash.pickBy(data, (v) => v !== null && v !== undefined);;
  }

  get firstName() {
    return this._data.firstName;
  }

  set firstName(firstName) {
    this._data.firstName = firstName;
  }

  get plan() {
    return this._data.plan;
  }

  set plan(plan) {
    this._data.plan = plan;
  }


  get userId() {
    return this._data.userId;
  }

  set userId(userId) {
    this._data.userId = userId;
  }

  get lastName() {
    return this._data.lastName;
  }

  set lastName(lastName) {
    this._data.lastName = lastName;
  }

  get email() {
    return this._data.email;
  }

  set email(email) {
    this._data.email = email;
  }

  get addressLine1() {
    return this._data.addressLine1;
  }

  set addressLine1(addressLine1) {
    this._data.addressLine1 = addressLine1;
  }

  get addressLine2() {
    return this._data.addressLine2;
  }

  set addressLine2(addressLine2) {
    this._data.addressLine2 = addressLine2;
  }

  get city() {
    return this._data.city;
  }

  set city(city) {
    this._data.city = city;
  }

  get state() {
    return this._data.state;
  }

  set state(state) {
    this._data.state = state;
  }

  get zip() {
    return this._data.zip;
  }

  set zip(zip) {
    this._data.zip = zip;
  }

  get cellPhone() {
    return this._data.cellPhone;
  }

  set cellPhone(cellPhone) {
    this._data.cellPhone = cellPhone;
  }

  get preferredContactMethod() {
    return this._data.preferredContactMethod;
  }

  set preferredContactMethod(preferredContactMethod) {
    this._data.preferredContactMethod = preferredContactMethod;
  }

  get dob() {
    return this._data.dob;
  }

  set dob(dob) {
    this._data.dob = dob;
  }

  get YYTCredentials() {
    return this._data.YYTCredentials;
  }

  set YYTCredentials(YYTCredentials) {
    this._data.YYTCredentials = YYTCredentials;
  }

  get yearsTraining() {
    return this._data.yearsTraining;
  }

  set yearsTraining(yearsTraining) {
    this._data.yearsTraining = yearsTraining;
  }

  get studioAffilication() {
    return this._data.studioAffilication;
  }

  set studioAffilication(studioAffilication) {
    this._data.studioAffilication = studioAffilication;
  }

  get specialities() {
    return this._data.specialities;
  }

  set specialities(specialities) {
    this._data.specialities = specialities;
  }

  get bio() {
    return this._data.bio;
  }

  set bio(bio) {
    this._data.bio = bio;
  }

  get coverImage() {
    return this._data.coverImage;
  }

  set coverImage(coverImage) {
    this._data.coverImage = coverImage;
  }

  get videoUrl() {
    return this._data.videoUrl;
  }

  set videoUrl(videoUrl) {
    this._data.videoUrl = videoUrl;
  }

  get profileImage() {
    return this._data.profileImage;
  }

  set profileImage(profileImage) {
    this._data.profileImage = profileImage;
  }

  get instructorInsurance() {
    return this._data.instructorInsurance;
  }

  set instructorInsurance(instructorInsurance) {
    this._data.instructorInsurance = instructorInsurance;
  }

  get friendlyUrl() {
    return this._data.friendlyUrl;
  }

  set friendlyUrl(friendlyUrl) {
    this._data.friendlyUrl = friendlyUrl;
  }

  get timeZone() {
    return this._data.timeZone;
  }

  set timeZone(timeZone) {
    this._data.timeZone = timeZone;
  }

  get fullName() {
    return `${this._data.firstName} ${this._data.lastName}`;
  }

}