import React, { Component } from 'react';

class ClassAttendance extends Component{
    render() {
        return(
            <div className="body-header-space">
                <h1>
                    Class Attendance
                </h1>
            </div>
        );
    }
}

export default ClassAttendance;