var firebaseConfig = {
  apiKey: "AIzaSyBDWS93IHZkFYn9DOuv_rXIsKM6BPAiZ3Y",
  authDomain: "omni-277218.firebaseapp.com",
  databaseURL: "https://omni-277218.firebaseio.com",
  projectId: "omni-277218",
  storageBucket: "omni-277218.appspot.com",
  messagingSenderId: "1044284550948",
  appId: "1:1044284550948:web:067b50098a607a1d7d4db1"
};

export default firebaseConfig;


