import lodash from 'lodash';
import firebase from "../firebase";
import Student from './student';
import Teacher from './teacher';
import Class from './Classes';

export default class ClassRoster {
  static collectionRef() {
    return firebase.db.collection('ClassRoster');
  }

  static getAllClassRoster() {
    return new Promise((resolve, reject) => {
      ClassRoster.collectionRef().get().then(snapshot => {
        if (snapshot.size) {
          return resolve(snapshot.docs.map(d => new ClassRoster(d)))
        }
        else resolve([])
      }).catch((e) => {
        reject()
      })
    })
  }



  static getAllClassRosterByUserId(id) {
    return new Promise((resolve, reject) => {
      ClassRoster.collectionRef()
        .where('userId', '==', id)
        .get().then(snapshot => {
          if (snapshot.size) {
            return resolve(snapshot.docs.map(d => new ClassRoster(d)))
          }
          else resolve([])
        }).catch((e) => {
          reject()
        })
    })
  }

  static getByIdUserIdClassId(userId, classId){
    return ClassRoster.collectionRef()
    .where('userId', '==', userId)
    .where('classId', '==', classId)
  }

  static deleteClassRosterByUserIdClassId(userId, classId) {
    return new Promise((resolve, reject) => {
      ClassRoster.getByIdUserIdClassId(userId, classId)
        .get().then(snapshot => {
          if (snapshot.size) {
              return new ClassRoster(snapshot.docs[0])
          }
          else resolve([])
        }).then(async(roster) => {
            await ClassRoster.collectionRef().doc(roster.id).delete()
            resolve()
        }).catch((e) => {
          reject()
        })
    })
  }


  static getAllClassesByClassRosters(id) {
    return new Promise((resolve, reject) => {
      ClassRoster.collectionRef()
        .where('userId', '==', id)
        .get().then(snapshot => {
          if (snapshot.size) {
            let classPromises = snapshot.docs.map(async (cr) => {
              cr = new ClassRoster(cr)
              let studentClass = cr.classId ? await Class.getById(cr.classId): {};
              let teacher = studentClass.teacherId ? await Teacher.getProfileByUserId(studentClass.teacherId): {};
              return {studentClass, teacher}
            })
            resolve(Promise.all(classPromises))
          }
        })
    })
  }


  static getAllCallRosterByClassId(classId) {
    return new Promise((resolve, reject) => {
      ClassRoster.collectionRef()
        .where('classId', '==', classId)
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            let crPromises = snapshot.docs.map(async (d) => {
              d = new ClassRoster(d);
              let student = await Student.getProfileByUserId(d.userId);
              d.studentName = student.FullName;
              return d
            })
            resolve(Promise.all(crPromises))
          }
          else resolve([])
        })
        .catch(e => {
          reject(e)
        })

    })
  }

  constructor(snapshot) {
    if (snapshot) {
      this._id = snapshot.id;
      this._data = snapshot.data() || {};
      this._ref = snapshot.ref;
    } else {
      this._ref = ClassRoster.collectionRef().doc();
      this._id = this._ref.id;
      this._data = {};
    }
  }

  save() {
    const _self = this;
    return new Promise((resolve, reject) => {
      _self._ref.set(this.data).then(() => {
        resolve(_self);
      });
    });
  }

  static async add(params) {
    const newClassRoster = new ClassRoster();
    try {
      if (params.classId) newClassRoster.classId = params.classId;
      if (params.userId) newClassRoster.userId = params.userId;
      if (params.paymentStatus) newClassRoster.paymentStatus = params.paymentStatus;
      if (params.amount) newClassRoster.amountPaid = params.amount;
      const batch = firebase.db.batch();
      batch.set(newClassRoster.ref, newClassRoster.data);
      await batch.commit();
    } catch (err) {
      throw err
    }
  }


  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get data() {
    return lodash.pickBy(this._data, (v) => v !== null && v !== undefined);
  }

  get studentName() {
    return this._data.studentName;
  }

  set studentName(studentName) {
    this._data.studentName = studentName;
  }


  get userId() {
    return this._data.userId;
  }

  set userId(userId) {
    this._data.userId = userId;
  }

  get classId() {
    return this._data.classId;
  }

  set classId(classId) {
    this._data.classId = classId;
  }


  get paymentStatus() {
    return this._data.paymentStatus;
  }

  set paymentStatus(paymentStatus) {
    this._data.paymentStatus = paymentStatus;
  }


  get amountPaid() {
    return this._data.amountPaid;
  }

  set amountPaid(amountPaid) {
    this._data.amountPaid = amountPaid;
  }

}