
import React, { useCallback, useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Container, Row, Col, Image, Card, FormGroup, FormControl, Modal, Spinner } from 'react-bootstrap';
import * as yup from "yup";
import Dropzone from 'react-dropzone';
import DateTimePicker from 'react-date-picker';
import TimezonePicker from 'react-bootstrap-timezone-picker';

import Teacher from '../../../../firebase/model/teacher';
import firebase, { FirebaseContext } from "../../../../firebase";
import TeacherClassContext from "../Context";
import MaskedInput from 'react-text-mask';
import mask from '../../../../utils/mask';
import FlexieLogo_green from '../../../../img/Flexie-logo_FINAL.svg';
import FlexieLogo_green_alt from '../../../../img/Flexie-logo_FINAL-green-alt.svg';
import FlexieIcon from '../../../../img/FlexieIcon.svg';
import FlexieIconReverse from '../../../../img/FlexieIcon-reverse.svg';
TeacherAccountProfile.teacher = {}

export default function TeacherAccountProfile(props) {
    const { teacherProfileDetails } = useContext(TeacherClassContext);
    const Specialties = ['Meditation', 'Yin', 'Yoga Nidra', 'Healing Arts'];
    const schema = yup.object().shape({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup
            .string()
            .email("Please enter a valid email")
            .required("Email is required"),
        addressLine1: yup.string().required("Address is required"),
        YYTCredentials:  yup.string().required("please select your credentials"),
        yearsTraining: yup.string().required("please provide your years of training"),
        friendlyUrl: yup.string().url('enter a valid url (e.g https://www.xyz.com)'),
        dob: yup.date().required('please select date of birth'),
        timeZone: yup.string().required('Please select timezone'),
        city: yup.string().required("City is required"),
        state: yup.string().required("State is required"),
        zip: yup.number().required("Zip Code is required")
    });

    
    const { register, handleSubmit, errors, control, reset, getValues, setValue } = useForm({
        validationSchema: schema,
        defaultValues: {}
    });
    
    const [firebaseError, setFirebaseError] = useState(null);
    const [plan, setPlan] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [toggle, handleToggle] = useState(false);
    const [coverImage, setCoverImages] = useState(null);
    const [profileImage, setProfileImages] = useState(null);
    const [ validateProfileImage, setValidationError] = useState(null);

    const handleChange = (e) => {
            TeacherAccountProfile.teacher[e.target.name] = e.target.value;
            setPlan(e.target.value);
    }

    const populateProfile = () => {
        TeacherAccountProfile.teacher = teacherProfileDetails
        TeacherAccountProfile.teacher.plan && setPlan(TeacherAccountProfile.teacher.plan)
        reset({
            ...TeacherAccountProfile.teacher.data,
            dob: TeacherAccountProfile.teacher.dob &&
            new Date(TeacherAccountProfile.teacher.dob.seconds ?
                 TeacherAccountProfile.teacher.dob.toDate() 
                 : TeacherAccountProfile.teacher.dob)
        });
        setLoading(false);
    }

    useEffect(() => {
        if(Object.keys(teacherProfileDetails).length){
            populateProfile();
        } 
    }, [teacherProfileDetails])

    const onDropCoverImage = useCallback((acceptedFiles, name) => {
        console.log('accepted files', acceptedFiles);
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            if (file.type.match(/image.*/)) {
                reader.onload = function (e) {
                    TeacherAccountProfile.teacher.coverImage = reader.result
                    setCoverImages({ file, imageUrl: reader.result })
                };
                reader.readAsDataURL(file);
            }
            else {
                setMessage('File not supported, please select image file for cover Image')
                handleToggle(!toggle)
            }
        }
    }, []);

    const onDropProfileImage = useCallback((acceptedFiles) => {
        console.log('accepted files', acceptedFiles);
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            if (file.type.match(/image.*/)) {
                reader.onload = function (e) {
                    TeacherAccountProfile.teacher.profileImage = reader.result
                    setProfileImages({ file, imageUrl: reader.result })
                };
                reader.readAsDataURL(file);
            }
            else { 
                setMessage('File not supported, please select image file for profile Image')
                handleToggle(!toggle)
            }
        }
    }, []);

    const onSubmit = async (data) => {
        if (!profileImage && !TeacherAccountProfile.teacher.profileImage ) {
            setValidationError('Please Upload a profile Image.')
            return
        }
        else {
            setLoading(true);
            try {
                if (coverImage)
                    TeacherAccountProfile.teacher.coverImage = await Teacher.uploadImage(coverImage, TeacherAccountProfile.teacher.id)
                if (profileImage)
                    TeacherAccountProfile.teacher.profileImage = await Teacher.uploadImage(profileImage, TeacherAccountProfile.teacher.id);
                TeacherAccountProfile.teacher.data = { ...TeacherAccountProfile.teacher.data, ...data };
                await TeacherAccountProfile.teacher.save();
                await firebase.updateUserProfile(TeacherAccountProfile.teacher.data.userId)
                populateProfile();
            } catch (err) {
                setFirebaseError(err)
                setLoading(false);
                handleToggle(!toggle)
            }
        }
        window.scrollTo(0, 0);
    }
    return (<div>
            <Container className="body-header-space teacher-profile-page">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="pt-4 mb-4">
                        <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                            <h1 className="salmon large float-left">
                                <i className="fas fa-user-circle mr-3" />
                            teacher profile
                            </h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={10} lg={8}>
                            <Card className="green-bg mt-3 rounded-40">
                                <Card.Body>
                                    <h3 className="yellow p-2">Flexie plan</h3>
                                    <Row className="row-eq-height p-4">
                                        <Col xs={12} md={6}>
                                            <Card className={`${plan === 'byo' ? 'yellow-bg' : 'green-bg green-alt-border'} h-100 rounded-40 plan`}>
                                                <Row className="card-body d-flex flex-row row-eq-height justify-content-center align-items-center">
                                                    <Col xs={6} lg={5}>
                                                        <h1 className={`${plan === 'byo' ? 'green' : 'green-alt'} text-right w-100`}>BYO</h1>
                                                    </Col>
                                                    <Col xs={6} lg={7}>
                                                        <Image src={plan == 'byo' ? FlexieLogo_green : FlexieLogo_green_alt} className="img-fluid text-right w-100 mr-2" />
                                                    </Col>
                                                    {(plan === 'full setup' || !plan) &&
                                                        <button
                                                            type="button"
                                                            name="plan"
                                                            value="byo"
                                                            onClick={handleChange}
                                                            className="btn btn-info btn-float green select-btn"
                                                        >
                                                            <Image src={FlexieIcon} className="btn-icon" />Select</button>}
                                                </Row>
                                            </Card>
                                        </Col>
                                        <Col xs={12} md={6} className="mt-sm-5 mt-md-0">
                                            <Card className={`${plan === 'full setup' ? 'yellow-bg' : 'green-bg green-alt-border'}  mt-5 mt-sm-0 rounded-40 plan`}>
                                                <Card.Body>
                                                    <Row className="d-flex flex-row row-eq-height justify-content-center align-items-center">
                                                        <Col xs={6} lg={5}>
                                                            <h1 className={plan !== 'full setup' ? "green-alt text-center w-100" : "green text-center w-100"}>Full Setup</h1>
                                                        </Col>
                                                        <Col xs={6} lg={7}>
                                                            <Image src={plan !== 'full setup' ? FlexieLogo_green_alt : FlexieLogo_green} className="img-fluid" />
                                                        </Col>
                                                        {(plan === 'byo' || !plan) &&
                                                            <button
                                                                type="button"
                                                                name="plan"
                                                                onClick={handleChange}
                                                                value="full setup"
                                                                className="btn btn-info btn-float green select-btn">
                                                                <Image src={FlexieIcon} className="btn-icon" />Select</button>}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="salmon-bg mt-3 rounded-40">
                                <Card.Body>
                                    <h3 className="green p-2">Contact Info</h3>
                                    <Form.Group className="form-group row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">First Name*</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                required
                                                name="firstName"
                                                type="text"
                                                placeholder="First Name"
                                                autoComplete="off"
                                                ref={register}>
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Last Name*</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                required
                                                name="lastName"
                                                type="text"
                                                placeholder="Last Name"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Email Address*</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                required
                                                name="email"
                                                type="text"
                                                placeholder="Email"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                            {(errors['email'] && errors['email'].type === "email") && <span className="alert">{errors['email'].message}</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row mt-5">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Address Line 1*</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                required
                                                name="addressLine1"
                                                type="text"
                                                placeholder="Address Line 1"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Address Line 2</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                name="addressLine2"
                                                type="text"
                                                placeholder="Address Line 2"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">City*</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                required
                                                name="city"
                                                type="text"
                                                placeholder="City"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Row className="w-100 no-gutters">
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="row">
                                                <Form.Label className="col-sm-4 col-lg-6 col-form-label">State*</Form.Label>
                                                <Col sm={8} lg={6}>
                                                    <Form.Control
                                                        ref={register}
                                                        required
                                                        as="select"
                                                        name="state"
                                                    >
                                                        <option value="AL">Alabama</option>
                                                        <option value="AK">Alaska</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="DC">District Of Columbia</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NY">New York</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>
                                                    </Form.Control>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <Col className="text-right">{(errors['state'] && errors['state'].type === "required") && <span className="alert">{errors['state'].message}</span>}</Col>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className="row">
                                                <Form.Label className="col-sm-4 col-lg-4 col-form-label">Zip Code*</Form.Label>
                                                <Col sm={8} lg={8}>
                                                    <Form.Control
                                                        required
                                                        name="zip"
                                                        type="text"
                                                        placeholder="Zip Code"
                                                        autoComplete="off"
                                                        ref={register}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <Col className="text-center">{(errors['zip'] && errors['zip'].type === "typeError") && <span className="alert">Enter valid zip code</span>}</Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Cell Phone #</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <FormControl
                                                type="text"
                                                name="cellPhone"
                                                ref={register}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-4 col-form-label mr-3">Preferred Method of Contact: </Form.Label>
                                        <Col sm={8} lg={6} className="mt-2 ml-sm-4">
                                            <Form.Check
                                                inline
                                                type="radio"
                                                label="Email"
                                                value='email'
                                                name="preferredContactMethod"
                                                className="mr-5"
                                                ref={register}
                                                control={control}
                                                setValue={setValue}
                                                getValues={getValues} />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                label="CellPhone"
                                                value='cellPhone'
                                                control={control}
                                                ref={register}
                                                
                                                setValue={setValue}
                                                getValues={getValues}
                                                name="preferredContactMethod" />
                                        </Col></Form.Group>
                                </Card.Body>
                            </Card>
                            <Card className="green-alt-bg mt-3 rounded-40">
                                <Card.Body>
                                    <h3 className="green p-2">Teacher Background</h3>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">DOB*</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <div className="date-picker">
                                                <Controller
                                                    as={
                                                        <DateTimePicker
                                                        format="MM/dd/yyyy"
                                                        dayPlaceholder="DD"
                                                        monthPlaceholder="MM"
                                                        yearPlaceholder="YYYY"
                                                        />}
                                                    name="dob"
                                                    control={control}
                                                />
                                            </div>
                                            {errors.dob && <span className="alert">{errors.dob.message}</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">YYT Credentials*</Form.Label>
                                        <Col sm={4} lg={3}>
                                            <Controller
                                                as={
                                                    <Form.Control
                                                        required
                                                        as="select"
                                                    >
                                                        <option>select</option>
                                                        <option>100</option>
                                                        <option>200</option>
                                                        <option>300</option>
                                                        <option>400</option>
                                                        <option>500</option>
                                                        <option>600</option>
                                                        <option>700</option>
                                                        <option>800</option>
                                                        <option>800</option>
                                                        <option>1000</option>
                                                        <option>1000+</option>
                                                    </Form.Control>
                                                }
                                                ref={register}
                                                control={control}
                                                name="YYTCredentials"
                                            />
                                        </Col>
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Years Training*</Form.Label>
                                        <Col sm={4} lg={3} className="mt-3 mt-sm-0">
                                            <FormControl
                                                type="text"
                                                name="yearsTraining"
                                                ref={register}

                                            />
                                        </Col>
                                        
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Col sm={12} lg={2} md={2}></Col>
                                        <Col sm={6} lg={5} md={5}>
                                        {errors.YYTCredentials && <span className="alert">{errors.YYTCredentials.message}</span>}
                                        </Col>
                                        <Col lg={5} md={5}>
                                        {errors.yearsTraining && <span className="alert">{errors.yearsTraining.message}</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Studio Affiliation
                                    </Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                name="studioAffiliation"
                                                type="text"
                                                ref={register}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Other Specialties
                                    </Form.Label>
                                        <Col sm={8} lg={9}>
                                            {Specialties.map((prop, i) =>
                                                <Form.Check
                                                    className="mr-2"
                                                    key={i}
                                                    inline
                                                    label={prop}
                                                    type='checkbox'
                                                    id={prop}
                                                    control={control}
                                                    setValue={setValue}
                                                    getValues={getValues}
                                                    name="specialities"
                                                    ref={register}
                                                    value={prop} />)}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">
                                            <i className="fas fa-question-circle mr-2" data-toggle="tooltip" data-placement="top"
                                                title="The CVV number is the 3 or 4 digit number printed on the signature strip"></i>
                                        Bio</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <FormControl
                                                ref={register}
                                                name="bio"
                                                className="text-area"
                                                type="textarea"
                                                as="textarea"
                                                rows="8"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">
                                            <i className="fas fa-question-circle mr-2" data-toggle="tooltip" data-placement="top"
                                                title="The CVV number is the 3 or 4 digit number printed on the signature strip"></i>
                                            Cover Image
                                        </Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <div className="grab-img-block d-block mb-2">
                                                <div className="d-flex align-items-center">
                                                    <Dropzone
                                                        onDrop={(e) => onDropCoverImage(e, 'coverImage')}
                                                    >
                                                        {({ getRootProps, getInputProps, isDragActive }) => (
                                                            <>
                                                                <div {...getRootProps()} className="drag-img mb-3">
                                                                    <input {...getInputProps()} />
                                                                    {
                                                                        isDragActive ?
                                                                            <p>Drop the files here ...</p> :
                                                                            (!TeacherAccountProfile.teacher.coverImage ? <i className="fas fa-image icon-img"></i>
                                                                                :
                                                                                <Image src={TeacherAccountProfile.teacher.coverImage} name="coverImage" />
                                                                            )
                                                                    }
                                                                </div>
                                                                <div className="text-center btn-col">
                                                                    <Button className="green-bg btn-secondary btn-upload" {...getRootProps()}>
                                                                        <i className="fas fa-upload mr-1"></i> Upload
                                                         </Button>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropzone>

                                                </div>
                                            </div>

                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">
                                            <i className="fas fa-question-circle mr-2" data-toggle="tooltip" data-placement="top"
                                                title="The CVV number is the 3 or 4 digit number printed on the signature strip"></i>
                                            Video URL
                                        </Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <FormControl
                                                name="videoUrl"
                                                type="text"
                                                ref={register}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">
                                            <i className="fas fa-question-circle mr-2" data-toggle="tooltip" data-placement="top"
                                                title="The CVV number is the 3 or 4 digit number printed on the signature strip"></i>
                                            Profile Pic*
                                        </Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <div className="grab-img-block d-block mb-2">
                                                <div className="d-flex align-items-center">
                                                    <Dropzone
                                                        onDrop={(e) => onDropProfileImage(e, 'profileImage')}
                                                    >
                                                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                                            <>
                                                                <div {...getRootProps()} className="drag-img-circle mb-3">
                                                                    <input {...getInputProps()} />
                                                                    {
                                                                        isDragActive ?
                                                                            <p>Drop the files here ...</p> :
                                                                            (!TeacherAccountProfile.teacher.profileImage ? <i className="fas fa-image icon-img"></i>
                                                                                :
                                                                                <Image src={TeacherAccountProfile.teacher.profileImage} name="profileImage" />
                                                                            )
                                                                    }
                                                                </div>
                                                                <div className="text-center btn-col">
                                                                    <Button className="green-bg btn-secondary btn-upload" {...getRootProps()}>
                                                                        <i className="fas fa-upload mr-1"></i> Upload
                                                    </Button>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropzone>

                                                </div>
                                            </div>
                                            {validateProfileImage && <span className="alert">{validateProfileImage}</span> }
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-4 col-form-label">Do you have instructor insurance?</Form.Label>
                                        <Col sm={8} lg={6} className="mt-2 ml-sm-3">
                                            <Form.Check inline
                                                type="radio"
                                                label="Yes"
                                                name="instructorInsurance"
                                                className="mr-5 ml-2"
                                                ref={register}
                                                control={control}
                                                setValue={setValue}
                                                getValues={getValues}
                                                value={'Yes'}

                                            />
                                            <Form.Check inline
                                                type="radio"
                                                label="No"
                                                control={control}
                                                ref={register}
                                                setValue={setValue}
                                                getValues={getValues}
                                                value={'No'}
                                                name="instructorInsurance" />
                                        </Col>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                            <Card className="green-bg mt-3">
                                <Card.Body>
                                    <h4 className="yellow">Website Info</h4>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">
                                            <i className="fas fa-question-circle mr-2" data-toggle="tooltip" data-placement="top"
                                                title="The CVV number is the 3 or 4 digit number printed on the signature strip"></i>
                                        Friendly URL
                                        </Form.Label>
                                        <Col sm={8} lg={9}>
                                            <FormControl
                                                type="text"
                                                name="friendlyUrl"
                                                ref={register}
                                            />
                                            {errors.friendlyUrl && <span className="green-alt">{errors.friendlyUrl.message}</span>}
                                        </Col>
                                    </Form.Group>
                                    <FormGroup className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Time Zone*</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <div className="time-picker">
                                                <Controller
                                                    as={
                                                        <TimezonePicker
                                                            absolute={false}
                                                            placeholder="Select timezone..."
                                                            name="timeZone"
                                                            // defaultValue="Europe/Moscow"
                                                            ref={register}
                                                        />}
                                                    name="timeZone"
                                                    // defaultValue="Europe/Moscow"
                                                    control={control}
                                                />
                                            </div>
                                            {errors.timeZone && <span className="green-alt">{errors.timeZone.message}</span>}
                                        </Col>
                                    </FormGroup>
                                </Card.Body>
                            </Card>

                            <Card className="salmon-bg mt-3">
                                <Card.Body>
                                    <h4 className="green">Payment Info</h4>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Credit Card #</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <MaskedInput

                                                name="ccNumber"
                                                className="form-control"
                                                mask={mask}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Exp. Date</Form.Label>
                                        <Col sm={4} lg={3}>
                                            <Form.Control
                                                required
                                                name="inputMonth"
                                                as="select"
                                            >
                                                <option>Month</option>
                                                <option>01</option>
                                                <option>02</option>
                                                <option>03</option>
                                                <option>04</option>
                                                <option>05</option>
                                                <option>06</option>
                                                <option>07</option>
                                                <option>08</option>
                                                <option>09</option>
                                                <option>10</option>
                                                <option>11</option>
                                                <option>12</option>
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4} lg={3} className="mt-3 mt-sm-0">
                                            <Form.Control
                                                required
                                                name="inputYear"
                                                as="select"
                                            >
                                                <option selected>Year</option>
                                                <option>2020</option>
                                                <option>2021</option>
                                                <option>2022</option>
                                                <option>2023</option>
                                                <option>2024</option>
                                                <option>2025</option>
                                                <option>2026</option>
                                                <option>2027</option>
                                                <option>2028</option>
                                                <option>2029</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">
                                            <i className="fas fa-question-circle mr-2" data-toggle="tooltip" data-placement="top"
                                                title="The CVV number is the 3 or 4 digit number printed on the signature strip"></i>
                                        CVV
                                    </Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                name="cvvNumber"
                                                type="text"
                                            />
                                        </Col>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-5 mb-5">
                        <Button type="submit" className="btn-secondary btn-float">
                            <Image src={FlexieIconReverse} className="btn-icon" />Submit
                    </Button>
                    </Row>
                </Form>
                <Modal show={toggle} onHide={() => handleToggle(!toggle)}>
                    <Modal.Body>{firebaseError ? firebaseError : message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleToggle(!toggle)}>
                            Cancel
                    </Button>
                    </Modal.Footer>
                </Modal>
                {loading && <Container className="mb-5 text-center spinner-block">
                    <Modal show={true} className="loading-modal text-center">
                        <Spinner animation="border" variant="primary" />
                    </Modal>
                </Container>}
            </Container>
    </div>
    );

}