import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { FirebaseContext } from "../firebase";


const TeacherRoute = props => {
    let  { claims } = useContext(FirebaseContext);
    claims = claims ? claims : JSON.parse(localStorage.getItem('claims'));
    return (claims ?((!claims.email_verified || !claims.profileComplete)? <Redirect to={`/teacher/profile`}/> :(claims.teacher 
        ?<Route {...props}/> 
        : <Redirect to='/login' />) )
        : <Redirect to="/" />)
}

export default TeacherRoute;