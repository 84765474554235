import React from "react";
import { Link } from 'react-router-dom';
import {Button, Container, Row, Col} from 'react-bootstrap';


function Register(props) {
  return (
    <div className="body-header-space">
    <Container>
      <Row className="mb-2">
        <Col className="text-center">
          <Button variant="success"><Link className="reverse" to="/student-registration">Register As A Student</Link></Button>
        </Col>
        <Col className="text-center">
          <Button variant="success"><Link className="reverse" to="/teacher-registration">Register As A Teacher</Link></Button>
        </Col>
      </Row>
    </Container>
    </div>
  );
}

export default Register;
