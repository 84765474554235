import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom'
import moment from 'moment';
import { Container, Row, Col, Nav, Card, Image, Tab, Button, ProgressBar, DropdownButton, Dropdown, Spinner } from 'react-bootstrap';

import ClassCard1 from '../../../../img/class-card_1.jpg';
import ButtonImage from '../../../../img/FlexieIcon-secondary.svg';
import { FirebaseContext } from "../../../../firebase";
import { calculatePagination } from '../../../../utils/helper';
import Class from '../../../../firebase/model/Classes';
import { StarRatingComponent } from '../../../../Components/Common/startRating';
import { formatDuration } from '../../../../utils/helper';
import ClassContext from '../Context';
import '../index.scss';
import Teacher from '../../../../firebase/model/teacher';
import BookModal from '../BookModal';



const TeacherProfile = (props) => {
    const { handleBookDetails, classRosters, handleBookClass } = useContext(ClassContext);
    const classIdLists = classRosters ? classRosters.map(cr => cr.classId) : [];
    const { claims, currentUserRole } = useContext(FirebaseContext);
    const [ isFriendlyUrl, setFriendlyUrl] = useState(null);
    const [currentTab, setCurrentTab] = useState('upcoming classes');
    const [teacherDetails, setTeacherDetails] = useState({});
    const [classes, setClasses] = useState(null);
    const [classPages, setClassPagination] = useState(null);
    const [toggle, handleToggle] = useState(false);
    let { pathname } = useLocation();

    const handleTabChange = (e) => {
        const tab = e.target.innerText
        setCurrentTab(tab)
    }

    const fetchClasses = async (id) => {
        const teacherDetails = await Teacher.getProfileById(id);
        if(teacherDetails.friendlyUrl) {
            setFriendlyUrl(teacherDetails.friendlyUrl)
        }
        const classes = await Class.getAllUpcomingClassesByTeacher(teacherDetails.userId);
        setClassPagination(calculatePagination(classes, 3))
        setClasses(classes);
        setTeacherDetails(teacherDetails)
    }

    useEffect(() => {
        let id = pathname.split('/')[3]
        id && fetchClasses(id);
    }, [])

    const handlePageChange = (index) => {
        let first = index * 3
        let last = first + 3
        setClassPagination({ ...classPages, first, last, currrentPage: index })
    }

    const handleBooking = (classId, amount) => {
        handleBookDetails({ classId, amount: amount || 0 });
        if (claims && currentUserRole === 'Student') {
            if (amount && amount > 0)
                props.history.push('/student/payment');
            else handleBookClass({ classId, amount });
        }
        else handleToggle(!toggle)

    }

    const checkBookingDisabled = (c) => {
        return { bookText: c.canceled ? 'Cancelled': (classIdLists.includes(c.id)? 'Booked' : 'Book'),
                 disable:  Boolean(c.date < new Date() || classIdLists.includes(c.id) || c.canceled) }
    }

    return <div className="teacher-profile-page">
        <div className="header-salmon-bg" />
        {isFriendlyUrl ?
        <iframe src={isFriendlyUrl} width={window.innerWidth} height={window.innerHeight}></iframe>
         :Object.keys(teacherDetails).length ? <Container>
            <Row>
                <Col xs={12} lg={12} xl={12} className="mt-5 info-card">
                    <Card>
                        <Card.Body>
                            {!teacherDetails.coverImage ? <div className="cover"><i class="far fa-image reverse" /></div>
                                : <Image src={teacherDetails.coverImage}></Image>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="image-wrap" md={'auto'}>
                    <div className="img-circle">
                        {!teacherDetails.profileImage ? <i class="fas fa-user icon-img user-icon"></i>
                            : <Image className="user-icon" src={teacherDetails.profileImage} roundedCircle />}
                    </div>
                </Col>
                <Col xs={12} lg={'auto'} className="d-flex align-items-center mt-5 mt-lg-0 mt-lg-0 flex-lg-fill">
                    <h1 className="green-alt text-size">{teacherDetails.firstName && teacherDetails.fullName}</h1>
                </Col>
                <Col xs={'auto'} className="d-flex justify-content-start justify-content-md-end align-items-center pr-md-5">
                    <div className="rating-fill-start ">
                        <StarRatingComponent size={'1x'}
                            value={4} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={12} xl={12} className="custom-tab-wrapper mb-4">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="upcoming classes">
                        <Row className="tab-row"><Nav variant="tabs" className="flex-row">
                            <Nav.Item className="d-none d-md-block">
                                <Nav.Link eventKey="upcoming classes">upcoming classes</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="d-none d-md-block">
                                <Nav.Link eventKey="biography">biography</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="d-none d-md-block">
                                <Nav.Link eventKey="reviews">reviews</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="d-md-none drop-button">
                                <DropdownButton id="dropdown-basic-button" title={currentTab} onClick={handleTabChange} size="lg">
                                    <Dropdown.Item data-toggle="tab" eventKey="upcoming classes">upcoming classes</Dropdown.Item>
                                    <Dropdown.Item data-toggle="tab" eventKey="biography">biography</Dropdown.Item>
                                    <Dropdown.Item eventKey="reviews">reviews</Dropdown.Item>
                                </DropdownButton>
                            </Nav.Item>
                        </Nav>
                        </Row>
                        <Row>
                            <Tab.Content className="w-100">
                                <Tab.Pane eventKey="upcoming classes" id="1">
                                    <Container>
                                        <Row>
                                            {(classes ? classes.length ?
                                                classes.slice(classPages.first, classPages.last).map((c, i) =>
                                                    <Col className="space-6" xs={12} lg={6} xl={4} key={i}>
                                                        <Card className="custom-card card-shadow mb-5">
                                                            <Card.Header>
                                                                <Card.Img variant="top" src={c.classImage ? c.classImage : ClassCard1} />
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <Card.Title className="center green">{c.className}</Card.Title>
                                                                <ul className="fa-ul">
                                                                    <li className="green"><span className="fa-li"><i className="fas fa-calendar-alt green-alt"></i></span> <strong>Time:</strong>{moment(c.date).format('MMMM Do, h:mm a')}</li>
                                                                    <li className="green"><span className="fa-li"><i className="fas fa-signal green-alt"></i></span> <strong>Difficulty:</strong>{c.difficultyLevel}</li>
                                                                    <li className="green"><span className="fa-li"><i className="fas fa-clock green-alt"></i></span> <strong>Duration:</strong>{formatDuration(c.duration)}</li>
                                                                    <li className="green"><span className="fa-li"> <i className="fas fa-comment green-alt" /></span> <strong>Language:</strong>English</li>
                                                                    <li className="green"><span className="fa-li"><i className="fas fa-dollar-sign green-alt"></i></span> <strong>Price: $</strong>{c.price ? c.price : '--'} </li>
                                                                </ul>
                                                                <div className="btn-float d-flex align-items-center justify-content-center w-100">
                                                                    <Button variant="primary" className="btn-secondary mr-2 d-flex justify-content-center align-items-center"> <i className="fas fa-list mr-2" /><Link to={`/class/details/${c.id}`}>details</Link></Button>
                                                                    <Button variant="primary" className="btn-salmon btn-info btn-primary d-flex justify-content-center align-items-center"
                                                                        onClick={() => handleBooking(c.id, c.price)}
                                                                        disabled={checkBookingDisabled(c).disable}>
                                                                        <i className="fas fa-calendar-plus mr-2 reverse" />
                                                                        {checkBookingDisabled(c).bookText }
                                                                    </Button>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                )
                                                : <Col xs={12} className="text-center green"> No Upcoming Classes </Col>
                                                : <Col xs={12} className="text-center">
                                                    <Spinner animation="border" variant="primary" />
                                                </Col>
                                            )}
                                        </Row>
                                        {classPages && classPages.totalPages > 1 ? <div className="custom-pagination d-flex justify-content-right green">
                                            <span className={classPages.currrentPage <= 0 ? "ml-2 disabled" : "ml-2"} onClick={() => handlePageChange(classPages.currrentPage - 1, 1)} >{'<'} </span>
                                            {classPages.pages.map((i) => <span key={i} className={classPages.currrentPage === i ? "ml-2 green-alt" : "ml-2"} onClick={() => handlePageChange(i, 1)} >{i + 1}</span>)}
                                            <span className={classPages.currrentPage === classPages.totalPages - 1 ? "ml-2 disabled" : "ml-2"} onClick={() => handlePageChange(classPages.currrentPage + 1, 1)} >{'>'}</span>
                                        </div>
                                            : ""}
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="biography" id="2">
                                    <Container>
                                        <Row>
                                            <Col xs={12} lg={6} xl={4}>
                                                <Card className="custom-card card-shadow mb-5">
                                                    <Card.Body>
                                                        <Card.Title className="center green">Quick facts</Card.Title>
                                                        <ul className="fa-ul">
                                                            <li className="green"><span className="fa-li"><i className="fas fa-map-marker-alt green-alt"></i></span> <strong>Location:</strong> {teacherDetails.state ? teacherDetails.state : 'USA'}</li>
                                                            <li className="green"><span className="fa-li"><i className="fas fa-comment-alt green-alt"></i></span> <strong>Language(s):</strong> English</li>
                                                            <li className="green"><span className="fa-li"><i className="fas fa-certificate green-alt"></i></span> <strong>Certification:</strong> {teacherDetails.YYTCredentials ? teacherDetails.YYTCredentials : '200'}hr</li>
                                                            <li className="green"><span className="fa-li"><Image src={ButtonImage} /></span> <strong>Member Since: </strong>sept 2020</li>
                                                        </ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Container>
                                                    <Row>
                                                        <Col>
                                                            <h1 className="green">About</h1>
                                                            <p className="green">
                                                                {teacherDetails.bio && teacherDetails.bio}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="video-wrap">
                                                            <video width="100%" controls>
                                                                <source src="mov_bbb.mp4" type="video/mp4" />
                                                                <source src="mov_bbb.ogg" type="video/ogg" />
                                                                Your browser does not support HTML video.
                                                    </video>
                                                        </Col>
                                                    </Row>
                                                </Container>

                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="reviews" id="2">
                                    <Container>
                                        <Row className="mb-5 justify-content-center align-items-center">
                                            <Col xs={'auto'} className="mb-4">
                                                <Card className="yellow-bg rounded-40 p-3 p-md-3">
                                                    <Card.Body className="py-0 pr-0 pl-2">
                                                        <Row className="align-items-center">
                                                            <Col xs="auto">
                                                                <h1 className="reverse green-alt m-0 large">4.6</h1>
                                                            </Col>
                                                            <Col>
                                                                <div className="rating-fill-start">
                                                                    <StarRatingComponent size={'1x'}
                                                                        value={4} />
                                                                </div>
                                                                <h4 className="green">30 rating</h4>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col xs={12} md={3} className="d-flex justify-content-start">
                                                <div className="ratings-wrap">
                                                    <span> 5 <i className="fas fa-star fill-start"></i><ProgressBar now={80} /></span>
                                                    <span> 4 <i className="fas fa-star fill-start"></i><ProgressBar now={60} /></span>
                                                    <span> 3 <i className="fas fa-star fill-start"></i><ProgressBar now={40} /></span>
                                                    <span> 2 <i className="fas fa-star fill-start"></i><ProgressBar now={20} /></span>
                                                    <span> 1 <i className="fas fa-star fill-start"></i><ProgressBar now={10} /></span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Container>
                                                <Row>
                                                    <Col xs={12} lg={6} xl={4}>
                                                        <Card className="custom-card card-shadow mb-5">
                                                            <Card.Body>
                                                                <ul className="fa-ul ml-0 mb-0 ">
                                                                    <li>
                                                                        <span>
                                                                            <div className="rating-fill-start">
                                                                                <StarRatingComponent size={'1x'}
                                                                                    value={4} />
                                                                            </div>
                                                                        </span>
                                                                    </li>
                                                                    <li className="green"><h4>Lorem Ipsum</h4></li>
                                                                    <li className="green"><h6>September 2020</h6></li>
                                                                    <li className="green">
                                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. </li>
                                                                </ul>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={12} lg={6} xl={4}>
                                                        <Card className="custom-card card-shadow mb-5">
                                                            <Card.Body>
                                                                <ul className="fa-ul ml-0 mb-0">
                                                                    <li>
                                                                        <span>
                                                                            <div className="rating-fill-start">
                                                                                <StarRatingComponent size={'1x'}
                                                                                    value={4} />
                                                                            </div>
                                                                        </span>
                                                                    </li>
                                                                    <li className="green"><h4>Lorem Ipsum</h4></li>
                                                                    <li className="green"><h6>September 2020</h6></li>
                                                                    <li className="green">
                                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. </li>
                                                                </ul>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={12} lg={6} xl={4}>
                                                        <Card className="custom-card card-shadow mb-5">
                                                            <Card.Body>
                                                                <ul className="fa-ul ml-0 mb-0">
                                                                    <li>
                                                                        <span>
                                                                            <div className="rating-fill-start">
                                                                                <StarRatingComponent size={'1x'}
                                                                                    value={4} />
                                                                            </div>
                                                                        </span>
                                                                    </li>
                                                                    <li className="green"><h4>Lorem Ipsum</h4></li>
                                                                    <li className="green"><h6>September 2020</h6></li>
                                                                    <li className="green">
                                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. </li>
                                                                </ul>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} lg={6} xl={4}>
                                                        <Card className="custom-card card-shadow mb-5">
                                                            <Card.Body>
                                                                <ul className="fa-ul ml-0 mb-0">
                                                                    <li>
                                                                        <span>
                                                                            <div className="rating-fill-start">
                                                                                <StarRatingComponent size={'1x'}
                                                                                    value={4} />
                                                                            </div>
                                                                        </span>
                                                                    </li>
                                                                    <li className="green"><h4>Lorem Ipsum</h4></li>
                                                                    <li className="green"><h6>September 2020</h6></li>
                                                                    <li className="green">
                                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. </li>
                                                                </ul>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={12} lg={6} xl={4}>
                                                        <Card className="custom-card card-shadow mb-5">
                                                            <Card.Body>
                                                                <ul className="fa-ul ml-0 mb-0">
                                                                    <li>
                                                                        <span>
                                                                            <div className="rating-fill-start">
                                                                                <StarRatingComponent size={'1x'}
                                                                                    value={4} />
                                                                            </div>
                                                                        </span>
                                                                    </li>
                                                                    <li className="green"><h4>Lorem Ipsum</h4></li>
                                                                    <li className="green"><h6>September 2020</h6></li>
                                                                    <li className="green">
                                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. </li>
                                                                </ul>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={12} lg={6} xl={4}>
                                                        <Card className="custom-card card-shadow mb-5">
                                                            <Card.Body>
                                                                <ul className="fa-ul ml-0 mb-0">
                                                                    <li>
                                                                        <span>
                                                                            <div className="rating-fill-start">
                                                                                <StarRatingComponent size={'1x'}
                                                                                    value={4} />
                                                                            </div>
                                                                        </span>
                                                                    </li>
                                                                    <li className="green"><h4>Lorem Ipsum</h4></li>
                                                                    <li className="green"><h6>September 2020</h6></li>
                                                                    <li className="green">
                                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. </li>
                                                                </ul>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <div className="float-right green">
                                            Load more
                                    </div>
                                    </Container>
                                </Tab.Pane>

                            </Tab.Content>
                        </Row>
                    </Tab.Container>
                </Col>
            </Row>
        </Container>
            :
            <div className="text-center spinner-block"><Spinner animation="border" variant="primary" /></div>
        }
        {(toggle && !claims) && <BookModal
            {...props}
            toggle={toggle}
            handleToggle={handleToggle} />}
    </div>
}

export default TeacherProfile;