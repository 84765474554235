import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Container, Row, Col, Card, Image, Button, Table, Dropdown, Accordion, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import GraphImage from '../../../../img/graph.jpg';
import FlexieIcon from '../../../../img/FlexieIcon-reverse.svg';
import FlexieIconSecondary from '../../../../img/FlexieIcon-secondary.svg'
import { StarRatingComponent } from '../../../../Components/Common/startRating';
import { formatDuration } from '../../../../utils/helper';
import TeacherClasscontext from '../Context';
import { DisplayModal } from '../../../../Components/Common/Modal'


const DashboardTeacher = () => {

    const { teacherProfileDetails, teacherUpcomingClasses, ShowModal, deleteClass,
         nextClassHappening, secondsLeft, nextClass } = useContext(TeacherClasscontext);

    const upcomingClasses = teacherUpcomingClasses ? (teacherUpcomingClasses.length > 5
        ? teacherUpcomingClasses.slice(0, 5) : teacherUpcomingClasses) : "";
    const [loading, setLoading] = useState(true);
    const [classId, setClassId] = useState(null);
    const [toggle, handleToggle] = useState(false);

    const handleDeleteClassDetail = (classId) => {
        setClassId(classId);
        handleToggle(!toggle);
    }

    const handleDeleteClass = async () => {
        setLoading(true);
        handleToggle(!toggle)
        await deleteClass(classId);
        setLoading(false)
        ShowModal('Class cancelled Successfully.')
    }

    useEffect(() => {
        Object.keys(teacherProfileDetails) && setLoading(false);
    }, [teacherProfileDetails])

    const NextClassBlock = useMemo(() => <Row className="mb-4 mt-3">
    <Col xs={12}>
        <Card className="green-bg border-0 white-bg p-3 rounded-40">
            <Row>
                <Col xs={12} lg={10}>
                    <div className="d-flex mt-2">
                        <i className="fas fa-calendar-alt green-alt fa-3x ml-3 mr-3" />
                        <h3 className="reverse mr-2 mt-2">Next Class: </h3>
                        <h3 className="reverse mr-1 mt-2">{nextClass.className}</h3>
                        <p className="reverse mt-2">{`begins in ${Math.floor(secondsLeft/60)} min ${Math.floor(secondsLeft % 60)} sec`}</p>
                    </div>
                </Col>
                <Col xs={12} lg={2}>
                    <a target="_blank" href={nextClass.streamingUrl} ><Button variant="secondary" className="btn btn-float mt-2" >
                        <i className="fas fa-calendar-alt icon-btn" /> Begin Class Now
            </Button></a>
                </Col>
            </Row>  
        </Card>
    </Col>

</Row> , [secondsLeft])

    return (
        <div className="body-header-space teacher-dashboard">
            <section className="content white-bg">
                {!loading ? <Container className="mb-5">
                    <Row className="mt-4 mb-4">
                        <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                            <h1 className="salmon"><i className="fas fa-th"></i> dashboard</h1>
                        </Col>
                    </Row>
                    {secondsLeft > 0 && nextClassHappening ? NextClassBlock: ""}
                    <Row>
                        <Col xs={12} lg={8} className="order-2 order-lg-1">
                            <Card className="card-shadow"> 
                                <Card.Body>
                                    <Row>
                                        <Col xs={2}>
                                            <i className="fas fa-piggy-bank green-alt fa-3x fa-lg-4x"></i>
                                        </Col>
                                        <Col xs={10} md={5}>
                                            <div className="rounded-25 green-bg p-3 p-md-3">
                                                <h2 className="reverse m-0 big">$186.99</h2>
                                                <h5 className="green-alt m-0">current earnings</h5>
                                            </div>
                                        </Col>
                                        <Col xs={10} md={5} className="ml-auto ml-md-0 mt-2 mt-md-0">
                                            <div className="rounded-25 salmon-bg p-3 p-md-3">
                                                <h2 className="reverse m-0 big">$264.36</h2>
                                                <h5 className="green m-0">last payday <span className="thin text-uppercase small">(Jun 15)</span></h5>
                                            </div>
                                        </Col>
                                        <Col xs={12} className="mt-3 mb-3">
                                            <Image src={GraphImage} className="w-100" />
                                        </Col>
                                        <Link to='/teacher/dashboard' className="btn btn-secondary btn-float">
                                            <Image className="btn-icon" src={FlexieIcon} />
                                                 Check Earnings
                                            </Link>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="d-none d-lg-block order-lg-2">
                            <Row>
                                <Col xs={12} md={6} lg={12}>
                                    <Card className="card-shadow mb-5">
                                        <Card.Body>
                                            <Row>
                                                <Col xs={4}>
                                                    <div className="default-image-profile">
                                                        {teacherProfileDetails.profileImage ? <Image src={teacherProfileDetails.profileImage} className="img-fluid rounded-circle salmon-border small-border h-100" />
                                                            : <i class="fas fa-user fa-3x reverse mt-4"></i>}
                                                    </div>
                                                </Col>
                                                <Col xs={8} className="mt-3">
                                                    <h4 className="green">{teacherProfileDetails.firstName && teacherProfileDetails.fullName}</h4>
                                                    <div className="rating">
                                                        <StarRatingComponent size={'1x'}
                                                            value={4} />
                                                        <span className="pl-1">(<a className="thin green-alt" href="#">190 Ratings</a>)</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Link to={`/profile/teacher/${teacherProfileDetails && teacherProfileDetails.id}`} className="btn btn-secondary btn-float"><i className="fas fa-user-circle"></i> Visit Profile</Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={12}>
                                    <h2 className="green"><Image src={FlexieIconSecondary} /> reflexions</h2>
                                    <Card className="yellow-bg">
                                        <Card.Body>
                                            <h4 className="green">Lorem ipsum dolor sit amet</h4>
                                            <p><strong>Lorem ipsum</strong>. Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                            <Link to="/teacher/dashboard" className="btn btn-secondary btn-float">
                                                <Image className="btn-icon" src={FlexieIcon} />
                                                    Learn More
                                                </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} className="order-1 order-lg-3 mb-3 mb-lg-0 mt-md-5">
                            <Card className="salmon-bg border-0 white-bg">
                                <Accordion>
                                    <Accordion.Toggle as={Card.Header} eventKey="3">
                                        <Button className="btn btn-link btn-block text-left green" type="button">
                                            <i className="fas fa-exclamation-triangle reverse"></i> Alerts
                                            <span className="float-right reverse d-inline-block">2 New Alerts</span>
                                        </Button>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse id="alertPane" eventKey="3">
                                        <Card.Body className="mb-0 pb-0">
                                            <Row>
                                                <Col xs={12}>
                                                    <div className="card red-bg mb-3 border-0">
                                                        <div className="card-body p-3">
                                                            <p className="reverse m-0"><strong>6/20/2020</strong> Your credit card has expired. <a href="#" className="reverse">Please click here to update info</a>.</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <Card className="red-bg mb-3 border-0">
                                                        <Card.Body className="card-body p-3">
                                                            <p className="reverse m-0"><strong>6/20/2020</strong> Your credit card has expired. <a href="#" className="reverse">Please click here to update info</a>.</p>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Accordion>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-5 mt-lg-4">
                        <Col xs={12} lg={8} lg={12} className="mb-3 mb-lg-0">
                            <h2 className="green"><i className="fas fa-calendar-alt green-alt"></i> upcoming classes</h2>
                            <Card className="card-shadow">
                                <Card.Body>
                                    <div className="table-responsive">
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th>date</th>
                                                    <th>name</th>
                                                    <th className="d-none d-md-table-cell">duration</th>
                                                    <th>registered</th>
                                                    <th>actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(upcomingClasses ? (upcomingClasses.length ? upcomingClasses.map((c, i) =>
                                                    <tr key={i}>
                                                        <td>{c.date && moment(c.date).format('MM/DD/YY hh:mm')}</td>
                                                        <td>{c.className}</td>
                                                        <td className="d-none d-md-table-cell">{formatDuration(c.duration)}</td>
                                                        <td>{c.studentsEnrolled, c.studentsEnrolled}</td>
                                                        <td>
                                                            <div className="actions d-none d-md-block">
                                                                <Link to={`/teacher/classes/${c.id}`}><i className="fas fa-eye mr-2"></i></Link>
                                                               {secondsLeft > 0 && nextClassHappening ? <a target="_blank" href={c.streamingUrl} ><i className="fas fa-user-check mr-2"/></a>: ""}
                                                                <Link to={`/teacher/class-builder/${c.id}`}><i className="fas fa-edit mr-2"></i></Link>
                                                                <Link onClick={() => handleDeleteClassDetail(c.id)}><i className="fas fa-trash-alt mr-2" /></Link>     
                                                            </div>
                                                            <div className="actions-mobile d-md-none">
                                                                <div className="btn-group">
                                                                    <Dropdown className="nav-item">
                                                                        <Dropdown.Toggle id="dropdown-custom-1" className="btn btn-salmon btn-sm dropdown-toggle">
                                                                            Actions
                                                                    </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item><Link to={`/teacher/classes/${c.id}`}><i className="fas fa-eye"></i>View</Link></Dropdown.Item>
                                                                            <Dropdown.Item><a href="#"><i className="fas fa-user-check"></i> Show Registered</a></Dropdown.Item>
                                                                            <Dropdown.Item><Link to={`/teacher/class-builder/${c.id}`}>Edit</Link></Dropdown.Item>
                                                                            <Dropdown.Divider></Dropdown.Divider>
                                                                            <Dropdown.Item> <Link onClick={() => handleDeleteClassDetail(c.id)}>delete</Link></Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                    : <tr><td colSpan={5}>No Upcoming classes</td></tr>)
                                                    : <tr><td colSpan={5} className="text-center">
                                                        <Spinner animation="border" variant="primary" /></td></tr>) }
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className="text-right">
                                        <Link className="btn btn-secondary mr-3" to="/teacher/class-builder">
                                            <i className="fas fa-calendar-plus"></i> Add a Class</Link>
                                        <Link to="/teacher/classes" className="green-alt">See Full List</Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="d-lg-none mt-4">
                            <Row>
                                <Col xs={12} md={6} lg={12}>
                                    <Card className="card-shadow mb-5">
                                        <Card.Body>
                                            <Row>
                                                <Col xs={4}>
                                                    <div className="default-image-profile">
                                                        {teacherProfileDetails.profileImage ? <Image src={teacherProfileDetails.profileImage} className="img-fluid rounded-circle salmon-border small-border" />
                                                            : <i class="fas fa-user fa-3x reverse mt-4"></i>}
                                                    </div>
                                                </Col>
                                                <Col xs={8} className="mt-3">
                                                    <h4 className="green">{teacherProfileDetails.firstName && teacherProfileDetails.fullName}</h4>
                                                    <div className="rating">
                                                        <StarRatingComponent size={'1x'}
                                                            value={4} />
                                                        <span className="pl-1">(<a className="thin green-alt" href="#">190 Ratings</a>)</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Link to={`/profile/teacher/${teacherProfileDetails && teacherProfileDetails.id}`} className="btn btn-secondary btn-float"><i className="fas fa-user-circle"></i> Visit Profile</Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={12}>
                                    <h2 className="green"><Image src={FlexieIconSecondary} /> reflexions</h2>
                                    <Card className="yellow-bg">
                                        <Card.Body>
                                            <h4 className="green">Lorem ipsum dolor sit amet</h4>
                                            <p><strong>Lorem ipsum</strong>. Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                            <Link to="/teacher/dashboard" className="btn btn-secondary btn-float">
                                                <Image className="btn-icon" src={FlexieIcon} />
                                                    Learn More
                                                </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                    : <Container className="text-center spinner-block"><Spinner animation="border" variant="primary" /></Container>}
            </section>
            {toggle && <DisplayModal {...{
                toggle,
                handleToggle,
                message: "Are you sure you can to delete this class?",
                firstText: "Confirm",
                secondText: "Cancel",
                headerText: "Delete Class",
                firstMethod: handleDeleteClass
            }} />}
        </div>
    );
}

export default DashboardTeacher;