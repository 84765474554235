import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Card, Image, Button, Table, Dropdown, Accordion, Spinner, Modal } from 'react-bootstrap';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import firebase, { FirebaseContext } from '../../../../firebase'
import FlexieIcon from '../../../../img/FlexieIcon-reverse.svg'
import RefundIcon from '../../../../img/restore.svg';
import Class from '../../../../firebase/model/Classes';
import ClassRoster from '../../../../firebase/model/classRoster';
import { calculatePagination } from '../../../../utils/helper'
import '../index.scss';
TeacherClassDetails.classDetail = {}


export default function TeacherClassDetails(props) {

    const [ClassDetails, setClassDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [classRosters, setClassRosters] = useState(null);
    const [firebaseError, setFirebaseError] = useState(null);
    const [classRosterPages, setClassRosterPagination] = useState(null)
    const { claims } = useContext(FirebaseContext)
    let { pathname } = useLocation();

    const fetchClassDetails = async () => {
        const id = pathname.split('/')[3]

        await Class.getById(id).then(async (snapshot) => {
            const classRosters = await ClassRoster.getAllCallRosterByClassId(id);
            TeacherClassDetails.classDetail = snapshot;
            setClassDetails(snapshot);
            setClassRosterPagination(calculatePagination(classRosters, 5))
            setClassRosters(classRosters);

        }).catch((err) => {
            setFirebaseError(err);
            setClassDetails('');
        });
    }

    useEffect(() => {
        claims && fetchClassDetails();
    }, [claims, pathname])

    const handlePageChange = (index) => {
        let first = index * 5
        let last = first + 5
        setClassRosterPagination({ ...classRosterPages, first, last, currrentPage: index })

    }

    const hanldeCancelClass = async (id) => {
        setLoading(true)
        try {
            if (classRosters.length > 0) {
                const classRosterIds = classRosters.map(cr => cr.userId);
                const result = await firebase.cancelClass({
                    userId: claims.user_id,
                    className: ClassDetails.className,
                    date: moment(ClassDetails.date).format('MMMM Do, h:mm a'),
                    amount: ClassDetails.price,
                    classRosters: classRosterIds
                });
                if (result.data == true) {
                    TeacherClassDetails.classDetail.canceled = true;
                    await TeacherClassDetails.classDetail.save();
                }
                else setFirebaseError(result.data.message);
            }
            else {
                TeacherClassDetails.classDetail.canceled = true;
                await TeacherClassDetails.classDetail.save();
            }
        } catch (err) {
            let message = !err.response ? err.message :
                ((err.response.data && err.response.data.error)
                    ? err.response.data.error
                    : "Error while cancelling class, try again")
            setFirebaseError(message);
        }
        setLoading(false)
        setToggle(!toggle);

    }

    return (
        <div className="body-inner-space teacher-dashboard">
            <section className="content white-bg">
                {ClassDetails && Object.keys(ClassDetails).length ? <Container className="mb-5">
                    <Row className="mb-5">
                        <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                            <h1 className="salmon large"><i className="fas fa-calendar-check"></i> Class detail</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={8} className="order-3 order-md-1 mb-4 mb-md-0">
                            <Card className="card-shadow class-detail-card">
                                <Card.Body>
                                    <Row>
                                        <Col xs={8} md="auto">
                                            <div className="creative-flow-content">
                                                <h4 className="green">{ClassDetails.className}</h4>
                                                <div className="flow-content-list">
                                                    <i className="fas fa-calendar-alt green-alt" />
                                                    <strong className="green">Time: </strong>
                                                    <span className="green">{ClassDetails.date && moment(ClassDetails.date).format('MMMM Do, h:mm a')}</span>
                                                </div>
                                                <div className="flow-content-list">
                                                    <i className="fas fa fa-signal green-alt" />
                                                    <strong className="green">Difficulty: </strong>
                                                    <span className="green">{ClassDetails.difficultyLevel}</span>
                                                </div>
                                                <div className="flow-content-list">
                                                    <i className="fas fa-clock green-alt" />
                                                    <strong className="green">Duration: </strong>
                                                    <span className="green">{ClassDetails.duration}</span>
                                                </div>
                                                <div className="flow-content-list">
                                                    <i className="fas fa-comment green-alt" />
                                                    <strong className="green">Language: </strong>
                                                    <span className="green">English</span>
                                                </div>
                                                <div className="flow-content-list">
                                                    <i className="fas fa-dollar-sign green-alt" />
                                                    <strong className="green">Price: </strong>
                                                    <span className="green">{ClassDetails.price}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="text-right flow-img grab-img-block">
                                            {ClassDetails.classImage ? <Image src={ClassDetails.classImage} className="img-fluid w-lg-50" />
                                                : <div className="drag-img mb-3 float-right">
                                                    <i className="fas fa-image icon-img"></i>
                                                </div>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="creative-flow-description mt-5">
                                            <p className="green">
                                                <strong>Class Description:</strong>
                                                {ClassDetails.classDescription}
                                            </p>
                                        </Col>
                                      { ClassDetails.date && (new Date(ClassDetails.date) > new Date()) && 
                                      <Col className="d-flex flex-md-column float-right justify-content-center justify-content-md-end align-items-end creative-flow-btn mt-5 mr-lg-5">
                                            {!ClassDetails.canceled && <Link to={`/teacher/class-builder/${ClassDetails.id}`} className="btn btn-secondary mb-md-2 mr-2 mr-md-0">
                                                <i className="fas fa-edit" />
                                                Edit Class
                                            </Link>}
                                            {!ClassDetails.canceled && <Button to="/teacher/dashboard" className="btn btn-secondary" onClick={() => hanldeCancelClass(ClassDetails.id)}>
                                                <i className="fas fa-calendar-times " />
                                                Cancel Class
                                            </Button>}
                                        </Col>}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="order-2 mt-4 mb-5 mt-md-0 mb-md-0">
                            <Row>
                                <Col xs={12} md={6} lg={12}>
                                    <Card className="mb-3 rounded-40 green-bg p-3 p-md-3">
                                        <Card.Body className="py-0 pr-0 pl-2">
                                            <Row className="align-items-center">
                                                <Col xs="auto">
                                                    <i className="fas fa-users green-alt fa-3x fa-lg-4x" />
                                                </Col>
                                                <Col>
                                                    <h1 className="reverse m-0 large">{(classRosters ? classRosters.length : 0)}</h1>
                                                    <h4 className="green-alt">Students Enrolled</h4>

                                                </Col>
                                            </Row>

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={6} lg={12}>
                                    <Card className="yellow-bg rounded-40 p-3 p-md-3">
                                        <Card.Body className="py-0 pr-0 pl-2">
                                            <Row className="align-items-center">
                                                <Col xs="auto"><i className="fas fa-piggy-bank salmon fa-3x fa-lg-4x"></i></Col>
                                                <Col>
                                                    <h1 className="reverse salmon m-0 large">{`$${ClassDetails.price * (classRosters ? classRosters.length : 0)}`}</h1>
                                                    <h4 className="green">Expected Earnings</h4>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="text-center">
                                <a target="_blank" href={ClassDetails.streamingUrl}  className="btn btn-secondary btn-float">
                                        <Image className="btn-icon" src={FlexieIcon} />
                                        Start Class
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} className="order-1 order-lg-3 mb-3 mb-lg-0 mt-md-5">
                            <Card className="salmon-bg border-0 white-bg alerts-accordion-block rounded-40">
                                <Accordion>
                                    <Accordion.Toggle as={Card.Header} eventKey="3">
                                        <Button className="btn btn-link btn-block text-left green" type="button">
                                            <i className="fas fa-exclamation-triangle reverse mr-2"></i> Alerts
                                            <span className="float-right reverse d-inline-block">2 New Alerts</span>
                                        </Button>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse id="alertPane" eventKey="3">
                                        <Card.Body className="mb-0 pb-3">
                                            <Row>
                                                <Col xs={12}>
                                                    <Card className="red-bg mb-2 border-0 rounded-40">
                                                        <Card.Body className="card-body py-3 px-4">
                                                            <p className="reverse m-0 alert-list-content"><strong>6/20/2020</strong> Your credit card has expired. <a href="#" className="reverse">Please click here to update info</a>.</p>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <Card className="red-bg mb-2 border-0 rounded-40">
                                                        <Card.Body className="card-body py-3 px-4">
                                                            <p className="reverse m-0 alert-list-content"><strong>6/20/2020</strong> Your credit card has expired. <a href="#" className="reverse">Please click here to update info</a>.</p>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Accordion>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={12} lg={8} lg={12} className="mb-3 mb-lg-0 class-roster-block">
                            <h2 className="green big"><i className="fas fa-users green-alt" /> class roster</h2>
                            <Card className="card-shadow rounded-40">
                                <Card.Body>
                                    <div className="table-responsive">
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th width="32%">name</th>
                                                    <th width="32%">amount paid(net)</th>
                                                    <th width="26%" className="d-md-table-cell">coupon</th>
                                                    <th width="10%">actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(classRosters ? classRosters.length ?
                                                    classRosters.slice(classRosterPages.first, classRosterPages.last).map((cr, i) =>
                                                        <tr key={i}>
                                                            <td>{cr.studentName && cr.studentName}</td>
                                                            <td>{`$ ${cr.amountPaid ? cr.amountPaid: 'FREE'}`}</td>
                                                            <td>{cr.coupon && cr.coupon}</td>
                                                            <td>
                                                                <div className="actions d-none d-md-block">
                                                                    <Link to={'/'}><i className="fas fa-user-check" /></Link>
                                                                    <Link to={'/'}><Image src={RefundIcon} className="green" /></Link>
                                                                </div>
                                                                <div className="actions-mobile d-md-none">
                                                                    <div className="btn-group">
                                                                        <Dropdown className="nav-item">
                                                                            <Dropdown.Toggle id="dropdown-custom-1" className="btn btn-salmon btn-sm dropdown-toggle">
                                                                                Actions
                                                                 </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Link to={'/'} className="dropdown-item"><i className="fas fa-user-check" />check in Student</Link>
                                                                                <Link to={'/'} className="dropdown-item"><Image src={RefundIcon} className="green" />Issue Refund</Link>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                    : <tr><td colSpan={5}>No class rosters to show</td></tr>
                                                    : <tr><td colSpan={5} className="text-center">
                                                        <Spinner animation="border" variant="primary" /></td></tr>)
                                                }
                                            </tbody>
                                        </Table>
                                        {classRosterPages && classRosterPages.totalPages > 1 ?
                                            <div className="custom-pagination d-flex justify-content-right green mb-4">
                                                <span className={classRosterPages.currrentPage <= 0 ? "ml-2 disabled" : "ml-2"}
                                                    onClick={() => handlePageChange(classRosterPages.currrentPage - 1, 1)} >
                                                    {'<'}
                                                </span>
                                                {classRosterPages.pages.map((i) =>
                                                    <span key={i}
                                                        className={classRosterPages.currrentPage === i ? "ml-2 green-alt" : "ml-2"}
                                                        onClick={() => handlePageChange(i, 1)} >
                                                        {i + 1}
                                                    </span>)}
                                                <span
                                                    className={classRosterPages.currrentPage === classRosterPages.totalPages - 1 ? "ml-2 disabled" : "ml-2"}
                                                    onClick={() => handlePageChange(classRosterPages.currrentPage + 1, 1)} >
                                                    {'>'}
                                                </span>
                                            </div>
                                            : ""}
                                    </div>
                                    <div className="text-md-right text-center msg-cls-btn">
                                        <Link className="btn btn-secondary" to="/teacher/dashboard">
                                            <i className="fas fa-envelope mr-2"></i> Message Class</Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    : <div className="text-center spinner-block"><Spinner animation="border" variant="primary" /></div>}
            </section>
            <Container className="mb-5 text-center">
                {loading ? <Modal show={true} className="loading-modal text-center">
                    <Spinner animation="border" variant="primary" />
                </Modal> : ""}
            </Container>
            <Modal show={toggle} onHide={() => setToggle(!toggle)}>
                <Modal.Body>{firebaseError ? firebaseError : 'class cancelled successfully'}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setToggle(!toggle)}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
