import React, { useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { FirebaseContext } from '../../firebase';
import ClassContext from '../Views/Main/Context/index';
import Header from '../../Components/Common/Header';
import Footer from '../../Components/Common/Footer';


export default ({ children }) => {

    const location = useLocation();
    const { handleBookDetails } = useContext(ClassContext);
    const { claims } = useContext(FirebaseContext);
    useEffect(() => {
        if (!claims && location.pathname !== '/student-registration' && location.pathname !== '/login') {
            handleBookDetails(null)
            localStorage.removeItem('classDetails');
        }
    }, [location.pathname]);


    return (
        <div>
            <Header />
            {children}
            <Footer />
        </div>
    )
}