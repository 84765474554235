import React, { useState, useContext } from 'react';
import { Container, Row, Col, Accordion, Card, Button, useAccordionToggle, AccordionContext } from 'react-bootstrap'

const FAQ = () => {

    const [collapseItem, handleCollapse] = useState({ 1: false })
    
    const handleItemCollapse = (e) => {
        const { id } = e.target
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] })
    }

    const ContextAwareToggle = ({ children, eventKey, callback }) => {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <Card.Header
                onClick={decoratedOnClick}
            >
                <Button id={0} className="accordion-main-title btn-block d-flex justify-content-between"
                 variant="link">
                    <h3 className="salmon mb-0">{children}</h3>
                    <div>
                        <div>
                            <i className={isCurrentEventKey 
                                ? "icon-minus fas fa-minus-circle" 
                                : "icon-plus fas fa-plus-circle"}>

                            </i>
                        </div>
                    </div>
                </Button>

            </Card.Header>
        );
    }

    return (
        <div className="faq-page">
            <div className="faq-header-bg"></div>
            <div className="small-header">
                <section className="content white-bg mt-0">
                    <Container className="mb-5">
                        <Row className="pt-4 mb-4">
                            <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                                <h1 className="green large"><i className="fas fa-question-circle"></i> FAQs</h1>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={12} sm={10} lg={9}>
                                <Accordion defaultActiveKey="0">
                                    <Card className="border-0">
                                        <ContextAwareToggle eventKey="0">teacher FAQ</ContextAwareToggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <Accordion>
                                                    <Card className="card-shadow border-0">
                                                        <Accordion.Toggle id={1} as={Card.Header} eventKey="3" onClick={handleItemCollapse}>
                                                            <Button id={1} className="btn-block d-flex justify-content-between green" variant="link" type="button" data-toggle="collapse" data-target="#teacherFaq"
                                                                aria-expanded="true" aria-controls="teacherFaq">
                                                                Lorem ipsum dolor sit amet?
                                                                    <div>
                                                                    <i id={1} className={!collapseItem[1] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                                </div>
                                                            </Button>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="3">
                                                            <Card.Body className="mb-0 pb-0">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur.</p>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                                <Accordion>
                                                    <Card className="card-shadow border-0">
                                                        <Accordion.Toggle id={2} as={Card.Header} eventKey="3" onClick={handleItemCollapse}>
                                                            <Button id={2} className="btn-block d-flex justify-content-between green" variant="link" type="button" data-toggle="collapse" data-target="#teacherFaq"
                                                                aria-expanded="true" aria-controls="teacherFaq">
                                                                Lorem ipsum dolor sit amet?
                                                                    <div>
                                                                    <i id={2} className={!collapseItem[2] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                                </div>
                                                            </Button>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="3">
                                                            <Card.Body className="mb-0 pb-0">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur.</p>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                                <Accordion>
                                                    <Card className="card-shadow border-0">
                                                        <Accordion.Toggle id={3} as={Card.Header} eventKey="3" onClick={handleItemCollapse}>
                                                            <Button id={3} className="btn-block d-flex justify-content-between green" variant="link" type="button" data-toggle="collapse" data-target="#teacherFaq"
                                                                aria-expanded="true" aria-controls="teacherFaq">
                                                                Lorem ipsum dolor sit amet?
                                                                    <div>
                                                                    <i id={3} className={!collapseItem[3] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                                </div>
                                                            </Button>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="3">
                                                            <Card.Body className="mb-0 pb-0">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur.</p>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                                <Accordion>
                                                    <Card className="card-shadow border-0">
                                                        <Accordion.Toggle id={4} as={Card.Header} eventKey="3" onClick={handleItemCollapse}>
                                                            <Button id={4} className="btn-block d-flex justify-content-between green" variant="link" type="button" data-toggle="collapse" data-target="#teacherFaq"
                                                                aria-expanded="true" aria-controls="teacherFaq">
                                                                Lorem ipsum dolor sit amet?
                                                                    <div>
                                                                    <i id={4}className={!collapseItem[4] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                                </div>
                                                            </Button>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="3">
                                                            <Card.Body className="mb-0 pb-0">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur.</p>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                                <Accordion>
                                                    <Card className="card-shadow border-0">
                                                        <Accordion.Toggle id={5} as={Card.Header} eventKey="3" onClick={handleItemCollapse}>
                                                            <Button id={5} className="btn-block d-flex justify-content-between green" variant="link" type="button" data-toggle="collapse" data-target="#teacherFaq"
                                                                aria-expanded="true" aria-controls="teacherFaq">
                                                                Lorem ipsum dolor sit amet?
                                                                    <div>
                                                                    <i id={4} className={!collapseItem[5] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                                </div>
                                                            </Button>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="3">
                                                            <Card.Body className="mb-0 pb-0">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur.</p>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                  
                                    </Card>
                                    <Card className="border-0">
                                        <ContextAwareToggle eventKey="1">student FAQ</ContextAwareToggle>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <Accordion>
                                                    <Card className="card-shadow border-0">
                                                        <Accordion.Toggle id={6} as={Card.Header} eventKey="3" onClick={handleItemCollapse}>
                                                            <Button id={6} className="btn-block d-flex justify-content-between green" variant="link" type="button" data-toggle="collapse" data-target="#teacherFaq"
                                                                aria-expanded="true" aria-controls="teacherFaq">
                                                                Lorem ipsum dolor sit amet?
                                                                    <div>
                                                                    <i id={6} className={!collapseItem[6] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                                </div>
                                                            </Button>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="3">
                                                            <Card.Body className="mb-0 pb-0">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur.</p>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                                <Accordion>
                                                    <Card className="card-shadow border-0">
                                                        <Accordion.Toggle id={7} as={Card.Header} eventKey="3" onClick={handleItemCollapse}>
                                                            <Button id={7} className="btn-block d-flex justify-content-between green" variant="link" type="button" data-toggle="collapse" data-target="#teacherFaq"
                                                                aria-expanded="true" aria-controls="teacherFaq">
                                                                Lorem ipsum dolor sit amet?
                                                                    <div>
                                                                    <i id="7" className={!collapseItem[7] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                                </div>
                                                            </Button>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="3">
                                                            <Card.Body className="mb-0 pb-0">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur.</p>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                                <Accordion>
                                                    <Card className="card-shadow border-0">
                                                        <Accordion.Toggle id={8} as={Card.Header} eventKey="3" onClick={handleItemCollapse}>
                                                            <Button id={8} className="btn-block d-flex justify-content-between green" variant="link" type="button" data-toggle="collapse" data-target="#teacherFaq"
                                                                aria-expanded="true" aria-controls="teacherFaq">
                                                                Lorem ipsum dolor sit amet?
                                                                    <div>
                                                                    <i id={8} className={!collapseItem[8] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                                </div>
                                                            </Button>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="3">
                                                            <Card.Body className="mb-0 pb-0">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur.</p>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                                <Accordion>
                                                    <Card className="card-shadow border-0">
                                                        <Accordion.Toggle id={9} as={Card.Header} eventKey="3" onClick={handleItemCollapse}>
                                                            <Button id={9} className="btn-block d-flex justify-content-between green" variant="link" type="button" data-toggle="collapse" data-target="#teacherFaq"
                                                                aria-expanded="true" aria-controls="teacherFaq">
                                                                Lorem ipsum dolor sit amet?
                                                                    <div>
                                                                    <i id={9} className={!collapseItem[9] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                                </div>
                                                            </Button>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="3">
                                                            <Card.Body className="mb-0 pb-0">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur.</p>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                                <Accordion>
                                                    <Card className="card-shadow border-0">
                                                        <Accordion.Toggle id={10} as={Card.Header} eventKey="3" onClick={handleItemCollapse}>
                                                            <Button id={10} className="btn-block d-flex justify-content-between green" variant="link" type="button" data-toggle="collapse" data-target="#teacherFaq"
                                                                aria-expanded="true" aria-controls="teacherFaq">
                                                                Lorem ipsum dolor sit amet?
                                                                    <div>
                                                                    <i id={10} className={!collapseItem[10] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                                </div>
                                                            </Button>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="3">
                                                            <Card.Body className="mb-0 pb-0">
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur.</p>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </div>
    );
}

export default FAQ;
