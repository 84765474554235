import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { FirebaseContext } from "../firebase";


const PublicRoute = props => {
    let  { claims, currentUserRole } = useContext(FirebaseContext);
    claims = claims ? claims : JSON.parse(localStorage.getItem('claims'))
    return (!claims ? 
             <Route {...props}/> 
             : (claims.teacher ? <Redirect to="/teacher/dashboard"/>: <Redirect to="/student/dashboard"/>) )
             
}

export default PublicRoute;