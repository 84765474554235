import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Image, Modal, Spinner } from 'react-bootstrap';
import ButtonImage from '../../../../img/FlexieIcon-secondary.svg';
import TeacherMain4 from '../../../../img/Teacher4_300x300px.jpg';
import Teacher from '../../../../firebase/model/teacher';
import { StarRatingComponent } from '../../../../Components/Common/startRating';

const TeachersList = () => {


    const [teachers, setTeachers] = useState(null);

    const fetchTeachers = async () => {
        const teachers = await Teacher.getAllTeachers();
        setTeachers(teachers);
    }

    useEffect(() => {
        fetchTeachers();
    }, [])

    return <Container className="body-inner-space p-0 teacher-list">
        <Row className="mt-5 mt-lg-5">
            <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot mt-5">
                <h1 className="salmon large"><i className="fas fa-calendar-alt" /> Teachers List</h1>
            </Col>
        </Row>
        <Row className="mt-5 mt-lg-4">

            {teachers && teachers.length ? teachers.map((t, i) =>
                <Col xl={4} key={i}>
                    <Card className="card-circle-top border-0 mb-5 custom-card class-teacher-card" >
                        <Card.Img variant="top" src={t.profileImage?t.profileImage:TeacherMain4} className="rounded-circle salmon-border" />
                        <Card.Body className="card-shadow">
                            <Card.Title className="center green">{t.fullName}</Card.Title>

                            <div className="rating text-center">
                                <StarRatingComponent size={'1x'}
                                    value={4} />
                            </div>
                            <ul className="fa-ul mt-2">
                                <li className="green"><span className="fa-li"><i className="fas fa-map-marker-alt green-alt"></i></span> <strong>Location:</strong> {t.state ? t.state : 'USA'}</li>
                                <li className="green"><span className="fa-li"><i className="fas fa-comment-alt green-alt"></i></span> <strong>Language(s):</strong> English</li>
                                <li className="green"><span className="fa-li"><i className="fas fa-certificate green-alt"></i></span> <strong>Certification:</strong> {t.YYTCredentials? t.YYTCredentials: '200'}hr</li>
                                <li className="green"><span className="fa-li"><Image src={ButtonImage} className="member-icon"/></span> <strong>Member Since: </strong>sept 2020</li>
                            </ul>
                        </Card.Body>
                        <Link to={`/profile/teacher/${t.id}`} className="btn btn-secondary btn-float"><i className="fas fa-user-circle"></i> Visit Profile</Link>
                    </Card>
            </Col>)
                    :
                <Modal show={true} className="loading-modal text-center">
                    <Spinner animation="border" variant="primary" />
                </Modal>
            }

        </Row>
    </Container>
}
export default TeachersList