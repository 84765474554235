import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Container, Row, Col, Modal, Spinner } from 'react-bootstrap';
import * as yup from "yup";
import MaskedInput from 'react-text-mask';
import mask from '../../../../utils/mask';
import firebase, { FirebaseContext } from "../../../../firebase";
import { ReactComponent as FlexieIcon_reverse } from '../../../../img/FlexieIcon-reverse.svg';
import ClassContext from "../../Main/Context";
import StudentClassContext from '../Context';

const Payment = (props) => {

    const [paymentDetails, setPaymemtDetails] = useState({});
    const schema = paymentDetails.cardNumber ?
        yup.object().shape({ cvvNumber: yup.number().required('Enter cvv Number') })
        : yup.object().shape({
            inputYear: yup.string().required('expiration year is required'),
            inputMonth: yup.string().required('expiration month is required'),
            cvvNumber: yup.number().required('Enter cvv Number'),
            zip: yup.number().required("Zip Code is required"),
            ccNumber: yup.number().required('Enter credit card Number')
        });
    const { register, handleSubmit, errors, control, reset } = useForm({
        validationSchema: schema
    });
    const [firebaseError, setFirebaseError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toggle, handleToggle] = useState(false)

    const { claims } = useContext(FirebaseContext);
    const { handleBookDetails, classDetail, handleBookClass } = useContext(ClassContext)
    const { studentProfileDetails } = useContext(StudentClassContext);

    async function onSubmit(data) {
        try {
            setLoading(!loading);
            if (classDetail.amount && classDetail.amount > 0) {
                if(paymentDetails.cardNumber){
                    await firebase.studentPayment({ ...data, ...classDetail,
                     customerProfileId: studentProfileDetails.customerProfileId,
                    customerPaymentProfileId: studentProfileDetails.customerPaymentProfileId })
                } else {
                    await firebase.createProfile({ ...data,
                         ...classDetail,
                        id: studentProfileDetails.id,
                        ...studentProfileDetails.data
                       })
                       
                }
            } 
            handleAfterPayment();
        } catch (err) {
            let message = !err.response ? err.message :
                ((err.response.data && err.response.data.error)
                    ? err.response.data.error
                    : "Error occured during payment, try again")
            setLoading(false);
            setFirebaseError(message);
            handleToggle(!toggle)
        }
    }

    const handleAfterPayment = async () => {
        if (classDetail) {
            handleBookClass(classDetail);
        }
        setLoading(false);
        reset();
        setFirebaseError(null);
        !classDetail && handleToggle(!toggle)
    }

    const handleDialogClose = () => {
        handleToggle(!toggle)
        props.history.push('/student/dashboard');

    }

    const handleCancel = () => {
        handleBookDetails(null);
        props.history.goBack();
    }

    const fetchPaymentDetails = async () => {
        const { customerProfileId = "", customerPaymentProfileId = "" } = studentProfileDetails;
        if (customerProfileId && customerPaymentProfileId) {
            setLoading(!loading);
            await firebase.getProfile(customerProfileId, customerPaymentProfileId)
                .then(({ data }) => {
                    setPaymemtDetails(data.paymentProfile.payment.creditCard)
                    setLoading(false);
                }).catch((err)=>{
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        fetchPaymentDetails();
    }, [studentProfileDetails])

    return (
        <div className="body-header-space">
            <Container className="mb-4">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="pt-4 mb-4">
                        <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                            <h1 class="green large float-left">Payment</h1>
                            {/* <Link class="float-right salmon text-underline mt-4" to="/teacher-registration">I'm a Yoga Instructor</Link> */}
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={10} lg={8}>
                            <div class="card green-bg mt-3">
                                <div class="card-body">
                                    <h4 class="green-alt">Step 2: Payment Info</h4>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Credit Card #</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            {paymentDetails.cardNumber
                                                ? <Form.Control
                                                    type="text"
                                                    value={paymentDetails.cardNumber}
                                                    autoComplete="off"
                                                    disabled
                                                />
                                                : <Controller
                                                    as={
                                                        <MaskedInput
                                                            name="ccNumber"
                                                            className="form-control"
                                                            mask={mask}
                                                        />}
                                                    control={control}
                                                    name="ccNumber" />}
                                            {(errors['ccNumber'] && errors['ccNumber'].type === "required") && <span className="alert">Enter a credit card number</span>}
                                            {(errors['ccNumber'] && errors['ccNumber'].type === "typeError") && <span className="alert">Please enter valid credit card number</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Exp. Date</Form.Label>
                                        {paymentDetails.expirationDate
                                            ? <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                                <Form.Control
                                                    type="text"
                                                    value={paymentDetails.expirationDate}
                                                    autoComplete="off"
                                                    disabled
                                                />
                                            </Col>
                                            : <>
                                                <Col sm={4} lg={3}>
                                                    <Controller
                                                        as={
                                                            <Form.Control
                                                                required
                                                                name="inputMonth"
                                                                as="select"
                                                            >
                                                                <option>Month</option>
                                                                <option>01</option>
                                                                <option>02</option>
                                                                <option>03</option>
                                                                <option>04</option>
                                                                <option>05</option>
                                                                <option>06</option>
                                                                <option>07</option>
                                                                <option>08</option>
                                                                <option>09</option>
                                                                <option>10</option>
                                                                <option>11</option>
                                                                <option>12</option>
                                                            </Form.Control>
                                                        }
                                                        name="inputMonth"
                                                        control={control}
                                                    />
                                                </Col>
                                                <Col sm={4} lg={3} className="mt-3 mt-sm-0">
                                                    <Controller
                                                        as={
                                                            <Form.Control
                                                                required
                                                                name="inputYear"
                                                                as="select"
                                                            >
                                                                <option selected>Year</option>
                                                                <option>2020</option>
                                                                <option>2021</option>
                                                                <option>2022</option>
                                                                <option>2023</option>
                                                                <option>2024</option>
                                                                <option>2025</option>
                                                                <option>2026</option>
                                                                <option>2027</option>
                                                                <option>2028</option>
                                                                <option>2029</option>
                                                            </Form.Control>
                                                        }
                                                        name="inputYear"
                                                        control={control}
                                                    />
                                                </Col>
                                            </>}
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Col sm={4} lg={3} />
                                        <Col className="mt-3 mt-sm-0">{((errors['inputMonth'] || errors['inputYear'])
                                            && ((errors['inputYear'] && errors['inputYear'].type === "required") || (errors['inputMonth'] && errors['inputMonth'].type === "required"))) && <span className="alert">please select date of credit card expiration</span>}</Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">CVV <i className="fas fa-question-circle" data-toggle="tooltip" data-placement="top"></i></Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <Form.Control
                                                required
                                                name="cvvNumber"
                                                type="text"
                                                ref={register}
                                            />
                                            {(errors['cvvNumber'] && errors['cvvNumber'].type === "typeError") && <span className="alert">Enter valid cvv number</span>}
                                        </Col>
                                    </Form.Group>
                                    {!paymentDetails.cardNumber && <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Billing Zip Code</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <Form.Control
                                                required
                                                name="zip"
                                                type="text"
                                                placeholder="Zip Code"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                            {(errors['zip'] && errors['zip'].type === "typeError") && <span className="alert">Enter valid zip code</span>}
                                        </Col>
                                    </Form.Group>}
                                    <div className="btn-float d-flex align-items-center justify-content-center w-100">
                                        <Button variant="primary" type="submit" className="btn-secondary mr-2 d-flex justify-content-center align-items-center">
                                            <FlexieIcon_reverse className="btn-icon" />Submit
                                                             </Button>
                                        <Button variant="primary" onClick={handleCancel} className="btn-salmon d-flex justify-content-center align-items-center">
                                            <FlexieIcon_reverse className="btn-icon" />Cancel</Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
                {loading ? <Modal show={true} className="loading-modal text-center">
                    <Spinner animation="border" variant="primary" />
                </Modal> : ""}
            </Container>
            <Modal show={toggle} onHide={() => handleToggle(!toggle)}>
                <Modal.Body>{firebaseError ? firebaseError : 'payment done successfully, your class is booked.'}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={firebaseError ? () => handleToggle(!toggle) : handleDialogClose}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Payment;