import React, { useState } from "react";
import firebase from "../../firebase";

function useAuth() {
  const [authUser, setAuthUser] = React.useState(null);
  const [claims, setClaims] = React.useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [useTeacherLayout, setUseTeacherLayout] = React.useState(false);

  const handleSetTeacherLayout = function () {
    setUseTeacherLayout(!useTeacherLayout);
  }

  const switchUser = (role) => {
    if (claims.teacher) {
      setCurrentUserRole(role);
      localStorage.setItem('currentUserRole', role);
    }
  }

  React.useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(user => {
      if (user) {
        setAuthUser(user);
        localStorage.setItem('user', JSON.stringify(user));
        user.getIdTokenResult(true).then((idTokenResult) => {
          setClaims(idTokenResult.claims);
          let currentUserRole = localStorage.getItem('currentUserRole')
          localStorage.setItem('currentUserRole', currentUserRole ? currentUserRole : (!idTokenResult.claims.teacher ? 'Student' : 'Teacher'))
          setCurrentUserRole(localStorage.getItem('currentUserRole'))
          localStorage.setItem('claims', JSON.stringify(idTokenResult.claims));
          setUseTeacherLayout(idTokenResult.claims.teacher);
        })
      } else {
        setAuthUser(null);
        setClaims(null);
        setUseTeacherLayout(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return { authUser, claims, useTeacherLayout, handleSetTeacherLayout, switchUser, currentUserRole, setCurrentUserRole, setClaims};
}

export default useAuth;