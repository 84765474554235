import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { FirebaseContext } from "../firebase";
import  StudentClassContext  from '../Containers/Views/Student/Context';


const PaymentRoute = props => {
    let { claims, classDetail } = useContext(FirebaseContext);
    const { checkBookedClass } = useContext(StudentClassContext);
    claims = claims ? claims : JSON.parse(localStorage.getItem('claims'))
    classDetail = classDetail ? classDetail : JSON.parse(localStorage.getItem('classDetails'))
    return (claims ? (classDetail ?
            (classDetail.amount && classDetail.amount > 0 && !checkBookedClass(classDetail.classId)
                ? <Route {...props} />
                : <Redirect to={`/class/details/${classDetail.classId}`} />)
            : <Redirect to='/student/dashboard' />)
            : (<Redirect to='/login' />))
        
}

export default PaymentRoute;