import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import DateTimePicker from 'react-datetime-picker'
import { useForm, Controller } from "react-hook-form";
import { Container, Row, Col, Card, Button, Table, Image, Accordion, Dropdown, Spinner, Form, FormGroup, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ClassCard1 from '../../../../img/class-card_1.jpg';
import StudentClassContext from '../Context';
import ClassContext from '../../Main/Context'
import { formatDuration, checkClassCancelStatus } from '../../../../utils/helper';

import { StarRatingComponent } from '../../../../Components/Common/startRating';
import { DisplayModal } from '../../../../Components/Common/Modal';

const DashboardStudent = (props) => {
    const { studentUpcomingClasses, studentPreviousClasses, upcomingClassesPages, studentProfileDetails,
        previousClassesPages, handlePageChange, interestedClasses, searchedClasses,
         handlSearchClass, cancelClass, nextClassHappening } = useContext(StudentClassContext);

    const { handleBookClass, handleBookDetails, claims, ShowModal } = useContext(ClassContext);
    const { control, getValues } = useForm({});
    const [toggle, handleToggle] = useState(false);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [classDetails, setClassDetails] = useState(null);

    const handleBooking = (e, classId, amount) => {
        handleBookDetails({ classId, amount: amount || 0, user: claims.user_id });
        if (amount && amount > 0)
            props.history.push('/student/payment');
        else handleBookClass({ classId, amount });
    }

    const handleClassCancel = (classDetails) => {
        setMessage("Are you sure you want to cancel this class?");
        setClassDetails(classDetails);
        handleToggle(!toggle);
    }

    const classCancel = async () => {
        setLoading(true);
        handleToggle(!toggle)
        await cancelClass(classDetails);
        setLoading(false)
    }

    useEffect(() => {
        Object.keys(studentProfileDetails).length && setLoading(false)
    }, [studentProfileDetails])
    const classesInInterestedSection = searchedClasses.length ? searchedClasses : interestedClasses;
    return (
        !loading ? <div className="body-header-space student-dashboad">
            <section className="content white-bg">
                <Container className="mb-5">
                    <Row>
                        <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                            <h1 className="green large"><i className="fas fa-th"></i> dashboard</h1>
                        </Col>
                    </Row>
                    <Row className="mt-5 mb-5 mb-md-0">
                        <Col xs={12} md={5} lg={4} xl={3} className="justify-content-center text-align-center mb-4 mb-md-0">
                            <Container>
                                <Row className="justify-content-center align-items-center mb-3 mb-md-0">
                                    <Col xs={12} sm={7} md={12} className="image-wrap image-color-with-white">
                                        <div className="img-circle">
                                            {!studentProfileDetails.profileImage ? <i className="fas fa-user icon-img user-icon"></i>
                                                :
                                                <Image className="user-icon" src={studentProfileDetails.profileImage} roundedCircle />}
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={4} md={12} className="salmon mt-3 mt-md-2 ml-md-4"><h1 className="text-center">{studentProfileDetails.FullName}</h1></Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col xs={12} sm={6} md={12} className="p-2 justify-content-center">
                                        <div className="rounded-40 yellow-bg px-3 px-md-3 d-flex align-items-center flow-card p-2 justify-content-center">
                                            <div className="p-2"><i className="fas fa-search green fa-2x" /></div>
                                            <div className="p-2">
                                                <h5 className="m-0 green larger profile-find-btn"><Link to={`/class-finder`}>Find a Class</Link></h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={12} className="p-2 text-align-center">
                                        <div className="rounded-40 yellow-bg px-3 px-md-3 d-flex align-items-center flow-card p-2 justify-content-center">
                                            <div className="py-2"><i className="fas fa-user-circle green fa-2x" /></div>
                                            <div className="p-2">
                                                <h5 className="m-0 green larger profile-find-btn"><Link to={`/student/profile`}>Edit Profile</Link></h5>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col xs={12} md={7} lg={8} xl={9}>
                            <h1 className="green mb-3"><i className="fas fa-calendar-alt green-alt" /> next class</h1>
                            <Card className="full-detail-card card-shadow next-class-card">
                                <Card.Body>
                                    {studentUpcomingClasses && studentUpcomingClasses.length > 0 ? <Row className="h-100 block-card">
                                        <Col xs={12} lg={7} xl={8}>
                                            <div className="card-img">
                                                <Image src={studentUpcomingClasses[0].studentClass.classImage ?
                                                    studentUpcomingClasses[0].studentClass.classImage : ClassCard1} />
                                                <div className="tag-img">
                                                    <div className="img">
                                                        {studentUpcomingClasses[0].teacher.profileImage ? <Image src={studentUpcomingClasses[0].teacher.profileImage}
                                                            className="img-fluid rounded-circle salmon-border small-border" />
                                                            : <i className="fas fa-user fa-3x reverse mt-4"></i>}
                                                    </div>
                                                    <div className="tag-link d-flex">
                                                        <i className="fas fa fa-user green-alt mr-2 mt-1" />
                                                        <div className="name-link">
                                                            <span><Link className="p-0 d-inline-block" to={`/profile/teacher/${studentUpcomingClasses[0].teacher.id}`}>
                                                                {studentUpcomingClasses[0].teacher.fullName}</Link>
                                                            </span>
                                                            <div className="rating-link-row">
                                                                <StarRatingComponent value={4} size={'1x'} editing={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={5} xl={4}>
                                            <div className="detail-right">
                                                <div className="creative-flow-content">
                                                    <h4 className="green">{studentUpcomingClasses[0].studentClass.className}</h4>
                                                    <p className="green">
                                                        {studentUpcomingClasses[0].studentClass.classDescription}
                                                    </p>
                                                    <div className="flow-content-list">
                                                        <i className="fas fa-calendar-alt green-alt" />
                                                        <strong className="green">Time: </strong>
                                                        <span className="green">{moment(studentUpcomingClasses[0].studentClass.date).format('MMMM Do, h:mm a')}</span>
                                                    </div>
                                                    <div className="flow-content-list">
                                                        <i className="fas fa fa-signal green-alt" />
                                                        <strong className="green">Difficulty: </strong>
                                                        <span className="green">{studentUpcomingClasses[0].studentClass.difficultyLevel}</span>
                                                    </div>
                                                    <div className="flow-content-list">
                                                        <i className="fas fa-clock green-alt" />
                                                        <strong className="green">Duration: </strong>
                                                        <span className="green">{formatDuration(studentUpcomingClasses[0].studentClass.duration)}</span>
                                                    </div>
                                                    <div className="flow-content-list">
                                                        <i className="fas fa-comment green-alt" />
                                                        <strong className="green">Language: </strong>
                                                        <span className="green">English</span>
                                                    </div>
                                                    <div className="flow-content-list">
                                                        <i className="fas fa-dollar-sign green-alt" />
                                                        <strong className="green">Price: </strong>
                                                        <span className="green">${studentUpcomingClasses[0].studentClass.price
                                                            ? studentUpcomingClasses[0].studentClass.price : "FREE"}</span>
                                                    </div>
                                                </div>
                                                <div className="detail-btn mt-3 d-flex">
                                                    <Link to={`/class/details/${studentUpcomingClasses[0].studentClass.id}`} className="btn btn-secondary mb-2 mb-md-0 mr-0 mr-md-2 d-flex align-items-center">
                                                        <i className="fas fa-list" />
                                                                Details
                                                            </Link>
                                                    {!nextClassHappening && !checkClassCancelStatus(studentUpcomingClasses[0].studentClass.date)
                                                    ?
                                                    <Button className="btn btn-salmon btn-secondary d-flex align-items-center" 
                                                    onClick={() => handleClassCancel(studentUpcomingClasses[0])}
                                                    disabled={studentUpcomingClasses[0].studentClass.canceled? true: false}>
                                                       
                                                    {!studentUpcomingClasses[0].studentClass.canceled ? <i className="fas fa-calendar-times" /> : <i className="fas fa-times" />}
                                                               {!studentUpcomingClasses[0].studentClass.canceled? 'Cancel' : 'Cancelled'}
                                                            </Button>
                                                            : ""}
                                                </div>
                                                {nextClassHappening && <div className="mt-3 join-class">
                                                <a target="_blank" href={studentUpcomingClasses[0].studentClass.streamingUrl}><Button variant="secondary" className="btn" >
                                                            <i className="fas fa-calendar-alt "/> Join Class
                                                        </Button></a>
                                                </div>}
                                            </div>
                                        </Col>
                                    </Row> :
                                        <Row className="green-alt-bg h-100 block-card">
                                            <Container>
                                                <div className="upcoming-classes-block h-100 d-flex flex-column justify-content-center">
                                                    <Row className="reverse">
                                                        <Col xs={'auto'} className="text-align-right"><i className="fas fa-exclamation-triangle fa-3x" /></Col>
                                                        <Col xs={8} md={9} lg={10}>
                                                            <h3 className="mb-0">You haven't booked any upcoming classes</h3>
                                                            <p className="mb-0">See the suggested classes below or use the class search tool to find your perfect class.</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className="schedul-filter-row align-items-end mt-4 flex-column flex-lg-row">
                                                        <Col className="mb-3 mb-lg-0">
                                                            <FormGroup className="date-filter d-flex flex-column mw-100">
                                                                <Form.Label className="green">Date</Form.Label>
                                                                <Controller
                                                                    as={
                                                                        <DateTimePicker
                                                                        />}
                                                                    name="date"
                                                                    defaultValue={new Date()}
                                                                    control={control}
                                                                />
                                                            </FormGroup>

                                                        </Col>
                                                        <Col className="mb-3 mb-lg-0">
                                                            <FormGroup className="time-filter d-flex flex-column mw-100">
                                                                <Form.Label className="green">Time</Form.Label>
                                                                <Controller
                                                                    as={
                                                                        <TimePicker
                                                                            showSecond={false}
                                                                            format={'hh:mm a'}
                                                                            use12Hours
                                                                            inputReadOnly
                                                                            clearIcon={<i className="fas fa-times" />}
                                                                        />}
                                                                    control={control}
                                                                    placeholder="00:00"
                                                                    name="time"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <Button className="green-bg green-border yellow" onClick={() => handlSearchClass(getValues('date'))}>
                                                                <i className="fas fa-search mr-1"></i> Search
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Container>
                                        </Row>}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="order-1 order-lg-3 mb-3 mb-lg-0 mt-md-5">
                            <Card className="salmon-bg border-0 white-bg">
                                <Accordion>
                                    <Accordion.Toggle as={Card.Header} eventKey="3">
                                        <Button className="btn btn-salmon btn-block text-left salmon text-white" type="button">
                                            <i className="fas fa-exclamation-triangle reverse"></i> Alerts
                                            <span className="float-right reverse d-inline-block">2 New Alerts</span>
                                        </Button>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse id="alertPane" eventKey="3">
                                        <Card.Body className="mb-0 pb-0">
                                            <Row>
                                                <Col xs={12}>
                                                    <div className="card red-bg mb-3 border-0">
                                                        <div className="card-body p-3">
                                                            <p className="reverse m-0"><strong>6/20/2020</strong> Your credit card has expired. <a href="#" className="reverse">Please click here to update info</a>.</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <Card className="red-bg mb-3 border-0">
                                                        <Card.Body className="card-body p-3">
                                                            <p className="reverse m-0"><strong>6/20/2020</strong> Your credit card has expired. <a href="#" className="reverse">Please click here to update info</a>.</p>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Accordion>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-center mt-4">
                            <div className="d-flex justify-content-between">
                                <h2 className="green"><i className="fas fa-calendar-alt green-alt" /> Classes you may be interested in</h2>
                            </div>
                        </Col>
                        {classesInInterestedSection.length ? classesInInterestedSection.map((c, i) =>
                            <Col xs={12} lg={6} xl={3} key={i}>
                                <Card className="custom-card card-shadow mb-5">
                                    <Card.Header>
                                        <Card.Img variant="top" src={c.c.classImage} />
                                        <div className="default-image-profile auth-user">
                                            {c.t.profileImage ? <Image src={c.t.profileImage} className="img-fluid rounded-circle salmon-border small-border" />
                                                : <i className="fas fa-user fa-3x reverse mt-4"></i>}
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title className="center green">{c.c.className}</Card.Title>
                                        <ul className="fa-ul">
                                            <li className="green"><span className="fa-li"><i className="fas fa-calendar-alt green-alt"></i></span>
                                                <strong>Time:&nbsp; </strong>{c.c.date && moment(c.c.date).format('MMMM Do, h:mm a')}</li>
                                            <li className="green">
                                                <span className="fa-li"><i className="fas fa-user green-alt"></i></span>
                                                <strong>Instructor:&nbsp; </strong>
                                                <div className="link-rating d-flex flex-column">
                                                    <Link className="p-0 d-inline-block" to={`/profile/teacher/${c.t.id}`}>{c.t.fullName && c.t.fullName}</Link>
                                                    <div className="rating-link-row">
                                                        <StarRatingComponent size={'1x'}
                                                            value={4} />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="green"><span className="fa-li"><i className="fas fa-signal green-alt"></i></span> <strong>Difficulty:&nbsp; </strong> {c.c.difficultyLevel}</li>
                                            <li className="green"><span className="fa-li"><i className="fas fa-clock green-alt"></i></span> <strong>Duration:&nbsp;  </strong> {formatDuration(c.c.duration)}</li>
                                            <li className="green"><span className="fa-li"><i className="fas fa-dollar-sign green-alt"></i></span> <strong>Price:&nbsp;  </strong>{c.c.price ? c.c.price : 'Free'} </li>
                                        </ul>
                                        <div className="btn-float d-flex align-items-center justify-content-center w-100">
                                            <Button variant="primary" className="btn-secondary mr-2 d-flex justify-content-center align-items-center"> <i className="fas fa-list mr-2" /><Link className="reverse" to={`/class/details/${c.c.id}`}>Details</Link></Button>
                                            <Button variant="primary"
                                                className="btn-salmon d-flex justify-content-center align-items-center"
                                                onClick={(e) => handleBooking(e, c.c.id, c.c.price)}
                                            >
                                                {!c.c.canceled ? <i className="fas fa-calendar-plus mr-2" /> : <i className="fas fa-times mr-2" />}
                                            Book
                                         </Button>


                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ) : ""}
                    </Row>
                    {studentUpcomingClasses && studentUpcomingClasses.length ? <Row>
                        <Col xs={12} lg={8} lg={12} className="mb-3 mb-lg-20 mt-lg-5">
                            <div className="d-flex justify-content-between"><h2 className="green"><i className="fas fa-clock green-alt" /> upcoming classes</h2>
                            </div>
                            <Card className="card-shadow">
                                <Card.Body>
                                    <div className="table-responsive">
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th >date </th>
                                                    <th >name</th>
                                                    <th className="d-none d-md-table-cell">teacher</th>
                                                    <th>price </th>
                                                    <th>actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className="green">
                                                {(studentUpcomingClasses ? studentUpcomingClasses.length ? studentUpcomingClasses.slice(upcomingClassesPages.first, upcomingClassesPages.last).map((c, i) =>
                                                    <tr key={i}>
                                                        <td>{c.studentClass.date && moment(c.studentClass.date).format('MM/DD/YY hh:mm A')}</td>
                                                        <td>{c.studentClass.className}</td>
                                                        <td className="d-none d-md-table-cell">{c.teacher && c.teacher.fullName}</td>
                                                        <td>{c.studentClass.price ? c.studentClass.price : "FREE"}</td>
                                                        <td>
                                                            <div className="actions d-none d-md-block">
                                                                <Link to={`/class/details/${c.studentClass.id}`}><i className="fas fa-eye ml-3" /></Link>
                                                                {checkClassCancelStatus(c.studentClass.date) || c.studentClass.canceled? "" :<Link to={`/student/dashboard`} onClick={() => handleClassCancel(c)}><i className="fas fa-times-circle ml-3"></i></Link>}
                                                            </div>
                                                            <div className="actions-mobile d-md-none">
                                                                <div className="btn-group">
                                                                    <Dropdown className="nav-item">
                                                                        <Dropdown.Toggle id="dropdown-custom-1" className="btn btn-salmon btn-sm dropdown-toggle">
                                                                            Actions
                                                                    </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <Link to={`/class/details/${c.studentClass.id}`}><i className="fas fa-eye ml-3" /></Link>
                                                                            <Link to={`/student/dashboard`}><i className="fas fa-times-circle ml-3"></i></Link>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                                    : <tr><td colSpan={6}>No classes</td></tr>
                                                    : <tr><td colSpan={6} className="text-center">
                                                        <Spinner animation="border" variant="primary" /></td></tr>)
                                                }
                                            </tbody>
                                        </Table>
                                        {upcomingClassesPages && upcomingClassesPages.totalPages > 1 ? <div className="custom-pagination d-flex justify-content-right green">
                                            <span className={upcomingClassesPages.currrentPage <= 0 ? "ml-2 disabled" : "ml-2"} onClick={() => handlePageChange(upcomingClassesPages.currrentPage - 1, 1)} disabled={upcomingClassesPages.currrentPage <= 0} >{'<'} </span>
                                            {upcomingClassesPages.pages.map((i) => <span key={i} className={upcomingClassesPages.currrentPage === i ? "ml-2 green-alt" : "ml-2"} onClick={() => handlePageChange(i, 1)} >{i + 1}</span>)}
                                            <span className="ml-2" onClick={() => handlePageChange(upcomingClassesPages.currrentPage + 1, 1)} disabled={upcomingClassesPages.currrentPage === upcomingClassesPages.totalPages - 1}>{'>'}</span>
                                        </div>
                                            : ""}
                                        {toggle && <DisplayModal {...{
                                            toggle,
                                            handleToggle,
                                            message,
                                            firstText: "Confirm",
                                            secondText: "Cancel",
                                            headerText: "Cancel Class",
                                            firstMethod: classCancel
                                        }} />}
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> : ""}
                    {studentPreviousClasses && studentPreviousClasses.length ? <Row>
                        <Col xs={12} lg={8} lg={12} className="mb-3 mb-lg-20 mt-lg-5">
                            <div className="d-flex justify-content-between"><h2 className="green">
                                <i className="fas fa-tasks green-alt" /> previous classes</h2>
                            </div>
                            <Card className="card-shadow">
                                <Card.Body>
                                    <div className="table-responsive">
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th >date </th>
                                                     <th >name </th>
                                                    <th className="d-none d-md-table-cell">teacher </th>
                                                    <th>price </th>
                                                    <th>actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className="green">
                                                {(studentPreviousClasses ? studentPreviousClasses.length ? studentPreviousClasses.slice(previousClassesPages.first, previousClassesPages.last).map((c, i) =>
                                                    <tr key={i}>
                                                        <td>{c.studentClass.date && moment(c.studentClass.date).format('MM/DD/YY hh:mm A')}</td>
                                                        <td>{c.studentClass.className}</td>
                                                        <td className="d-none d-md-table-cell">{c.teacher && c.teacher.fullName}</td>
                                                        <td>{c.studentClass.price ? c.studentClass.price : "--"}</td>
                                                        <td>
                                                            <div className="actions d-none d-md-block">
                                                                <Link to={`/class/details/${c.studentClass.id}`}><i className="fas fa-eye ml-3" /></Link>
                                                            </div>
                                                            <div className="actions-mobile d-md-none">
                                                                <div className="btn-group">
                                                                    <Dropdown className="nav-item">
                                                                        <Dropdown.Toggle id="dropdown-custom-1" className="btn btn-salmon btn-sm dropdown-toggle">
                                                                            Actions
                                                                    </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <Link to={`/class/details/${c.studentClass.id}`}><i className="fas fa-eye ml-3" /></Link>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                                    : <tr><td colSpan={6}>No classes</td></tr>
                                                    : <tr><td colSpan={6} className="text-center">
                                                        <Spinner animation="border" variant="primary" /></td></tr>)
                                                }
                                            </tbody>
                                        </Table>
                                        {previousClassesPages && previousClassesPages.totalPages > 1 ? <div className="custom-pagination d-flex justify-content-right green">
                                            <span className={previousClassesPages.currrentPage <= 0 ? "ml-2 disabled" : "ml-2"} onClick={() => handlePageChange(previousClassesPages.currrentPage - 1, 2)} disabled={previousClassesPages.currrentPage <= 0} >{'<'} </span>
                                            {previousClassesPages.pages.map((i) => <span key={i} className={previousClassesPages.currrentPage === i ? "ml-2 green-alt" : "ml-2"} onClick={() => handlePageChange(i, 2)} >{i + 1}</span>)}
                                            <span className="ml-2" onClick={() => handlePageChange(previousClassesPages.currrentPage + 1, 2)} disabled={previousClassesPages.currrentPage === previousClassesPages.totalPages - 1}>{'>'}</span>
                                        </div>
                                            : ""}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> : ""}
                </Container>
            </section>
        </div> : <Modal show={true} className="loading-modal text-center">
                <Spinner animation="border" variant="primary" />
            </Modal>
    );
}

export default DashboardStudent;