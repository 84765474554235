import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Container, Row, Col, Card, Image, Spinner } from 'react-bootstrap';

import { StarRatingComponent } from '../../../../Components/Common/startRating'
import { FirebaseContext } from "../../../../firebase";
import ButtonImage from '../../../../img/FlexieIcon-secondary.svg';
import Class from '../../../../firebase/model/Classes';
import { formatDuration } from '../../../../utils/helper';
import ClassContext from '../Context';
import BookModal from '../BookModal';
import Teacher from '../../../../firebase/model/teacher';

const ClassDetails = (props) => {

    const [classDetails, setClassDetails] = useState(null);
    const [toggle, handleToggle] = useState(false);
    const [teacherDetails, setTeacherDetails] = useState({});
    let { pathname } = useLocation();
    const { claims, currentUserRole } = useContext(FirebaseContext);
    const { handleBookDetails, classRosters, handleBookClass, readyToJoinClass } = useContext(ClassContext);
    const classIdLists = classRosters ? classRosters.map(cr => cr.classId) : [];

    const fetchClassDetails = async () => {
        const id = pathname.split('/')[3]
        await Class.getById(id).then(async (snapshot) => {
            setClassDetails(snapshot);
            const teacherDetails = await Teacher.getProfileByUserId(snapshot.teacherId)
            setTeacherDetails(teacherDetails)
        }).catch((err) => {
            setClassDetails('');
        });
    }

    useEffect(() => {
        fetchClassDetails();
    }, [])

    const handleBooking = (classId) => {
        if(readyToJoinClass && readyToJoinClass.id === classId) window.open(readyToJoinClass.streamingUrl, '_blank');
        else{
        handleBookDetails({ classId, amount: classDetails.price });
        if (claims && currentUserRole === 'Student') {
            if (classDetails.price && classDetails.price > 0)
                props.history.push('/student/payment');
            else handleBookClass({ classId, amount: classDetails.price });
        }
        else handleToggle(!toggle)
    }

    }

    const checkBookingDisabled = (c) => {
        return { bookText: c.canceled ? 'Cancelled': (classIdLists.includes(c.id)? 'Booked' : 'Book Now'),
                 disable:  Boolean(c.date < new Date() || classIdLists.includes(c.id) || c.canceled) }
    }
   
    return <div className="class-details-page">
        <div className="header-salmon-bg" />
        {(classDetails && Object.keys(classDetails).length) ? <Container className="mt-4 mt-md-5">
            <Row>
                <Col xs={12} lg={12} xl={12} className="info-card">
                    <Card className="mt-0">
                        <Card.Body>
                            {!classDetails.classImage ? <div className="cover"><i class="far fa-image reverse" /></div>
                                : <Image src={classDetails.classImage}></Image>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-4 mb-5">
                <Col xl={9}>
                    <Container>
                        <Row>
                            <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                                <h1 className="green large"> {classDetails.className && classDetails.className}</h1>
                            </Col>
                            <Col xs={12} className="mt-5 class-desc">
                                <p className="green"><strong>Class Description: </strong>{classDetails.classDescription && classDetails.classDescription}</p>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={6} xl={4} className="p-2">
                                <div className="rounded-25 green-bg px-3 px-md-3 d-flex align-items-center flow-card">
                                    <div className="p-2"><i className="fas fa-calendar-alt green-alt fa-3x" /></div>
                                    <div className="p-1">
                                        <h5 className="m-0 green-alt">Time</h5>
                                        <h2 className="reverse m-0">{classDetails.date && moment(classDetails.date).format('MMMM Do, h:mm a')}</h2>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={6} xl={4} className="p-2">
                                <div className="rounded-25 green-bg px-3 px-md-3 d-flex align-items-center flow-card">
                                    <div className="p-2"><i className="fas fa-signal green-alt fa-3x" /></div>
                                    <div className="p-2"><h5 className="m-0 green-alt">Difficulty</h5>
                                        <h2 className="reverse m-0">{classDetails.difficultyLevel && classDetails.difficultyLevel}</h2>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={6} xl={4} className="p-2">
                                <div className="rounded-25 green-bg px-3 px-md-3 d-flex align-items-center flow-card">
                                    <div className="p-2"><i className="fas fa-clock green-alt fa-3x" /></div>
                                    <div className="p-2">
                                        <h5 className="m-0 green-alt">Duration</h5>
                                        <h2 className="reverse m-0">{classDetails.duration && formatDuration(classDetails.duration)}</h2>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={6} xl={4} className="p-2">
                                <div className="rounded-25 green-bg px-3 px-md-3 d-flex align-items-center flow-card">
                                    <div className="p-2"><i className="fas fa-comment green-alt fa-3x" /></div>
                                    <div className="p-2">
                                        <h5 className="m-0 green-alt">Language</h5>
                                        <h2 className="reverse m-0">English</h2>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={6} xl={4} className="p-2">
                                <div className="rounded-25 green-bg px-3 px-md-3 d-flex align-items-center flow-card">
                                    <div className="px-2"><i className="fas fa-dollar-sign green-alt fa-3x" /></div>
                                    <div className="p-2">
                                        <h5 className="m-0 green-alt">Price</h5>
                                        <h2 className="reverse m-0">${classDetails.price ? classDetails.price : 0} </h2>
                                    </div>
                                </div>
                            </Col>
                            {readyToJoinClass && readyToJoinClass.id === classDetails.id ?
                                <Col xs={12} lg={6} xl={4}
                                    className="p-2"
                                    onClick={() =>handleBooking(classDetails.id)}
                                >
                                    <div className="rounded-25 salmon-bg px-3 px-md-3 d-flex align-items-center flow-card book-now-flow">
                                        <div className="p-2">
                                            <i className="fas fa-calendar-plus fa-3x mr-2 reverse" /></div>
                                        <div className="p-2">
                                            <h2 className="reverse m-0">Join Class</h2>
                                        </div>
                                    </div>
                                </Col> :
                                <Col xs={12} lg={6} xl={4}
                                    className="p-2"
                                    onClick={() => { !checkBookingDisabled(classDetails).disable && handleBooking(classDetails.id) }}
                                >
                                    <div className="rounded-25 salmon-bg px-3 px-md-3 d-flex align-items-center flow-card book-now-flow">
                                        <div className="p-2">
                                            <i className="fas fa-calendar-plus fa-3x mr-2 reverse" /></div>
                                        <div className="p-2">
                                            <h2 className="reverse m-0">{checkBookingDisabled(classDetails).bookText}</h2>
                                        </div>
                                    </div>
                                </Col>}
                        </Row>
                    </Container>
                </Col>
                <Col xl={3}>
                    <Card className="card-circle-top border-0 mb-5 custom-card class-teacher-card">

                        {!teacherDetails.profileImage ? <div className="card-img-top rounded-circle default-image-profile-large salmon-border">
                            <i class="fas fa-user"></i>
                        </div> :
                            <Card.Img variant="top" src={teacherDetails.profileImage} className="rounded-circle salmon-border" />}

                        <Card.Body className="card-shadow">
                            <Card.Title className="center green">{teacherDetails.firstName && teacherDetails.fullName}</Card.Title>

                            <div className="rating text-center">
                                <StarRatingComponent value={4.5} size={'1x'} />
                            </div>
                            <ul className="fa-ul mt-2">
                                <li className="green"><span className="fa-li"><i className="fas fa-map-marker-alt green-alt"></i></span> <strong>Location:</strong> {teacherDetails.state ? teacherDetails.state : 'USA'}</li>
                                <li className="green"><span className="fa-li"><i className="fas fa-comment-alt green-alt"></i></span> <strong>Language(s):</strong> English</li>
                                <li className="green"><span className="fa-li"><i className="fas fa-certificate green-alt"></i></span> <strong>Certification:</strong> {teacherDetails.YYTCredentials ? teacherDetails.YYTCredentials : '200'}hr</li>
                                <li className="green"><span className="fa-li"><Image src={ButtonImage} /></span> <strong>Member Since: </strong>sept 2020</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
            :
            <div className="text-center spinner-block"><Spinner animation="border" variant="primary" /></div>}
        {(toggle && !claims) &&
            <BookModal
                {...props}
                toggle={toggle}
                handleToggle={handleToggle}
            />}
    </div>
}

export default ClassDetails;