import React from 'react';
// import FirebaseContext from "../../../../firebase/context";
import { HeroComponent } from '../HeroComponent'
import { FeatureComponent} from '../FeatureComponent'




function HomePage(props) {

    // const { firebase } = React.useContext(FirebaseContext);

   /*  firebase.db
    .collection("notes")
    .add({
      title: "Working",
      body: "This is to check the Integration is working",
    }) */

    // firebase.db.collection("notes").get().then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //         console.log(doc.id, " => ", doc.data());
    //     });
    // });

    // firebase.db.collection("homeFeaturedClasses")
    // .get()
    // .then(function(querySnapshot) {
    //     querySnapshot.forEach(function(doc) {
    //         // doc.data() is never undefined for query doc snapshots
    //         console.log(doc.id, " => ", doc.data());
    //     });
    // })
    // .catch(function(error) {
    //     console.log("Error getting documents: ", error);
    // });
    
    return(
        <div>
            <HeroComponent/>
            <FeatureComponent {...props}/>
          </div>
    );
    
}


export default HomePage;