import React from 'react'
import Header from '../../Components/Common/Header';
import Footer from '../../Components/Common/Footer';
import '../Views/Student/index.scss';


export default ({ children }) => {
    return (
        <div className="student">
            <Header />
            {children}
            <Footer />
        </div>
    )
}