import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link } from 'react-router-dom';
import { Button, Form, Container, Row, Col, Modal, Spinner } from 'react-bootstrap';
import * as yup from "yup";
import MaskedInput from 'react-text-mask';
import { formatStudentRegisterValues } from '../../../utils/helper';
import mask from '../../../utils/mask';
import firebase, { FirebaseContext } from "../../../firebase";
import ClassContext from '../../../Containers/Views/Main/Context/index';
import { ReactComponent as FlexieIcon_reverse } from '../../../img/FlexieIcon-reverse.svg';

export default function StudentRegistration(props) {

    const schemaWithPayment = yup.object().shape({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup
            .string()
            .email("Please enter a valid email")
            .required("Email is required"),
        password: yup
            .string().min(8)
            .required("Passowrd is required"),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'confirm passwords must match with password').required("Password confirmation is required"),
        inputYear: yup.string().required('expiration year is required'),
        inputMonth: yup.string().required('expiration month is required'),
        cvvNumber: yup.number().required('Enter cvv Number'),
        zip: yup.number().required("Zip Code is required"),
        ccNumber: yup.number().required('Enter credit card Number')
    });
    const schemaWithoutPayment = yup.object().shape({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup
            .string()
            .email("Please enter a valid email")
            .required("Email is required"),
        password: yup
            .string().min(8)
            .required("Passowrd is required"),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'confirm passwords must match with password').required("Password confirmation is required")
    });
  
    const { handleBookClass, classDetail } = useContext(ClassContext);
    const showPayment = classDetail && classDetail.amount && classDetail.amount > 0;
    const { register, handleSubmit, watch, errors, control, reset } = useForm({
        validationSchema: showPayment ? schemaWithPayment : schemaWithoutPayment
    });

    const [firebaseError, setFirebaseError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toggle, handleToggle] = useState(false)

    async function onSubmit(data) {
        try {
            setLoading(!loading);
            let result = formatStudentRegisterValues(data);
            result = classDetail ? {...result, ...classDetail} : result
            await firebase.studentRegister(result)
            .then(async({data}) => {
                if (data) {
                  handleAfterRegister(data);
                }
            })
        } catch (err) {
            let message = !err.response ? err.message :
                ((err.response.data && err.response.data.error)
                    ? err.response.data.error
                    : "Error while registering user try again")
            setLoading(false);
            setFirebaseError(message);
            handleToggle(!toggle)
        }
    }

    const handleAfterRegister = async(data) => {
        if(classDetail){
            const userId = data.uid;
            handleBookClass({...classDetail, userId});
        }
        reset();
        setLoading(false);
        setFirebaseError(null);
        !classDetail && handleToggle(!toggle)
    }

    const handleDialogClose = () => {
        handleToggle(!toggle)
        classDetail ? props.history.goBack()
         :props.history.push('/login')
         
    }
    
    return (
        <div className="body-header-space">
            <Container className="mb-4">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="pt-4 mb-4">
                        <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                            <h1 class="green large float-left">student registration</h1>
                            <Link class="float-right salmon text-underline mt-4" to="/teacher-registration">I'm a Yoga Instructor</Link>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={10} lg={8}>
                            <h3 class="salmon">You’re less than 3 minutes from getting started!</h3>
                            <p>Fill out the required information below. You will have the option to add
                        more information to your profile later, but this is all we need for now.</p>
                            <div class="card green-alt-bg mt-3">
                                <div class="card-body">
                                    <h4 class="green">Step 1: Account Info</h4>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">First Name</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                name="firstName"
                                                type="text"
                                                placeholder="First Name"
                                                autoComplete="off"
                                                required
                                                ref={register}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Last Name</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                name="lastName"
                                                type="text"
                                                required
                                                placeholder="Last Name"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Email</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                name="email"
                                                type="text"
                                                required
                                                placeholder="Email"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                            {(errors['email'] && errors['email'].type === "email") && <span className="alert">{errors['email'].message}</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Password</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                name="password"
                                                type="password"
                                                required
                                                placeholder="Password"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                            {(errors['password'] && errors['password'].type === "min") && <span className="alert">{errors['password'].message}</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Confirm Password</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                name="confirmPassword"
                                                type="password"
                                                required
                                                placeholder="Confirm Password"
                                                autoComplete="off"
                                                ref={register({
                                                    validate: (value) => value === watch('password') || "Passwords don't match."
                                                })}
                                            />
                                            {errors['confirmPassword'] && <span className="alert">{errors['confirmPassword'].message}</span>}
                                        </Col>
                                    </Form.Group>
                                    {!showPayment &&<Button variant="salmon" type="submit" className="btn-float">
                                        <FlexieIcon_reverse className="btn-icon" />Submit
                                    </Button>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {showPayment ? <Row className="justify-content-center">
                        <Col xs={12} sm={10} lg={8}>
                            <div class="card green-bg mt-3">
                                <div class="card-body">
                                    <h4 class="green-alt">Step 2: Payment Info</h4>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Credit Card #</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <Controller
                                                as={
                                                    <MaskedInput
                                                        name="ccNumber"
                                                        className="form-control"
                                                        mask={mask}
                                                    />}
                                                control={control}
                                                name="ccNumber" />
                                            {(errors['ccNumber'] && errors['ccNumber'].type === "required") && <span className="alert">Enter a credit card number</span>}
                                            {(errors['ccNumber'] && errors['ccNumber'].type === "typeError") && <span className="alert">Please enter valid credit card number</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Exp. Date</Form.Label>
                                        <Col sm={4} lg={3}>
                                            <Controller
                                                as={
                                                    <Form.Control
                                                        required
                                                        name="inputMonth"
                                                        as="select"
                                                    >
                                                        <option>Month</option>
                                                        <option>01</option>
                                                        <option>02</option>
                                                        <option>03</option>
                                                        <option>04</option>
                                                        <option>05</option>
                                                        <option>06</option>
                                                        <option>07</option>
                                                        <option>08</option>
                                                        <option>09</option>
                                                        <option>10</option>
                                                        <option>11</option>
                                                        <option>12</option>
                                                    </Form.Control>
                                                }
                                                name="inputMonth"
                                                control={control}
                                            />
                                        </Col>
                                        <Col sm={4} lg={3} className="mt-3 mt-sm-0">
                                            <Controller
                                                as={
                                                    <Form.Control
                                                        required
                                                        name="inputYear"
                                                        as="select"
                                                    >
                                                        <option selected>Year</option>
                                                        <option>2020</option>
                                                        <option>2021</option>
                                                        <option>2022</option>
                                                        <option>2023</option>
                                                        <option>2024</option>
                                                        <option>2025</option>
                                                        <option>2026</option>
                                                        <option>2027</option>
                                                        <option>2028</option>
                                                        <option>2029</option>
                                                    </Form.Control>
                                                }
                                                name="inputYear"
                                                control={control}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Col sm={4} lg={3} />
                                        <Col className="mt-3 mt-sm-0">{((errors['inputMonth'] || errors['inputYear'])
                                            && ((errors['inputYear'] && errors['inputYear'].type === "required") || (errors['inputMonth'] && errors['inputMonth'].type === "required"))) && <span className="alert">please select date of credit card expiration</span>}</Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">CVV <i className="fas fa-question-circle" data-toggle="tooltip" data-placement="top"></i></Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <Form.Control
                                                required
                                                name="cvvNumber"
                                                type="text"
                                                ref={register}
                                            />
                                            {(errors['cvvNumber'] && errors['cvvNumber'].type === "typeError") && <span className="alert">Enter valid cvv number</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Billing Zip Code</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <Form.Control
                                                required
                                                name="zip"
                                                type="text"
                                                placeholder="Zip Code"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                            {(errors['zip'] && errors['zip'].type === "typeError") && <span className="alert">Enter valid zip code</span>}
                                        </Col>
                                    </Form.Group>
                                    <Button variant="salmon" type="submit" className="btn-float">
                                        <FlexieIcon_reverse className="btn-icon" />Submit
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>: <></>}
                </Form>
                {loading ? <Modal show={true} className="loading-modal text-center">
                    <Spinner animation="border" variant="primary" />
                </Modal> : ""}
            </Container>
            <Modal show={toggle} onHide={() => handleToggle(!toggle)}>
                <Modal.Body>{firebaseError ?( firebaseError === 'Error: User with this email already exists'?
                <span>{`It appears as though this user already exists. Either create an account with a different email address or `}<Link to="/login">login</Link></span>
                : firebaseError )
                : (classDetail?
                'Class Booked successfully, verify your email to login now. ':
                'Congratulations! You are just a couple of clicks away from starting your practice online. Check your email. We sent you an email to verify your email address.')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={firebaseError ? () => handleToggle(!toggle) : handleDialogClose}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}