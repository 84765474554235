import React, { Component } from 'react';

class Billing extends Component{
    render() {
        return(
            <div className="body-header-space">
                <h1>
                    Billing
                </h1>
            </div>
        );
    }
}

export default Billing;