import axios from 'axios';

const BaseApi = 'https://omni-277218.uc.r.appspot.com/api'

export const teacherRegistrationService = async(params) => {
    return await axios({
        method: 'post',
        url: `${BaseApi}/auth/register`,
        data: params
      });
}

export const loginService = async(params) => {
  return await axios({
      method: 'post',
      url: `${BaseApi}/auth/login`,
      data: params
    });
}

export const passwordResetService = async(params) => {
  return await axios({
      method: 'post',
      url: `${BaseApi}/auth/forgot-password`,
      data: params
    });
}

export const studentRegisterService = async(params) => {
  return await axios({
      method: 'post',
      url: `${BaseApi}/auth/register-student`,
      data: params
    });
}

export const sendContactMailService = async(params) => {
  return await axios({
    method: 'post',
    url: `${BaseApi}/auth/contact`,
    data: params
  })
}

export const sendSupportMailService = async(params) => {
  return await axios({
    method: 'post',
    url: `${BaseApi}/auth/support`,
    data: params
  })
}

export const studentPaymentService = async(params) => {
  return await axios({
    method: 'post',
    url: `${BaseApi}/auth/payment`,
    data: params
  })
}

export const sendClassCancellationMailService = async(params) => {
  return await axios({
    method: 'post',
    url: `${BaseApi}/teacher/cancel-class`,
    data: params
  })
}

export const getCustomerProfileService = async(params) => {
  return await axios({
    method: 'get',
    url: `${BaseApi}/student/profile`,
    params
  })
}

export const updateCustomerProfileService = async(params) => {
  return await axios({
    method: 'post',
    url: `${BaseApi}/student/profile`,
    data: params
  })
}

export const createCustomerProfileService = async(params) => {
  return await axios({
    method: 'post',
    url: `${BaseApi}/student/create-profile`,
    data: params
  })
}

export const sendStudentClassCancellationMailService = async(params) => {
  return await axios({
    method: 'post',
    url: `${BaseApi}/student/cancel-class`,
    data: params
  })
}

export const upgradeToTeacherRoleService = async(params) => {
  return await axios({
    method: 'post',
    url: `${BaseApi}/student/upgrade`,
    data: params
  })
}

export const updateProfile = async(params) => {
  return await axios({
    method: 'post',
    url: `${BaseApi}/auth/update-profile`,
    data: params
  })
}


