import React, { useContext, useState } from 'react';
import { Container, Row, Col, Card, Nav, Button, Image, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';


import ClassCard1 from '../../../../img/class-card_1.jpg';
import ClassCard2 from '../../../../img/class-card_2.jpg';
import ClassCard3 from '../../../../img/class-card_3.jpg';
import ClassCard4 from '../../../../img/class-card_4.jpg';

import TeacherThumb1 from '../../../../img/Teacher1_90x90px.jpg';
import TeacherThumb2 from '../../../../img/Teacher2_90x90px.jpg';
import TeacherThumb3 from '../../../../img/Teacher3_90x90px.jpg';
import ButtonImage from '../../../../img/FlexieIcon-reverse.svg';

import TeacherMain1 from '../../../../img/Teacher1_300x300px.jpg';
import TeacherMain2 from '../../../../img/Teacher2_300x300px.jpg';
import TeacherMain3 from '../../../../img/Teacher3_300x300px.jpg';
import TeacherMain4 from '../../../../img/Teacher4_300x300px.jpg';
import { formatDuration } from '../../../../utils/helper';
import ClassContext from '../Context';
import BookModal from '../BookModal';

import './Index.scss';

export const FeatureComponent = (props) => {
  const { upcomingClasses, claims, classRosters, handleBookDetails, handleBookClass } = useContext(ClassContext);
  const [toggle, handleToggle] = useState(false);
  const classIdLists = classRosters ? classRosters.map(cr => cr.classId) : [];

  const featuredClasses = upcomingClasses ? upcomingClasses.length > 4 ? upcomingClasses.slice(0, 4) : upcomingClasses : "";

  const checkBookingDisabled = (c) => {
    return {
      bookText: c.canceled ? 'Cancelled' : (classIdLists.includes(c.id) ? 'Booked' : 'Book'),
      disable: Boolean(c.date < new Date() || classIdLists.includes(c.id) || c.canceled)
    }
  }

  const handleBooking = (classId, amount) => {
    handleBookDetails({ classId, amount: amount || 0 });
    if (claims && !claims.teacher) {
      if (amount && amount > 0)
        props.history.push('/student/payment');
      else handleBookClass({ classId, amount });
    }
    else handleToggle(!toggle)

  }

  return (
    <section className="content white-bg pb-5 mt-0">
      <Container>
        <Row>
          <Col xs={12} className="text-center mt-4">
            <h2 className="green-alt mt-5 mb-5">featured and upcoming LIVE classes</h2>
          </Col>
          {(featuredClasses ? (featuredClasses.length ? featuredClasses.map((c, i) =>
            <Col xs={12} sm={6} xl={3}>
              <Card className="custom-card card-shadow mb-5">
                <Card.Header>
                  <Card.Img variant="top" src={c.c.classImage} />
                  <div className="default-image-profile auth-user">
                    {c.t.profileImage ? <Image src={c.t.profileImage} className="img-fluid rounded-circle salmon-border small-border user-icon" />
                      : <i class="fas fa-user fa-3x reverse mt-4"></i>}
                  </div>
                </Card.Header>
                <Card.Body>
                  <Card.Title className="center green">{c.c.className}</Card.Title>
                  <ul className="fa-ul">
                    <li className="green"><span className="fa-li"><i className="fas fa-calendar-alt green-alt"></i></span> <strong>Time: </strong>{moment(c.c.date).format('MMMM Do, h:mm a')}</li>
                    <li className="green"><span className="fa-li"><i className="fas fa-user green-alt"></i></span> <strong>Instructor: </strong> <Link className="p-0 d-inline-block" to={`/profile/teacher/${c.t.id}`}>{c.t.fullName}</Link></li>
                    <li className="green"><span className="fa-li"><i className="fas fa-signal green-alt"></i></span> <strong>Difficulty: </strong> {c.c.difficultyLevel}</li>
                    <li className="green"><span className="fa-li"><i className="fas fa-clock green-alt"></i></span> <strong>Duration: </strong> {formatDuration(c.c.duration)}</li>
                    <li className="green"><span className="fa-li"><i className="fas fa-dollar-sign green-alt"></i></span> <strong>Price: </strong> ${c.c.price ? c.c.price : 'Free'}</li>
                  </ul>
                  <Button variant="primary" className="btn-secondary btn-float"
                    onClick={() => handleBooking(c.c.id, c.c.price)}
                    disabled={checkBookingDisabled(c.c).disable}
                  >
                    <Image className="btn-icon" src={ButtonImage} />
                    {checkBookingDisabled(c.c).bookText}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ) : <Col xs={12} className="text-center mt-4">
          <h2 className="green-alt mt-5 mb-5">No featured and upcoming classes found</h2>
        </Col>)
            :<Col xs={12} className="text-center"><Spinner animation="border" variant="primary" /></Col>)}
        </Row>
        <Row>
          <Col xs={12} className="text-center mt-4">
            <h2 className="salmon mt-5 mb-5">meet our newest teachers</h2>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <Card className="card-circle-top border-0 mb-5 custom-card">
              <Card.Img variant="top" src={TeacherMain1} className="rounded-circle salmon-border" />
              <Card.Body className="card-shadow">
                <Card.Title className="center green">Quick Yoga</Card.Title>
                <ul className="fa-ul">
                  <li className="green"><span className="fa-li"><i className="fas fa-map-marker-alt salmon"></i></span> <strong>Location:</strong> USA</li>
                  <li className="green"><span className="fa-li"><i className="fas fa-comment-alt salmon"></i></span> <strong>Language(s):</strong> English</li>
                </ul>
                <Button variant="primary" className="btn btn-salmon btn-float"><Image className="btn-icon" src={ButtonImage} /> Book now</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <Card className="card-circle-top border-0 mb-5 custom-card">
              <Card.Img variant="top" src={TeacherMain2} className="rounded-circle salmon-border" />
              <Card.Body className="card-shadow">
                <Card.Title className="center green">Quick Yoga</Card.Title>
                <ul className="fa-ul">
                  <li className="green"><span className="fa-li"><i className="fas fa-map-marker-alt salmon"></i></span> <strong>Location:</strong> USA</li>
                  <li className="green"><span className="fa-li"><i className="fas fa-comment-alt salmon"></i></span> <strong>Language(s):</strong> English</li>
                </ul>
                <Button variant="primary" className="btn btn-salmon btn-float"><Image className="btn-icon" src={ButtonImage} /> Book now</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <Card className="card-circle-top border-0 mb-5 custom-card">
              <Card.Img variant="top" src={TeacherMain3} className="rounded-circle salmon-border" />
              <Card.Body className="card-shadow">
                <Card.Title className="center green">Quick Yoga</Card.Title>
                <ul className="fa-ul">
                  <li className="green"><span className="fa-li"><i className="fas fa-map-marker-alt salmon"></i></span> <strong>Location:</strong> USA</li>
                  <li className="green"><span className="fa-li"><i className="fas fa-comment-alt salmon"></i></span> <strong>Language(s):</strong> English</li>
                </ul>
                <Button variant="primary" className="btn btn-salmon btn-float"><Image className="btn-icon" src={ButtonImage} /> Book now</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} xl={3}>
            <Card className="card-circle-top border-0 mb-5 custom-card">
              <Card.Img variant="top" src={TeacherMain4} className="rounded-circle salmon-border" />
              <Card.Body className="card-shadow">
                <Card.Title className="center green">Quick Yoga</Card.Title>
                <ul className="fa-ul">
                  <li className="green"><span className="fa-li"><i className="fas fa-map-marker-alt salmon"></i></span> <strong>Location:</strong> USA</li>
                  <li className="green"><span className="fa-li"><i className="fas fa-comment-alt salmon"></i></span> <strong>Language(s):</strong> English</li>
                </ul>
                <Button variant="primary" className="btn btn-salmon btn-float"><Image className="btn-icon" src={ButtonImage} /> Book now</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {(toggle && !claims) && <BookModal
          {...props}
          toggle={toggle}
          handleToggle={handleToggle} />}
      </Container>
    </section>
  )
}