import React, { useState, useContext } from 'react'
import { Container, Col, Row, Dropdown, Table, Card, Modal, Button, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import TeacherClassContext from '../Context'
import { formatDuration } from '../../../../utils/helper';
import '../index.scss';
import { DisplayModal } from '../../../../Components/Common/Modal'

const ClassList = () => {
    
    const [toggle, handleToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ classId, setClassId] = useState(null);
    const { teacherUpcomingClasses, teacherPreviousClasses, upcomingClassesPages,
        previousClassesPages, handlePageChange, order, handleSort, deleteClass, 
        ShowModal } = useContext(TeacherClassContext)

    const handleDeleteClassDetail = (classId) => {
        setClassId(classId);
        handleToggle(!toggle);
    }

    const handleDeleteClass = async() => {
        setLoading(true);
        handleToggle(!toggle)
        await deleteClass(classId);
        setLoading(false)
        ShowModal('Class cancelled Successfully.')
    }

    return(!loading ?
        <Container className="body-inner-space p-0">
            <Row className="mt-5 mt-lg-5">
                <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot mt-5">
                    <h1 className="salmon large"><i className="fas fa-calendar-alt" /> Class Manager</h1>
                </Col>
            </Row>
            <Row className="mt-2 mt-lg-2">
                <Col xs={12} lg={8} lg={12} className="mb-3 mb-lg-20 mt-lg-5">
                    <div className="d-flex justify-content-between"><h2 className="green"><i className="fas fa-clock green-alt" /> upcoming classes</h2>
                        <div className="text-right">
                            <Link className="btn btn-salmon btn-float salmon-bg" to="/teacher/class-builder">
                                <i className="fas fa-calendar-plus"></i> New Class</Link>
                        </div>
                    </div>
                    <Card className="card-shadow">
                        <Card.Body>
                            <div className="table-responsive">
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th onClick={() => handleSort('date', !order.upcoming, 'upcoming')} >date <i className="fas fa-sort-down" /></th>
                                            <th onClick={() => handleSort('className', !order.upcoming, 'upcoming')} >name <i className="fas fa-sort disabled" /></th>
                                            <th onClick={() => handleSort('duration', !order.upcoming, 'upcoming')} className="d-none d-md-table-cell">duration <i className="fas fa-sort disabled" /></th>
                                            <th onClick={() => handleSort('studentsEnrolled', !order.upcoming, 'upcoming')} >registered <i className="fas fa-sort disabled" /></th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="green">
                                        {(teacherUpcomingClasses && upcomingClassesPages ? teacherUpcomingClasses.length ?
                                            teacherUpcomingClasses.slice(upcomingClassesPages.first, upcomingClassesPages.last).map((c, i) =>
                                                <tr key={c.id}>
                                                    <td>{c.date && moment(c.date).format('MM/DD/YY hh:mm A')}</td>
                                                    <td>{c.className}</td>
                                                    <td className="d-none d-md-table-cell">{formatDuration(c.duration)}</td>
                                                    <td>{c.studentsEnrolled && c.studentsEnrolled}</td>
                                                    <td>
                                                        <div className="actions d-none d-md-block">
                                                            <Link to={`/teacher/classes/${c.id}`}><i className="fas fa-eye ml-3" /></Link>
                                                            {!c.canceled && <Link to={`/teacher/class-builder/${c.id}`}><i className="fas fa-edit ml-3"></i></Link>}
                                                            <Link onClick={() => handleDeleteClassDetail(c.id)}><i className="fas fa-trash-alt ml-3" /></Link>
                                                        </div>
                                                        <div className="actions-mobile d-md-none">
                                                            <div className="btn-group">
                                                                <Dropdown className="nav-item">
                                                                    <Dropdown.Toggle id="dropdown-custom-1" className="btn btn-salmon btn-sm dropdown-toggle">
                                                                        Actions
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Link to={`/teacher/classes/${c.id}`} className="dropdown-item"><i className="fas fa-eye ml-3" /> View</Link>
                                                                        {!c.canceled && <Link to={`/teacher/class-builder/${c.id}`} className="dropdown-item"><i className="fas fa-edit ml-3"></i> Edit</Link>}
                                                                        <Dropdown.Divider></Dropdown.Divider>
                                                                        <Link onClick={() => handleDeleteClassDetail(c.id)} className="dropdown-item"><i className="fas fa-trash-alt ml-3" /> Delete</Link>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                            : <tr><td colSpan={5}>No upcoming classes</td></tr>
                                            : <tr><td colSpan={5} className="text-center">
                                                <Spinner animation="border" variant="primary" /></td></tr>)
                                        }
                                    </tbody>
                                </Table>
                                {upcomingClassesPages && upcomingClassesPages.totalPages > 1 ? <div className="custom-pagination d-flex justify-content-right green">
                                    <span className={upcomingClassesPages.currrentPage <= 0 ? "ml-2 disabled" : "ml-2"} onClick={() => handlePageChange(upcomingClassesPages.currrentPage - 1, 1)} >{'<'} </span>
                                    {upcomingClassesPages.pages.map((i) => <span key={i} className={upcomingClassesPages.currrentPage === i ? "ml-2 green-alt" : "ml-2"} onClick={() => handlePageChange(i, 1)} >{i + 1}</span>)}
                                    <span className={upcomingClassesPages.currrentPage === upcomingClassesPages.totalPages - 1 ? "ml-2 disabled" : "ml-2"} onClick={() => handlePageChange(upcomingClassesPages.currrentPage + 1, 1)} >{'>'}</span>
                                </div>
                                    : ""}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={8} lg={12} className="mb-3 mb-lg-20 mt-lg-5">
                    <h2 className="green"><i className="fas fa-tasks green-alt" /> previous classes</h2>
                    <Card className="card-shadow">
                        <Card.Body>
                            <div className="table-responsive">
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th onClick={() => handleSort('date', !order.previous, 'previous')} >date <i className="fas fa-sort-down" /></th>
                                            <th onClick={() => handleSort('className', !order.previous, 'previous')} >name <i className="fas fa-sort" /></th>
                                            <th className="d-none d-md-table-cell" onClick={() => handleSort('duration', !order.previous, 'previous')} >duration <i className="fas fa-sort" /></th>
                                            <th onClick={() => handleSort('studentsEnrolled', !order.previous, 'previous')}>registered <i className="fas fa-sort" /></th>
                                            <th onClick={() => handleSort('totalEarnings', !order.previous, 'previous')}>earnings <i className="fas fa-sort" /></th>
                                            <th>actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="green">
                                        {(teacherPreviousClasses && previousClassesPages ? teacherPreviousClasses.length ? teacherPreviousClasses.slice(previousClassesPages.first, previousClassesPages.last).map((c, i) => <tr key={i}>
                                            <td>{c.date && moment(c.date).format('MM/DD/YY hh:mm A')}</td>
                                            <td>{c.className}</td>
                                            <td className="d-none d-md-table-cell">{formatDuration(c.duration)}</td>
                                            <td>{c.studentsEnrolled ? c.studentsEnrolled: 0}</td>
                                            <td>{c.totalEarnings ?c.totalEarnings: 0}</td>
                                            <td>
                                                <div className="actions d-none d-md-block">
                                                    <Link to={`/teacher/classes/${c.id}`}><i className="fas fa-eye" /></Link>
                                                </div>
                                                <div className="actions-mobile d-md-none">
                                                    <div className="btn-group">
                                                        <Dropdown className="nav-item">
                                                            <Dropdown.Toggle id="dropdown-custom-1" className="btn btn-salmon btn-sm dropdown-toggle">
                                                                Actions
                                                        </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Link to={`/teacher/classes/${c.id}`} className="dropdown-item"><i className="fas fa-eye" /> View</Link>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        )
                                            : <tr><td colSpan={6}>No classes</td></tr>
                                            : <tr><td colSpan={6} className="text-center">
                                                <Spinner animation="border" variant="primary" /></td></tr>)
                                        }
                                    </tbody>
                                </Table>
                                {previousClassesPages && previousClassesPages.totalPages > 1 ? <div className="custom-pagination d-flex justify-content-right green">
                                    <span className={previousClassesPages.currrentPage <= 0 ? "ml-2 disabled" : "ml-2"} onClick={() => handlePageChange(previousClassesPages.currrentPage - 1, 2)} disabled={previousClassesPages.currrentPage <= 0} >{'<'} </span>
                                    {previousClassesPages.pages.map((i) => <span key={i} className={previousClassesPages.currrentPage === i ? "ml-2 green-alt" : "ml-2"} onClick={() => handlePageChange(i, 2)} >{i + 1}</span>)}
                                    <span className="ml-2" onClick={() => handlePageChange(previousClassesPages.currrentPage + 1, 2)} disabled={previousClassesPages.currrentPage === previousClassesPages.totalPages - 1}>{'>'}</span>
                                </div>
                                    : ""}
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
                {toggle && <DisplayModal {...{
                    toggle,
                    handleToggle,
                    message: "Are you sure you can to delete this class?",
                    firstText: "Confirm",
                    secondText: "Cancel",
                    headerText: "Delete Class",
                    firstMethod: handleDeleteClass
                }} />}
            </Row>
        </Container> 
        : <Modal show={true} className="loading-modal text-center">
            <Spinner animation="border" variant="primary" />
        </Modal>);
}
export default ClassList