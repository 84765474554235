import React, { Component } from 'react';

class AccountVerification extends Component{
    render() {
        return(
            <header className="App-header">
                AccountVerification
            </header>
        );
    }
}

export default AccountVerification;