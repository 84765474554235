import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { FirebaseContext } from "../firebase";
import StudentClassContext from '../Containers/Views/Student/Context';


const StudentRoute = props => {
    let { claims, classDetail } = useContext(FirebaseContext); 
    const { checkBookedClass } = useContext(StudentClassContext);
    claims = claims ? claims : JSON.parse(localStorage.getItem('claims'))
    classDetail = classDetail ? classDetail : JSON.parse(localStorage.getItem('classDetails'))

    const bookedClass = classDetail && checkBookedClass(classDetail.classId)
    return   (claims ? ((!claims.email_verified || !claims.profileComplete)? <Redirect to={`/student/profile`}/> :(!classDetail ?
            <Route {...props} /> : (classDetail.amount && classDetail.amount > 0 && !bookedClass ?
                <Redirect to='/student/payment' /> :
                (!classDetail.user ?
                    <Redirect to={`/class/details/${classDetail.classId}`} />
                    : <Route {...props} />))))
            : <Redirect to='/login' />)
       
}

export default StudentRoute;