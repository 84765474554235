import React, { useState } from "react";
import {useForm, Controller} from "react-hook-form";
import { Link } from 'react-router-dom';
import { Button, Form, Container, Row, Col, Spinner, Modal, Card} from 'react-bootstrap';
import * as yup from "yup";
import MaskedInput from 'react-text-mask';
import mask from '../../../utils/mask';
import { formatTeacherRegisterValues } from '../../../utils/helper';
import firebase from "../../../firebase";
import {ReactComponent as FlexieLogo_green_alt} from '../../../img/Flexie-logo_FINAL-green-alt.svg';
import {ReactComponent as FlexieIcon} from '../../../img/FlexieIcon.svg';
import {ReactComponent as FlexieIconReverse} from '../../../img/FlexieIcon-reverse.svg';

export default function TeacherRegistration(props) {
    const schema = yup.object().shape({
        // plan: yup.string().required("Plan is required"),   
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup
		    .string()
		    .email("Please enter a valid email")
            .required("Email is required"),
        password: yup
            .string().min(8)
            .required("Passowrd is required"),
        confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'confirm passwords must match with').required("Password confirmation is required"),
        addressLine1: yup.string().required("Address is required"),
        city: yup.string().required("City is required"),
        inputYear: yup.string().required('expiration year is required'),
        state: yup.string().required('please select state'),
        inputMonth: yup.string().required('expiration month is required'),
        zip: yup.number().required("Zip Code is required"),
        cvvNumber: yup.number().required('Enter cvv Number'),
        ccNumber: yup.number().required('Enter credit card Number')
      });
      
    const { register, handleSubmit, watch, errors, control, reset, setValue } = useForm({
        validationSchema: schema
    });
    const [firebaseError, setFirebaseError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toggle, handleToggle] = useState(false);
    const [plan, setPlan] = useState(null)

    async function onSubmit(data) {
        try {
            setLoading(!loading);
            const result = formatTeacherRegisterValues(data);
            await firebase.teacherRegister(result).then((data) => {
                    setLoading(false);
                   if(data) {
                       reset();
                       setFirebaseError(null);
                       handleToggle(!toggle)
                     }
                })   
        } catch (err) {
            let message = !err.response ? err.message :
            ((err.response.data && err.response.data.error)
                ? err.response.data.error
                : "Error while registering user try again")
             setLoading(false);
             setFirebaseError(message);
             handleToggle(!toggle)
        }
    }

   const handleDialogClose = () => {
        handleToggle(!toggle)
        props.history.push('/login');
    }

    const handlePlan = (e) => {
        let { value } = e.target
        setValue('plan' , value)
        setPlan(value);
    }

    return (
        <div className="body-header-space">
       <Container className="mb-5">
            <Row className="pt-4 mb-4">
                <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                    <h1 className="salmon large float-left">teacher registration</h1>
                    <Link class="float-right salmon text-underline mt-4" to="/student-registration">I'm a student</Link>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={12} sm={10} lg={8}>
                    <h3 className="green">You’re less than 5 minutes from getting started!</h3>
                    <p className="green">Fill out the required information below. You will have the option to add
                        more information to your profile later, but this is all we need for now.</p>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="card green-bg mt-3">
                            <div className="card-body pb-1">
                                <h4 className="yellow">Step 1: Pick your plan</h4>
                                {(errors['plan'] && errors['plan'].type === "required") && <span className="alert">Please select your plan.</span>}
                                <Row className="row-eq-height">
                                    <Col xs={12} sm={6}>
                                        <Card className={`${plan === 'byo' ? 'yellow-bg' : 'green-bg green-alt-border'} h-100`}>
                                            <Row className="card-body d-flex flex-row  row-eq-height justify-content-center align-items-center">
                                                <Col xs={6}>
                                                    <h1 className={`${plan === 'byo' ? 'green' : 'green-alt'} text-right w-100`}>BYO</h1>
                                                </Col>
                                                <Col xs={6}>
                                                    <FlexieLogo_green_alt className="img-fluid" />
                                                </Col>
                                                {(plan === 'full setup' || !plan) &&
                                                <button type="button" value="byo" className="btn btn-info btn-float green select-btn" ref={register}  name="plan" onClick={handlePlan}>
                                                    <FlexieIcon className="btn-icon" />Select</button>
                                                     } 
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Card className={`${plan === 'full setup' ? 'yellow-bg' : 'green-bg green-alt-border'}  mt-5 mt-sm-0`}>
                                            <Row className="card-body d-flex flex-row row-eq-height justify-content-center align-items-center">
                                                <Col xs={6}>
                                                    <h1 className={plan !== 'full setup' ? "green-alt text-center w-100" : "green text-center w-100"}>Full Setup</h1>
                                                </Col>
                                                <Col xs={6}>
                                                    <FlexieLogo_green_alt className="img-fluid" />
                                                </Col>
                                                {(plan === 'byo' || !plan) &&
                                                <button type="button" value="full setup" className="btn btn-info btn-float green select-btn"  ref={register}  name="plan" onClick={handlePlan}>
                                                    <FlexieIcon className="btn-icon" />Select</button>
                                                 }
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                <Form.Group className="row mt-5">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label mt-3 mt-sm-0">Referral Code</Form.Label>
                                    <Col sm={8} lg={9}>
                                        <Form.Control 
                                            name="referralCode"
                                            type="text"
                                            placeholder="Plan"
                                            autoComplete="off"
                                            ref={register}>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="card salmon-bg mt-3">
                            <div className="card-body">
                                <h4 className="green">Step 2: Fill out the required stuff</h4>
                                <Form.Group className="form-group row">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label">First Name</Form.Label>
                                    <Col sm={8} lg={9}>
                                        <Form.Control
                                            required
                                            name="firstName"
                                            type="text"
                                            placeholder="First Name"
                                            autoComplete="off"
                                            ref={register}>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label">Last Name</Form.Label>
                                    <Col sm={8} lg={9}>
                                        <Form.Control
                                            required
                                            name="lastName"
                                            type="text"
                                            placeholder="Last Name"
                                            autoComplete="off"
                                            ref={register}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label">Password</Form.Label>
                                    <Col sm={8} lg={9}>
                                        <Form.Control
                                            required
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            autoComplete="off"
                                            ref={register}
                                        />
                                        {(errors['password'] && errors['password'].type === "min") && <span className="alert">{errors['password'].message}</span>}
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label">Confirm Password</Form.Label>
                                    <Col sm={8} lg={9}>
                                        <Form.Control
                                            required
                                            name="confirmPassword"
                                            type="password"
                                            placeholder="Confirm Password"
                                            autoComplete="off"
                                            ref={register({
                                                validate: (value) => value === watch('password') || "Passwords don't match."
                                            })}
                                        />
                                         {errors['confirmPassword']  && <span className="alert">{errors['confirmPassword'].message}</span>}
                                    </Col>
                                        
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label">Email</Form.Label>
                                    <Col sm={8} lg={9}>
                                        <Form.Control
                                            required
                                            name="email"
                                            type="text"
                                            placeholder="Email"
                                            autoComplete="off"
                                            ref={register}
                                        />
                                        {(errors['email'] && errors['email'].type === "email") && <span className="alert">{errors['email'].message}</span>}
                                    </Col>
                                    </Form.Group>
                                <Form.Group className="row mt-5">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label">Address Line 1</Form.Label>
                                    <Col sm={8} lg={9}>
                                        <Form.Control
                                            required
                                            name="addressLine1"
                                            type="text"
                                            placeholder="Address Line 1"
                                            autoComplete="off"
                                            ref={register}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label">Address Line 2</Form.Label>
                                    <Col sm={8} lg={9}>
                                        <Form.Control
                                                name="addressLine2"
                                                type="text"
                                                placeholder="Address Line 2"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label">City</Form.Label>
                                    <Col sm={8} lg={9}>
                                        <Form.Control
                                            required
                                            name="city"
                                            type="text"
                                            placeholder="City"
                                            autoComplete="off"
                                            ref={register}
                                        />
                                    </Col>
                                </Form.Group>
                                <Row className="w-100 no-gutters">
                                    <Col xs={12} lg={6}>
                                        <Form.Group className="row">
                                            <Form.Label className="col-sm-4 col-lg-6 col-form-label">State</Form.Label>
                                            <Col sm={8} lg={6}>
                                                <Controller
                                                as={
                                                <Form.Control
                                                    required
                                                    as="select"
                                                    name="state"
                                                >
                                                        <option value="0">Choose...</option>
                                                        <option value="AL">Alabama</option>
                                                        <option value="AK">Alaska</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="DC">District Of Columbia</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NY">New York</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>
                                                </Form.Control>
                                                }
                                                control={control}
                                                name="state"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group className="row">
                                            <Col className="text-right">{(errors['state'] && errors['state'].type === "required") && <span className="alert">{errors['state'].message}</span>}</Col>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className="row">
                                            <Form.Label className="col-sm-4 col-lg-4 col-form-label">Zip Code</Form.Label>
                                            <Col sm={8} lg={8}>
                                                <Form.Control
                                                    required
                                                    name="zip"
                                                    type="text"
                                                    placeholder="Zip Code"
                                                    autoComplete="off"
                                                    ref={register}
                                                />
                                            </Col>
                                        </Form.Group>
                                         <Form.Group className="row">
                                         <Col className="text-center">{(errors['zip'] && errors['zip'].type === "typeError") && <span className="alert">Enter valid zip code</span>}</Col>
                                         </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="row mt-5">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label">Credit Card #</Form.Label>
                                    <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                      <Controller
                                      as={
                                        <MaskedInput
                                        name="ccNumber"
                                        className="form-control"
                                        mask={mask}
                                      />}
                                      control={control}
                                      name="ccNumber"/>
                                      {(errors['ccNumber'] && errors['ccNumber'].type === "required") && <span className="alert">Enter a credit card number</span>}
                                      {(errors['ccNumber'] && errors['ccNumber'].type === "typeError") && <span className="alert">Please enter valid credit card number</span>}
                                    </Col>
                                </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label">Exp. Date</Form.Label>
                                    <Col sm={4} lg={3}>
                                        <Controller
                                        as={
                                        <Form.Control
                                            required
                                            name="inputMonth"
                                            as="select"
                                        >
                                            <option>Month</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                            <option>07</option>
                                            <option>08</option>
                                            <option>09</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                        </Form.Control>
                                        }
                                        name="inputMonth"
                                        control={control}
                                        />
                                    </Col>
                                    <Col sm={4} lg={3} className="mt-3 mt-sm-0">
                                        <Controller
                                        as={
                                        <Form.Control
                                            required
                                            name="inputYear"
                                            as="select"
                                        >
                                            <option selected>Year</option>
                                            <option>2020</option>
                                            <option>2021</option>
                                            <option>2022</option>
                                            <option>2023</option>
                                            <option>2024</option>
                                            <option>2025</option>
                                            <option>2026</option>
                                            <option>2027</option>
                                            <option>2028</option>
                                            <option>2029</option>
                                        </Form.Control>
                                        }
                                        name="inputYear"
                                        control={control}
                                        />
                                    </Col>
                                </Form.Group>
                               <Form.Group className="row">
                               <Col sm={4} lg={3}/>
                               <Col className="mt-3 mt-sm-0">{((errors['inputMonth'] || errors['inputYear'])
                                && ((errors['inputYear'] &&  errors['inputYear'].type === "required") || (errors['inputMonth'] && errors['inputMonth'].type === "required" ))) && <span className="alert">please select date of credit card expiration</span>}</Col>
                               </Form.Group>
                                <Form.Group className="row">
                                    <Form.Label className="col-sm-4 col-lg-3 col-form-label">CVV <i className="fas fa-question-circle" data-toggle="tooltip" data-placement="top"
                                        title="The CVV number is the 3 or 4 digit number printed on the signature strip"></i>
                                    </Form.Label>
                                    <Col sm={8} lg={9}>
                                        <Form.Control
                                            required
                                            name="cvvNumber"
                                            type="text"
                                            ref={register}
                                        />
                                        {(errors['cvvNumber'] && errors['cvvNumber'].type === "typeError") && <span className="alert">Enter valid cvv number</span>}
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="card green-alt-bg mt-4">
                            <div className="card-body">
                                <h4 className="green">Step 3: Accept the Terms of Service</h4>
                                <Form.Group>
                                    <Form.Check id="invalidCheck">
                                        <input
                                            name="termsAndService"
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            red={register}
                                            id="invalidCheck"
                                            required
                                        />
                                        <Form.Label className="form-check-label reverse">I have read and agree to the <a href="#" class="green">Terms of Service</a>.</Form.Label>
                                        <div className="invalid-feedback reverse">You must agree before submitting.</div>
                                    </Form.Check>
                                </Form.Group>
                                <Button variant="salmon" type="submit" className="btn-float">
                                    <FlexieIconReverse className="btn-icon" />Submit
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
        
        <Container className="mb-5 text-center">
            {loading ?<Modal show={true} className="loading-modal text-center">
                 <Spinner animation="border" variant="primary" />
            </Modal>: ""}
        </Container>
        <Modal show={toggle} onHide={() => handleToggle(!toggle)}>
                <Modal.Body>{firebaseError ? ( firebaseError == 'Error: User with this email already exists'?
                 <span>{`It appears as though this user already exists. Either create an account with a different email address or `}<Link to="/login">login</Link></span>
                : firebaseError )
                : 'Congratulations! You are just a couple of clicks away from starting your practice online. Check your email. We sent you an email to verify your email address.'}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={firebaseError? () => handleToggle(!toggle) : handleDialogClose }>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
       </div>
    );
}