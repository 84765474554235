import React, { useState, useContext, useEffect } from "react";
import { Spinner, Modal, Container, Row, Col, Button, Form, Image } from 'react-bootstrap';
import useFormValidation from '../useFormValidation';
import validateLogin from "../validateLogin";
import FlexieIcon from '../../../img/FlexieIcon-reverse.svg';
import firebase, { FirebaseContext } from "../../../firebase";
import ClassContext from '../../../Containers/Views/Main/Context/index';
import { formatError } from '../../../utils/helper';
import { Link } from "react-router-dom";

const INITIAL_STATE = {
  email: "",
  password: ""
};

function Login(props) {
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    errors,
    isSubmitting
  } = useFormValidation(INITIAL_STATE, validateLogin, authenticateUser);
  const [firebaseError, setFirebaseError] = useState(null);
  const { clearStorage } = useContext(ClassContext);
  const [loading, setLoadingState] = useState(false);
  const { classDetail } = useContext(FirebaseContext);

  async function authenticateUser() {
    const { email, password } = values;
    setLoadingState(!loading);
    try {
      const user = await firebase.login({ email, password });
      setLoadingState(!loading);
      clearStorage();
     verifyUserRole(user);
    } catch (error) {
      let errMessage =  formatError(error)?  formatError(error): "Error while logging try again";
      setLoadingState(false);
      //setFirebaseError("Incorrect username or password");
      setFirebaseError(errMessage);
    }
  } 


  const verifyUserRole = (user) => {
    if(!user.email_verified) {
       props.history.push('/teacher/profile');
    }
    else {
      if (user && user.teacher)
      props.history.push("/teacher/dashboard");
    else {
      if(classDetail) props.history.push('/student/payment');
      else props.history.push("/student/dashboard");
    }
    }
  }

  return (
    <div className="login-page">
      <div className="login-header-bg"/>
      {loading ? <Modal show={true} className="loading-modal text-center">
        <Spinner animation="border" variant="primary" />
      </Modal> : ""}
      <Container>
        <Form onSubmit={handleSubmit}>
          <Row className="mt-4 mb-4">
            <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
              <h1 className="green"> log in</h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} lg={8} >
              <div className="rounded-40 green-alt-bg pr-5 pl-5 text-center card-body">
                <Form.Group className="row pt-lg-3">
                  <Form.Label className=" col-sm-4 col-lg-3 col-form-label green">Email Address</Form.Label>
                  <Col sm={8} lg={9}>
                    <Form.Control
                      type="email"
                      name="email"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      autoComplete="off"
                    />
                    {errors.email && <p className="error-text">{errors.email}</p>}
                  </Col>
                </Form.Group>
                <Form.Group className="row pt-lg-3">
                  <Form.Label className="col-sm-4 col-lg-3 col-form-label green">Password</Form.Label>
                  <Col sm={8} lg={9}>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      value={values.password}
                      className={errors.password && "error-input"}
                      name="password"
                      type="password"
                      />
                    {errors.password && <p className="error-text">{errors.password}</p>}
                    {firebaseError && <p className="error-text mt-2">{firebaseError}</p>}
                  </Col>
                </Form.Group>
                <div className="text-center mb-3"><Link to="/password-recovery">Forgot Username/Password?</Link></div>
                <Button
                  className="salmon-bg btn-float"
                  type="submit"
                  variant="info"
                  disabled={isSubmitting}
                >
                   <Image className="btn-icon" src={FlexieIcon} />
                  Submit
                  </Button>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5 mb-5">
            <Link to="/register" className="btn green-bg btn-float">
              <Image className="btn-icon" src={FlexieIcon} />
                  Create an account
             </Link>
          </Row>
        </Form>
      </Container>
    </div>
  );
}

export default Login;
