import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

import {
  teacherRegistrationService,
  loginService,
  passwordResetService,
  studentRegisterService,
  sendContactMailService,
  sendSupportMailService,
  studentPaymentService,
  sendClassCancellationMailService,
  getCustomerProfileService,
  updateCustomerProfileService,
  createCustomerProfileService,
  sendStudentClassCancellationMailService,
  upgradeToTeacherRoleService,
  updateProfile
} from '../services';
import firebaseConfig from "./config";

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.storage = app.storage();
    this.timestamp = app.firestore.Timestamp;
  }

  async updateTeacherProfile(firstName, lastName, email, password, plan, referralCode, addressLine1, addressLine2, city, zip) {

  }

  async studentRegister(props) {
    const { firstName, lastName, email, password, ccNumber, inputMonth, inputYear, cvvNumber, zip, amount, classId } = props;
    try {
      return await studentRegisterService({
        firstName,
        lastName,
        email,
        password,
        ccNumber,
        inputMonth,
        inputYear,
        cvvNumber,
        zip,
        amount,
        classId
      })
    } catch (err) {
      throw (err);
    }
  }

  async teacherRegister(props) {
    const { firstName, lastName, email, password, plan,
      referralCode, addressLine1, addressLine2, city,
      zip, state, ccNumber, cvvNumber, inputYear, inputMonth } = props
    try {
          return await teacherRegistrationService(
            {
              firstName,
              lastName,
              email,
              password,
              plan,
              referralCode,
              addressLine1,
              addressLine2,
              city,
              state,
              inputYear,
              inputMonth,
              zip,
              ccNumber,
              cvvNumber
            })
        } catch (error) {
          throw (error)
        }
  }

  async login(props) {
    try {
      const { data } = await loginService({ email: props.email, password: props.password });
      if(data.message){
        return {}
    } else {
      await this.auth.signInWithCustomToken(data.token).catch((error) => {
        throw (error)
      });
      return await this.auth.currentUser.getIdTokenResult().then((idTokenResult) => {
        return idTokenResult.claims
      })
    }
    } catch (error) {
      throw (error);
    }
  }

  async studentPayment(data) {
    try {
      return await studentPaymentService(data);
    }
    catch (error) {
      throw (error);
    }
  }

  async logout() {
    localStorage.clear();
    await this.auth.signOut();
  }

  async resetPassword(email) {
    try {
      return await passwordResetService({ email })
    } catch (error) {
      throw (error)
    }
  }

  async sendContactMail(data) {
    try {
      return await sendContactMailService(data)
    } catch (error) {
      throw (error)
    }
  }

  async sendSupportMail(data) {
    try {
      return await sendSupportMailService(data)
    } catch (error) {
      throw (error)
    }
  }

  async cancelClass(data) {
    try {
      return await sendClassCancellationMailService(data)
    } catch (error) {
      throw (error)
    }
  }

  async cancelStudentClass(data) {
    try {
      return await sendStudentClassCancellationMailService(data)
    } catch (error) {
      throw (error)
    }
  }

  async getProfile(customerProfileId, customerPaymentProfileId) {
    try {
      return await getCustomerProfileService({ customerProfileId, customerPaymentProfileId })
    } catch (error) {
      throw (error)
    }
  }

  async updateProfile(data) {
    try {
      return await updateCustomerProfileService(data)
    } catch (error) {
      throw (error)
    }
  }

  async createProfile(data) {
    try {
      return await createCustomerProfileService(data)
    } catch (error) {
      throw (error)
    }
  }

  async upgradeToTeacherRole(data) {
    try {
      return await upgradeToTeacherRoleService(data)
    } catch (error) {
      throw (error)
    }
  }

  async updateUserProfile(data){
    try {
    const res = await updateProfile({id: data})
    if(res){
      return await this.auth.currentUser.getIdTokenResult(true).then((idTokenResult) => {
        return idTokenResult.claims
      })
    }
    } catch (error) {
      throw (error)
    }
  }

}



const firebase = new Firebase();
export default firebase;
