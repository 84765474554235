
import React, { useCallback, useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import _ from 'lodash';
import { Button, Form, Container, Row, Col, Image, Card, FormGroup, FormControl, Modal, Spinner } from 'react-bootstrap';
import * as yup from "yup";
import Dropzone from 'react-dropzone';
import DateTimePicker from 'react-date-picker';
import TimezonePicker from 'react-bootstrap-timezone-picker';

import Student from '../../../../firebase/model/student';
import firebase, { FirebaseContext } from "../../../../firebase";
import StudentClassContext from '../Context';
import MaskedInput from 'react-text-mask';
import mask from '../../../../utils/mask';
import FlexieIconReverse from '../../../../img/FlexieIcon-reverse.svg';
import '../index.scss';
StudentAccountProfile.student = {}

export default function StudentAccountProfile(props) {
    const { claims } = useContext(FirebaseContext);

    const schema = yup.object().shape({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        email: yup
            .string()
            .email("Please enter a valid email")
            .required("Email is required"),
        dob: yup.date().required('please select date of birth'),
        timeZone: yup.string().required('Please select timezone'),
        ccNumber: yup.number().notRequired(),
        inputYear: yup.string().notRequired(),
        inputMonth: yup.string().notRequired()
    });


    const { register, handleSubmit, errors, control, reset, getValues, setValue} = useForm({
        validationSchema: schema,
        defaultValues: {}
    });
    const { studentProfileDetails } = useContext(StudentClassContext);
    const [firebaseError, setFirebaseError] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toggle, handleToggle] = useState(false);
    const [profileImage, setProfileImages] = useState(null);
    const [ validateProfileImage, setValidationError] = useState(null);

    const populateProfile = async (id) => {
        setLoading(true);
        StudentAccountProfile.student = studentProfileDetails
        if (StudentAccountProfile.student.customerProfileId)
            await firebase.getProfile(
                StudentAccountProfile.student.customerProfileId,
                StudentAccountProfile.student.customerPaymentProfileId
            ).then((details) => {
            }).catch((err) => {
            });
        reset({
            ...StudentAccountProfile.student.data,
            dob: StudentAccountProfile.student.dob &&
                new Date(StudentAccountProfile.student.dob.seconds ? StudentAccountProfile.student.dob.toDate() : StudentAccountProfile.student.dob)
        });
        setLoading(false);
    }

    useEffect(() => {
        claims &&  populateProfile(claims.user_id);
    }, [studentProfileDetails])

    const onDropProfileImage = useCallback((acceptedFiles) => {
        console.log('accepted files', acceptedFiles);
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            if (file.type.match(/image.*/)) {
                reader.onload = function (e) {
                    StudentAccountProfile.student.profileImage = reader.result
                    setProfileImages({ file, imageUrl: reader.result })
                };
                reader.readAsDataURL(file);
            }
            else {
                setMessage('File not supported, please select image file for profile Image')
                handleToggle(!toggle)
            }
        }
    }, []);

    const onSubmit = async (data) => {
        if (!profileImage && !StudentAccountProfile.student.profileImage ) {
            setValidationError('Please Upload a profile Image.')
            return
        }
        else {
            setLoading(true);
            try {
                if (profileImage)
                    StudentAccountProfile.student.profileImage = await Student.uploadImage(profileImage, StudentAccountProfile.student.id);
                let d = _.reduce(data, (result, value, key) => {
                    if (!['ccNumber', 'inputYear', 'inputMonth', 'cvvNumber'].includes(key)) result[key] = value;
                    return result
                }, {})
                StudentAccountProfile.student.data = { ...StudentAccountProfile.student.data, ...d };
                await StudentAccountProfile.student.save();
                if (data.ccNumber) {
                    if (StudentAccountProfile.student.customerProfileId && StudentAccountProfile.student.customerPaymentProfileId)
                        await firebase.updateProfile({
                            customerProfileId: StudentAccountProfile.student.customerProfileId,
                            customerPaymentProfileId: StudentAccountProfile.student.customerPaymentProfileId,
                            ...data
                        })
                    else
                        await firebase.createProfile({
                            id: StudentAccountProfile.student.id,
                            ...StudentAccountProfile.student.data,
                            ...data
                        })
                }
                await firebase.updateUserProfile(StudentAccountProfile.student.data.userId)
                populateProfile(claims.user_id);
            } catch (err) {
                let error = err.message ? err.message : 'Error while updating profile details';
                setFirebaseError(error)
                setLoading(false);
                handleToggle(!toggle)
            }
        }
        window.scrollTo(0, 0);
    }
    return (<div className="student-profile-page body-header-space">
        <div className="header-salmon-bg" />
        {!loading ?
            <Container>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="pt-4 mb-4">
                        <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                            <h1 className="green large float-left">
                                <i className="fas fa-user-circle mr-3" />
                            student profile
                            </h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={10} lg={8}>
                            <Card className="salmon-bg mt-3 rounded-40">
                                <Card.Body>
                                    <h3 className="green p-2">Contact Info</h3>
                                    <Form.Group className="form-group row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">First Name*</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                required
                                                name="firstName"
                                                type="text"
                                                placeholder="First Name"
                                                autoComplete="off"
                                                ref={register}>
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Last Name*</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                required
                                                name="lastName"
                                                type="text"
                                                placeholder="Last Name"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Email Address*</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                required
                                                name="email"
                                                type="text"
                                                placeholder="Email"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                            {(errors['email'] && errors['email'].type === "email") && <span className="alert">{errors['email'].message}</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Cell Phone #</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <FormControl
                                                type="text"
                                                name="cellPhone"
                                                ref={register}
                                            />
                                            {(errors['cellPhone'] && errors['cellPhone']) && <span className="alert">{errors['cellPhone'].message}</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-4 col-form-label mr-3">Preferred Method of Contact: </Form.Label>
                                        <Col sm={8} lg={6} className="mt-2 ml-sm-4">
                                            <Form.Check
                                                inline
                                                type="radio"
                                                label="Email"
                                                value='email'
                                                name="preferredContactMethod"
                                                className="mr-5"
                                                ref={register}
                                                control={control}
                                                setValue={setValue}
                                                getValues={getValues} />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                label="CellPhone"
                                                value='cellPhone'
                                                control={control}
                                                ref={register}
                                                setValue={setValue}
                                                getValues={getValues}
                                                name="preferredContactMethod" />
                                        </Col></Form.Group>
                                </Card.Body>
                            </Card>
                            <Card className="green-alt-bg mt-3 rounded-40">
                                <Card.Body>
                                    <h3 className="green p-2">Student Info</h3>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">DOB*</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <div className="date-picker">
                                                <Controller
                                                    as={
                                                        <DateTimePicker
                                                            format="MM/dd/yyyy"
                                                            dayPlaceholder="DD"
                                                            monthPlaceholder="MM"
                                                            yearPlaceholder="YYYY"
                                                            calendarIcon={<i className="fas fa-calendar-alt green" />}
                                                        />}
                                                    name="dob"
                                                    control={control}
                                                    // defaultValue={new Date()}
                                                />
                                            </div>
                                            {errors.dob && <span className="alert">{errors.dob.message}</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">
                                            <i className="fas fa-question-circle mr-2" data-toggle="tooltip" data-placement="top"
                                                title="The CVV number is the 3 or 4 digit number printed on the signature strip"></i>
                                            Profile Pic*
                                        </Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <div className="grab-img-block d-block mb-2">
                                                <div className="d-flex align-items-center">
                                                    <Dropzone
                                                        onDrop={(e) => onDropProfileImage(e, 'profileImage')}
                                                    >
                                                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                                            <>
                                                                <div {...getRootProps()} className="drag-img-circle mb-3">
                                                                    <input {...getInputProps()} />
                                                                    {
                                                                        isDragActive ?
                                                                            <p>Drop the files here ...</p> :
                                                                            (!StudentAccountProfile.student.profileImage ? <i className="fas fa-image icon-img"></i>
                                                                                :
                                                                                <Image src={StudentAccountProfile.student.profileImage} name="profileImage" />
                                                                            )
                                                                    }
                                                                </div>
                                                                <div className="text-center btn-col">
                                                                    <Button className="green-bg btn-secondary btn-upload" {...getRootProps()}>
                                                                        <i className="fas fa-upload mr-1"></i> Upload
                                                    </Button>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Dropzone>

                                                </div>
                                            </div>
                                            {validateProfileImage && <span className="alert">{validateProfileImage}</span> }
                                        </Col>
                                    </Form.Group>
                                    <FormGroup className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Time Zone</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <div className="time-picker">
                                                <Controller
                                                    as={
                                                        <TimezonePicker
                                                            absolute={false}
                                                            placeholder="Select timezone..."
                                                            name="timeZone"
                                                            ref={register}
                                                        />}
                                                    name="timeZone"
                                                    control={control}
                                                />
                                            </div>
                                            {errors.timeZone && <span className="alert">{errors.timeZone.message}</span>}
                                        </Col>
                                    </FormGroup>

                                </Card.Body>
                            </Card>

                            <Card className="green-bg mt-3">
                                <Card.Body>
                                    <h4 className="yellow">Payment Info</h4>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Credit Card #</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <Controller
                                                as={
                                                    <MaskedInput
                                                        className="form-control"
                                                        mask={mask}
                                                    />}
                                                name="ccNumber"
                                                control={control}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Exp. Date</Form.Label>
                                        <Col sm={4} lg={3}>
                                            <Controller
                                                as={
                                                    <Form.Control
                                                        required
                                                        as="select"
                                                    >
                                                        <option>Month</option>
                                                        <option>01</option>
                                                        <option>02</option>
                                                        <option>03</option>
                                                        <option>04</option>
                                                        <option>05</option>
                                                        <option>06</option>
                                                        <option>07</option>
                                                        <option>08</option>
                                                        <option>09</option>
                                                        <option>10</option>
                                                        <option>11</option>
                                                        <option>12</option>
                                                    </Form.Control>
                                                }
                                                name="inputMonth"
                                                ref={register}
                                                control={control}
                                            />
                                        </Col>
                                        <Col sm={4} lg={3} className="mt-3 mt-sm-0">
                                            <Controller
                                                as={
                                                    <Form.Control
                                                        required
                                                        as="select"
                                                    >
                                                        <option selected>Year</option>
                                                        <option>2020</option>
                                                        <option>2021</option>
                                                        <option>2022</option>
                                                        <option>2023</option>
                                                        <option>2024</option>
                                                        <option>2025</option>
                                                        <option>2026</option>
                                                        <option>2027</option>
                                                        <option>2028</option>
                                                        <option>2029</option>
                                                    </Form.Control>
                                                }
                                                name="inputYear"
                                                ref={register}
                                                control={control}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">
                                            <i className="fas fa-question-circle mr-2" data-toggle="tooltip" data-placement="top"
                                                title="The CVV number is the 3 or 4 digit number printed on the signature strip"></i>
                                        CVV
                                    </Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                name="cvvNumber"
                                                type="text"
                                                ref={register}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-5 mb-5">
                        <Button type="submit" className="btn-secondary btn-float">
                            <Image src={FlexieIconReverse} className="btn-icon" />Submit
                    </Button>
                    </Row>
                </Form>
                <Modal show={toggle} onHide={() => handleToggle(!toggle)}>
                    <Modal.Body>{firebaseError ? firebaseError : message}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleToggle(!toggle)}>
                            Cancel
                    </Button>
                    </Modal.Footer>
                </Modal>

                {loading && <Container className="mb-5 text-center spinner-block">
                    <Modal show={true} className="loading-modal text-center">
                        <Spinner animation="border" variant="primary" />
                    </Modal>
                </Container>}
            </Container>
            : <Container className="mb-5 text-center spinner-block">
                {loading ? <Modal show={true} className="loading-modal text-center">
                    <Spinner animation="border" variant="primary" />
                </Modal> : ""}
            </Container>
        }
    </div>
    );

}