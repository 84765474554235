import React, { useState, useCallback, useEffect, useContext } from 'react';
import moment from 'moment';
import _ from 'lodash'
import DateTimePicker from 'react-date-picker';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import { Container, Row, Col, Card, InputGroup, Form, FormGroup, FormControl, Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useLocation } from 'react-router-dom'
import { useDropzone } from 'react-dropzone';
import { FirebaseContext } from "../../../../firebase";
import Class from '../../../../firebase/model/Classes'
import TeacherClassContext from '../Context'
import { generateClassStreamingURl, getFormattedDate } from '../../../../utils/helper'
import '../index.scss';

ClassBuilder.classDetails = {};


const schema = yup.object().shape({
  className: yup.string().required("class name is required"),
  classDescription: yup.string().required("class description is required"),
  difficultyLevel: yup.string().required('select difficulty level.'),
  yogaType: yup.string().required('select type of yoga.'),
  duration: yup.string().required('select duration for class.'),
  feeCancelationPolicy: yup.string().optional(),
  recurring: yup.string().optional(),
  classStreamingType: yup.string().required('please select a class streaming type.'),
  streamingUrl: yup.string().url('enter a valid url').required('please enter class streaming url'),
  date: yup.date().min(moment().subtract('day', 1), 'date cannot be in past').required('please select date'),
  time: yup
  .string()
  .required("please select date")
  .test("is-greater", "time should be greater then now", function(value) {
    let { date } = this.parent;
    if(moment(date).isAfter(moment())) return true;
    return (moment(value).format('hh:mm') > moment().format('hh:mm'));
  }),
  price: yup.string().required('please select price'),
  promoCode: yup.string().optional()
});


export default function ClassBuilder(props) {


  const { pathname } = useLocation();
  const id = pathname.split('/')[3];
  const PropsNeeded = ['Blocks', 'Straps', 'Wheel', 'Blanket', 'Pillow'];
  const recurringType = ['Never', 'Daily', 'Weekly', 'Monthly'];
  const ReccurDays = ['Sunday' , 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const { fectchClassses, teacherProfileDetails } = useContext(TeacherClassContext)
  const [value, onChange] = useState(null);
  const [recurringScheme, setRecurringType] = useState('Never');
  const [loading, setLoading] = useState(false);
  const [toggle, handleToggle] = useState(false);
  const [classData, handleClass] = useState({});
  const [imageUrl, setImageUrl] = useState();
  const { claims } = useContext(FirebaseContext);

  const { register, handleSubmit, errors, control, getValues, setValue, reset } = useForm({
    validationSchema: schema,
    defaultValues: {
      recurring: 'Never',
      timeZone: teacherProfileDetails.timeZone
    }
  });

  const fetchClass = async () => {
    try {
      setLoading(true);
      const snapshot = await Class.getById(id)
      ClassBuilder.classDetails = snapshot
      handleClass(snapshot.data);
      reset({
        ...ClassBuilder.classDetails.data,
        date: ClassBuilder.classDetails.date ?
          new Date(ClassBuilder.classDetails.date.toDate())
          : new Date(),
        time: moment(ClassBuilder.classDetails.date)
      });
      setLoading(false);
    } catch (e) {
      console.log('error', e)
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) fetchClass();
    setValue('timeZone',teacherProfileDetails.timeZone )
  }, [teacherProfileDetails])

  const handleUrlChange = (e) => {
    handleClass({ ...classData, streamingUrl: e.target.value });
  }

  const handleClassType = (e) => {
    if (e.target.value == 'Flexie-web') {
      let streamingUrl = generateClassStreamingURl()
      setValue('streamingUrl', streamingUrl);
      handleClass({ ...classData, classStreamingType: e.target.value, streamingUrl });
    }
    else {
      setValue('streamingUrl', "");
      handleClass({ ...classData, classStreamingType: e.target.value, streamingUrl: "" });
    }
  }

  async function onSubmit(data) {
    try {
      setLoading(!loading);
      if (!id) {
        await Class.add({
          ...data,
          teacherId: claims.user_id,
          classImage: imageUrl? value: ""
        })
      }
      else {
        const classImage = imageUrl ? await Class.uploadImage(value, ClassBuilder.classDetails.id) : "";
        if (imageUrl) ClassBuilder.classDetails.classImage = classImage;
        if (ClassBuilder.classDetails.recurring !== 'Never' && !ClassBuilder.classDetails.recurringCycle ) ClassBuilder.classDetails.recurringCycle = 30;
        let d = _.reduce(data, (result, value, key) => {
          if (!['time'].includes(key)) result[key] = value;
          return result
        }, {})
        ClassBuilder.classDetails.data = { ...ClassBuilder.classDetails.data, ...d };
        let date = getFormattedDate(data.date, data.time)
        ClassBuilder.classDetails.date = date
        await ClassBuilder.classDetails.save();
      }
      fectchClassses();
      setLoading(false);
      props.history.push("/teacher/classes");
    } catch (e) {
      console.log('error', e)
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    console.log('accepted files', acceptedFiles);
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      onChange(file);
      const reader = new FileReader();
      if (file.type.match(/image.*/)) {
        reader.onload = function (e) {
          setImageUrl(reader.result)
        };
        reader.readAsDataURL(file);
      }
      else handleToggle(!toggle)
      // Class.uploadImage(file);
    }
  }, []);

  const handleRecurringSchedule = (e) => {
    const { value } = e.target
    setRecurringType(value);
  }


  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (

    <Container className="body-inner-space p-0">
      {loading ? <Modal show={true} className="loading-modal text-center">
        <Spinner animation="border" variant="primary" />
      </Modal> : ""}
      <section className="content white-bg new-class-section">
        <Container className="mb-5">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="pt-2 pt-md-4 mb-4">
              <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                <h1 className="salmon large"><i className="fas fa-th"></i> new class</h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} className="order-2 order-lg-1">
                <Card className="card-shadow">
                  <Card.Body>
                    <div className="card-title">
                      <h3 className="green">Class Info</h3>
                    </div>
                    <div className="class-info-block">
                      <Row className="class-info-row">
                        <Col xs={12} md={7}>
                          <Form.Group controlId="className">
                            <Form.Label className="green">Class Name<i className="red-error">*</i></Form.Label>
                            <Form.Control type="text" name="className" ref={register} />
                            {errors.className && <p className="alert">class name is required</p>}
                          </Form.Group>
                          <div className="grab-img-block d-block d-md-none mb-2">
                            <div className="class-sub-title mb-3">
                              <span className="green">Class Image</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div {...getRootProps()} className="drag-img mb-3">
                                <input {...getInputProps()} />
                                {
                                  isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    (!imageUrl ? !(classData && classData.classImage) ? <i className="fas fa-image icon-img"></i>
                                      : <Image src={classData.classImage ? classData.classImage : imageUrl} />

                                      : <Image src={imageUrl} />)
                                }
                              </div>
                              <div className="text-center btn-col">
                                <Button className="btn-secondary btn-upload" {...getRootProps()}>
                                  <i className="fas fa-upload mr-1"></i> Upload
                              </Button>
                              </div>
                            </div>
                          </div>
                          <Form.Group controlId="formClassDescription">
                            <Form.Label className="green">Class Description</Form.Label>
                            <Form.Control className="text-area" type="textarea" as="textarea" rows="3"
                              name="classDescription" ref={register}
                            />
                            {errors.classDescription && <p className="alert">class description is required</p>}
                          </Form.Group>
                          <div className="class-info-dropdown mt-4 mb-3 mb-md-0 d-block d-md-none">
                            <Form.Group controlId="exampleForm.SelectCustom">
                              <Form.Control as="select" custom name="difficultyLevel" ref={register}>
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Expert">Expert</option>
                              </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.SelectCustom">
                              <Form.Control as="select" custom name="yogaType" ref={register}>
                                <option value="Power">Power</option>
                                <option value="Restorative">Restorative</option>
                                <option value="Vinyasa">Vinyasa</option>
                                <option value="Yin">Yin</option>
                              </Form.Control>
                            </Form.Group>
                          </div>
                          <Form.Group controlId="formGroupEmail">
                            <Form.Label className="green">Class Size Limit <small>(max 100 if blank)</small></Form.Label>
                            <Form.Control type="number" name="classSize" ref={register} />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={4} className="ml-auto ml-md-0 mt-2 mt-md-0">
                          <div className="grab-img-block d-md-block d-none">
                            <div className="class-sub-title mb-3">
                              <span className="green">Class Image</span>
                            </div>
                            <div {...getRootProps()} className="drag-img mb-3">
                              <input {...getInputProps()} />
                              {
                                isDragActive ?
                                  <p>Drop the files here ...</p> :
                                  (!imageUrl ? !(classData && classData.classImage) ? <i className="fas fa-image icon-img"></i>
                                    : <Image src={classData.classImage ? classData.classImage : imageUrl} />

                                    : <Image src={imageUrl} />)
                              }
                            </div>
                            <div>
                              <div className="text-center">
                                <Button className="btn-secondary btn-upload" {...getRootProps()}>
                                  <i className="fas fa-upload mr-1"></i> Upload
                              </Button>
                              </div>
                            </div>
                          </div>

                          <div className="class-info-dropdown mt-md-4 mb-3 mb-md-0 d-md-block d-none">
                            <Form.Group controlId="exampleForm.SelectCustom">
                              <Form.Control as="select" custom name="difficultyLevel" ref={register}>
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Expert">Expert</option>
                              </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.SelectCustom">
                              <Form.Control as="select" custom name="yogaType" ref={register}>
                                <option value="Power">Power</option>
                                <option value="Restorative">Restorative</option>
                                <option value="Vinyasa">Vinyasa</option>
                                <option value="Yin">Yin</option>
                              </Form.Control>
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className="class-sub-title mb-2">
                            <span className="green">Props Needed</span>
                          </div>
                          <Form.Group controlId="className"
                            className="prop-checkbox d-flex justify-content-md-between flex-wrap flex-md-wrap"
                            name="propsNeeded" ref={register}>
                            {PropsNeeded.map((prop, i) =>
                              <Form.Check
                                key={i}
                                inline
                                label={prop}
                                type='checkbox'
                                id={prop}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                                name="propsNeeded"
                                ref={register}
                                value={prop} />)}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className="class-sub-title mb-2">
                            <span className="green">Class Streaming Type <i className="red-error">*</i></span>
                          </div>
                        </Col>
                        <Col xs={12} lg={6}>
                          <div className="recurring-radio-check-row flex-md-row">
                            <FormGroup name="recurring" ref={register}>
                              <Form.Check
                                label="Zoom"
                                type='radio'
                                onClick={handleClassType}
                                name="classStreamingType"
                                value="Zoom"
                                ref={register}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                              />
                              <Form.Check
                                label="Flexie-web"
                                type='radio'
                                value="Flexie-web"
                                onClick={handleClassType}
                                name="classStreamingType"
                                ref={register}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                              />
                            </FormGroup>
                          </div>
                          {errors.classStreamingType && <p className="alert">{errors.classStreamingType.message}</p>}
                        </Col>
                        <Col xs={12} lg={6}>
                          {classData.classStreamingType ? <Form.Group controlId="streamingUrl">
                            <Form.Label className="green">URL<i className="red-error">*</i></Form.Label>
                            <Form.Control type="text" name="streamingUrl" ref={register} required
                              disabled={(classData.classStreamingType === 'Flexie-web') ? true : false}
                              onChange={handleUrlChange}
                              value={classData.streamingUrl} />
                            {errors.streamingUrl && <p className="alert">{errors.streamingUrl.message}</p>}
                          </Form.Group> : ""}
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-md-5 mt-4 mt-lg-4">
              <Col xs={12} lg={8} lg={12} className="mb-3 mb-lg-0">
                <Card className="card-shadow yellow-bg border-0">
                  <Card.Body>
                    <div className="card-title">
                      <h3 className="green">Schedule</h3>
                    </div>
                    <div className="class-info-block">
                      <Row>
                        <Col xs={12}>
                          <div className="schedul-filter-row mb-4 d-flex flex-wrap flex-md-nowrap">
                            <FormGroup className="date-filter d-flex flex-column">
                              <Form.Label className="green">Date <i className="red-error">*</i></Form.Label>
                              <Controller
                                as={
                                  <DateTimePicker
                                    format="MM/dd/yyyy"
                                    dayPlaceholder="DD"
                                    monthPlaceholder="MM"
                                    yearPlaceholder="YYYY"
                                    calendarIcon={<i className="fas fa-calendar-alt" />}
                                  />}
                                name="date"
                                control={control}
                              />
                              {errors.date && <p className="alert">{errors.date.message}</p>}
                            </FormGroup>
                            <FormGroup className="time-filter d-flex flex-column">
                              <Form.Label className="green">Time <i className="red-error">*</i></Form.Label>
                              <Controller
                                as={
                                  <TimePicker
                                    showSecond={false}
                                    format={'hh:mm a'}
                                    use12Hours
                                    inputReadOnly
                                    clearIcon={<i className="fas fa-times" />}
                                  />}
                                control={control}
                                placeholder="00:00"
                                name="time"
                              />
                              {errors.time && <p className="alert">{errors.time.message}</p>}
                            </FormGroup>
                            <Form.Group controlId="exampleForm.SelectCustom" className="duration-filter d-flex flex-column">
                              <Form.Label className="green">Duration <i className="red-error">*</i></Form.Label>
                              <Form.Control as="select" custom name="duration" ref={register}>
                                <option value="15">15 mins</option>
                                <option value="30">30 mins</option>
                                <option value="45">45 mins</option>
                                <option value="60">1 hour</option>
                                <option value="90">1 hour 30 minutes</option>
                                <option value="105">1 hour 45 minutes</option>
                                <option value="120">2 hours</option>
                              </Form.Control>
                            </Form.Group>
                            <FormGroup className="zone-filter d-flex flex-column">
                              <Form.Label className="green">Time Zone</Form.Label>
                              <Controller
                                as={
                                  <TimezonePicker
                                    absolute={false}
                                    defaultValue="Europe/Moscow"
                                    placeholder="Select timezone..."
                                  />}
                                name="timeZone"
                                defaultValue="Europe/Moscow"
                                control={control}
                              />
                              {errors.duration && <p className="alert">{errors.duration.message}</p>}
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <div className="recurring-block">
                            <div className="recurring-title mb-3">
                              <span>Recurring ?</span>
                            </div>
                            <div className="recurring-radio-check-row d-flex flex-column flex-md-row">
                              <div className="radio-col">
                                <FormGroup name="recurring" ref={register}>
                                {recurringType.map((prop, i) =>
                              <Form.Check
                                key={i}
                                // inline
                                label={prop}
                                type='radio'
                                id={prop}
                                onClick={(e) => handleRecurringSchedule(e)}
                                control={control}
                                setValue={setValue}
                                getValues={getValues}
                                name="recurring"
                                ref={register}
                                value={prop} />)}
                                  {/* <Form.Check label="Never" type='radio' id="inline-radio-1" name="formHorizontalRadios" />
                                  <Form.Check label="Daily" type='radio' id="inline-radio-2" name="formHorizontalRadios" />
                                  <Form.Check label="Weekly" type='radio' id="inline-radio-3" name="formHorizontalRadios" />
                                  <Form.Check label="Monthly" type='radio' id="inline-radio-4" name="formHorizontalRadios" /> */}
                                </FormGroup>
                              </div>
                              <div className="checkbox-col">
                                <div className="weeks-title mb-4">
                                  <span className="align-items-center d-flex">Recur every <Form.Control type="text" /> week(s) on:</span>
                                </div>
                                <div className="weeks-checkbox">
                                  <FormGroup className="d-flex flex-wrap">
                                    {ReccurDays.map((day) =>
                                      <Form.Check
                                        key={day}
                                        inline
                                        disabled={(recurringScheme == 'Daily' || recurringScheme == 'Never') ? true: false}
                                        label={day}
                                        type='checkbox'
                                        id={day}
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        name="recurringSchedule"
                                        ref={register}
                                        value={ReccurDays.indexOf(day)} />)}
                                  </FormGroup>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-md-5 mt-2 mt-lg-4">
              <Col xs={12} lg={8} lg={12} className="mb-3 mb-lg-0">
                <Card className="card-shadow green-alt-bg green-alt-border">
                  <Card.Body>
                    <div className="card-title">
                      <h3 className="green">Prices & Policies</h3>
                    </div>
                    <div className="class-info-block">
                      <Row>
                        <Col xs={8} md={4} className="policies-col">
                          <FormGroup>
                            <Form.Label>Price per registrant <i className="red-error">*</i></Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl type="number" aria-label="Amount (to the nearest dollar)" name="price" ref={register} />
                            </InputGroup>
                            {errors.price && <p className="alert">{errors.price.message}</p>}
                          </FormGroup>
                        </Col>
                        <Col xs={8} md={4} className="policies-col">
                          <FormGroup>
                            <Form.Label>Free Cancellation Policy <i className="red-error">*</i></Form.Label>
                            <FormControl as="select" name="feeCancelationPolicy" custom ref={register}>
                              <option value="1 Hour Before Class">1 Hour Before Class</option>
                              <option value="4 Hours Before Class">4 Hours Before Class</option>
                              <option value="12 Hours Before Class">12 Hours Before Class</option>
                              <option value="24 Hours Before Class">24 Hours Before Class</option>
                              <option value="No Free Cancellation">No Free Cancellation</option>
                            </FormControl>
                          </FormGroup>
                        </Col>
                        <Col xs={8} md={4} className="policies-col">
                          <FormGroup>
                            <Form.Label>Promo Code</Form.Label>
                            <Form.Control type="text" placeholder="Enter promo code" name="promoCode" ref={register} />
                            <span className="policies-note mt-2 d-block"><a className="yellow" href="#">Click here</a> to access Promo Code Admin</span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center mt-md-4">
              <Col>
                <div className="new-class-bottom d-flex flex-column flex-md-row">
                  <div className="form-btn d-flex justify-content-center order-2 order-md-1 flex-wrap flex-sm-nowrap">
                    <Button className="salmon-bg salmon-border" type="submit">
                      <i className="fas fa-save icon-btn"></i> Save to Edit Later
                  </Button>
                    <Button className="salmon-bg salmon-border" type="submit">
                      <i className="fas fa-calendar-check icon-btn"></i> Publish Class
                  </Button>
                    <Button className="salmon-bg salmon-border" onClick={() => reset({})}>
                      <i className="fas fa-redo icon-btn"></i> Reset
                  </Button>
                  </div>
                  <span className="green order-1 order-md-2 text-right mb-3 mb-md-0"><i className="red-error">*</i> Required Fields</span>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
      <Modal show={toggle} onHide={() => handleToggle(!toggle)}>
        <Modal.Body>File not supported, select image type file!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleToggle(!toggle)}>
            Cancel
                    </Button>
        </Modal.Footer>
      </Modal>

    </Container >
  );
}