import React from 'react';
import {Container, Row, Col, Nav, Image} from 'react-bootstrap';
import {  Link } from 'react-router-dom';
import BannerImg from '../../../../img/hero-splash.png';
import HeroSplashImage from '../../../../img/hero-splash.png';
import FlexIconSec from '../../../../img/FlexieIcon-secondary.svg';
import FlexIcon from '../../../../img/FlexieIcon.svg';
import './Index.scss';

export const HeroComponent = () => {
    return (
        <div className="banner-section">
                <Container>
                    <Row>
                        <Col xs={12} xl={5}>
                          <Row>
                              <Col xs={12} md={6} xl={12} className="d-flex align-items-center">
                                  <h1 className="reverse center">live yoga online,<br/> anytime, anywhere.</h1>
                              </Col>
                              <Col xs={12} md={6} xl={12}>
                                  <Image src={BannerImg} className="img-fluid d-block d-xl-none" />
                              </Col>
                          </Row>
                          <Row>
                            <Col xs={12} sm={12} lg={6} xl={12} className="mt-4 mt-xl-3">
                              <div className="card salmon-bg white-border">
                                <div className="card-body text-center">
                                  <h3 className="green">Yoga instructors.</h3>
                                  <p className="reverse ml-4 mr-4">Flexie brings flexibility and control to your practice. Teach from anyplace, on your own schedule. Charge your own rates. Teach in your own way. Build your community and be rewarded for it.</p>
                                  <Nav.Link href="/Intro-Teacher" className="btn btn-secondary btn-reverse btn-float"><Image className="btn-icon" src={FlexIconSec} /> I'm a teacher</Nav.Link>
                                </div>
                              </div>
                            </Col>
                            <Col xs={12} sm={12} lg={6} xl={12} className="mt-4 mt-xl-5">
                              <div className="card salmon-bg white-border">
                                <div className="card-body text-center">
                                  <h3 className="green">Yoga students.</h3>
                                  <p className="reverse ml-4 mr-4">Find online classes easily. Try new instructors. Take live classes when it works for you. From anywhere at any time.</p>
                                  <Link to="/class-finder" className="btn btn-primary btn-reverse btn-float"><Image className="btn-icon" src={FlexIcon} /> I'm a student</Link>
                                </div>
                              </div>
                            </Col>
                          </Row>      
                        </Col>
                        <Col xs={12} xl={7} className="d-none d-xl-block">
                          <Image src={HeroSplashImage} className="img-fluid mt-3 mt-xl-5" />
                        </Col>
                    </Row>
                </Container>
            </div>
      
    )
}