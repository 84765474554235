import React, { Component } from 'react';

class IntroStudent extends Component{
    render() {
        return(
            <header className="App-header">
                Student Introduction
            </header>
        );
    }
}

export default IntroStudent;