import lodash from 'lodash';
import moment from 'moment';
import firebase from "../firebase";
import Class from './Classes'

export default class Student {
   static collectionRef() {
    return firebase.db.collection('studentProfile');
  }

  static getAllStudents(){
    return new Promise((resolve, reject) => { 
        Student.collectionRef().get().then(snapshot => {
         if (snapshot.size) {
             return resolve(snapshot.docs.map(d => new Student(d)))
         }
         else resolve([])
     }).catch((e) => {
         reject()
     })
     })
  }

  static getById(userId){
    return new Promise((resolve, reject) => { 
        Student.collectionRef().doc(userId).get().then(snapshot => {
            if (snapshot.exists) resolve(new Student(snapshot));
            else resolve();
     }).catch((e) => {
         reject()
     })
     })
  }

  static getProfileByUserId(userId) {
    return new Promise((resolve, reject) => {
      Student.collectionRef()
        .where('userId', '==', userId)
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            return resolve(new Student(snapshot.docs[0]))
          }
          else resolve('')
        }).catch((e) => {
          reject()
        })
    })
  }
  static uploadImage(fileObj, id){
    return new Promise ((resolve, reject) => {
      const rootRef = firebase.storage.ref();
      const fileRef = rootRef.child(`studentProfile/${id}/`+fileObj.file.name);
      fileRef.put(fileObj.file).then(async snapshot => {
        if(snapshot){
          const imageUrl = await snapshot.ref.getDownloadURL();
        resolve(imageUrl)
        }
        console.log('upload successfull', snapshot);
      }).catch(err => {
        reject(err);
        console.log('error while uploading', err);
      });
    }) 
  }

  static getInterestedClasses(classIds) {
      return new Promise((resolve, reject) => {
        Class.getAllClassesWithTeacherDetails().then((classes) => {
               classes = lodash.filter(classes, (c) => !classIds.includes(c.c.id) && !c.c.canceled) 
               if(classes.length){
               let cl = classes.length > 4 ? classes.slice(0, 4): classes.slice(0, classes.length)
               resolve(cl);
               }
               else resolve([]);
        })
      })
  }


  constructor(snapshot) {
    if (snapshot) {
      this._id = snapshot.id;
      this._data = snapshot.data() || {};
      this._ref = snapshot.ref;
    } else {
      this._ref = Student.collectionRef().doc();
      this._id = this._ref.id;
      this._data = {};
    }
  }

   save() {
    const _self = this;
    return new Promise((resolve, reject) => {
      _self._ref.set(this.data).then(() => {
        resolve(_self);
      });
    });
  }


  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get data() {
    return lodash.pickBy(this._data, (v) => v !== null && v !== undefined);
  }

  set data(data) {
    return this._data = lodash.pickBy(data, (v) => v !== null && v !== undefined);;
  }


  get FullName() {
      return `${this._data.firstName} ${this._data.lastName}`
  }

  get firstName() {
    return this._data.firstName;
  }

  set firstName(firstName) {
    this._data.firstName = firstName;
  }

  get userId() {
    return this._data.userId;
  }

  set userId(userId) {
    this._data.userId = userId;
  }

  get lastName() {
    return this._data.lastName;
  }

  set lastName(lastName) {
    this._data.lastName = lastName;
  }

  get email() {
    return this._data.email;
  }

  set email(email) {
    this._data.email = email;
  }


  get cellPhone() {
    return this._data.cellPhone;
  }

  set cellPhone(cellPhone) {
    this._data.cellPhone = cellPhone;
  }

  get preferredContactMethod() {
    return this._data.preferredContactMethod;
  }

  set preferredContactMethod(preferredContactMethod) {
    this._data.preferredContactMethod = preferredContactMethod;
  }

  get dob() {
    return this._data.dob
  }

  set dob(dob) {
    this._data.dob = firebase.timestamp.fromDate(moment(dob).toDate());
  }

  get profileImage() {
    return this._data.profileImage;
  }

  set profileImage(profileImage) {
    this._data.profileImage = profileImage;
  }

  get timeZone() {
    return this._data.timeZone;
  }

  set timeZone(timeZone) {
    this._data.timeZone = timeZone;
  }


  get customerProfileId() {
    return this._data.customerProfileId;
  }

  set customerProfileId(customerProfileId) {
    this._data.customerProfileId = customerProfileId;
  }

  get customerPaymentProfileId() {
    return this._data.customerPaymentProfileId;
  }

  set customerPaymentProfileId(customerPaymentProfileId) {
    this._data.customerPaymentProfileId = customerPaymentProfileId;
  }



}