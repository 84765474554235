import { useState, useEffect, useContext, useCallback } from 'react';
import lodash from 'lodash';
import moment from 'moment';

import firebase from '../../../../firebase'
import Student from '../../../../firebase/model/student';
import { calculatePagination } from '../../../../utils/helper'
import ClassRoster from '../../../../firebase/model/classRoster';
import Class from '../../../../firebase/model/Classes';
import ClassContext from '../../Main/Context';

useStudentClasses.secondsLeft = 0;
useStudentClasses.nextClassHappening = false;

function useStudentClasses() {

    const { classRosters, claims, setClass, setClassRosters, ShowModal, currentUserRole, setReadyToJoinClass } = useContext(ClassContext)
    const [studentProfileDetails, setStudentProfileDetails] = useState({});
    const [nextClassHappening, setNextClassHappening] = useState(false);
    const [studentUpcomingClasses, setStudentUpcomingClasses] = useState(null);
    const [upcomingClassesPages, setUpcomingClassesPages] = useState(null);
    const [previousClassesPages, setPreviousClassesPages] = useState(null);
    const [bookedClasses, setBookedClasses] = useState([]);
    const [interestedClasses, setInterestedClasses] = useState([]);
    const [searchedClasses, setSearchedClasses] = useState([]);
    const [studentPreviousClasses, setStudentPreviousClasses] = useState(null);

    const fetchStudentProfile = async () => {
        const studentProfileDetails = await Student.getProfileByUserId(claims.user_id);
        setStudentProfileDetails(studentProfileDetails);
    }

    const fectchClassses = async () => {
        let studentUpcomingClasses = []
        let studentPreviousClasses = []
        const studentAllClasses = await ClassRoster.getAllClassesByClassRosters(claims.user_id);
        studentAllClasses.map((c) => {
            if (c.studentClass.date > new Date()) studentUpcomingClasses.push(c)
            else studentPreviousClasses.push(c);
        })
        studentUpcomingClasses = lodash.orderBy(studentUpcomingClasses, [(studentUpcomingClasses) => studentUpcomingClasses.studentClass.date], 'asc');
        studentPreviousClasses = lodash.orderBy(studentPreviousClasses, [(studentPreviousClasses) => studentPreviousClasses.studentClass.date], 'desc');
        setUpcomingClassesPages(calculatePagination(studentUpcomingClasses, 5));
        setPreviousClassesPages(calculatePagination(studentPreviousClasses, 5));
        setStudentUpcomingClasses(studentUpcomingClasses);
        setStudentPreviousClasses(studentPreviousClasses);
    }

    const fetchClassRosters = async (id) => {
        let rosters = classRosters ? classRosters : await ClassRoster.getAllClassRosterByUserId(claims.user_id);
        let classIds = rosters && rosters.map((c) => c.classId);
        setBookedClasses(classIds);
        const intererstedClasses = classIds ? await Student.getInterestedClasses(classIds) : []
        setInterestedClasses(intererstedClasses);
    }

    const handlePageChange = (index, list) => {
        let first = index * 5
        let last = first + 5
        if (list === 1) setUpcomingClassesPages({ ...upcomingClassesPages, first, last, currrentPage: index })
        else setPreviousClassesPages({ ...previousClassesPages, first, last, currrentPage: index })
    }

    const checkBookedClass = (id) => {
        if (bookedClasses.includes(id)) {
            localStorage.removeItem('classDetails');
            setClass(null)
        }
        return bookedClasses.includes(id)
    }

    const handlSearchClass = async (date) => {
        const searchedClasses = date ? await Class.getAllClassesByDateWithTeacherDetails(date) : []
        setSearchedClasses(searchedClasses);
    }

    const cancelClass = async (details) => {
        // const classDetails = await Class.getById(details.studentClass.id);
        try {
            let rosters = classRosters.filter(cr => cr.classId !== details.studentClass.id)
        const response = await firebase.cancelStudentClass({
            userId: claims.user_id,
            className: details.studentClass.className,
            date: details.studentClass.date,
            amount: details.studentClass.price,
            instructorName: details.teacher.fullName
        })
        if (response.data) {
            await ClassRoster.deleteClassRosterByUserIdClassId(claims.user_id, details.studentClass.id)
            setClassRosters(rosters);
            ShowModal('Class cancelled Successfully.')
        }
    } catch(err){
        
       ShowModal('something went wrong while canceling class')
    }
    }

    const checkNextClassHappening = (classes) => {
        const nextClass = classes && classes.length && classes[0].studentClass
        if (nextClass) {
            let duration = moment.duration(moment(nextClass.date).diff(moment()));
            if (duration && duration.asMinutes() < 16 && duration.asMinutes() > 0) {
                setNextClassHappening(true)
                useStudentClasses.nextClassHappening = true;
                useStudentClasses.secondsLeft = (Number(nextClass.duration) + duration.asMinutes()) * 60;
                setReadyToJoinClass(nextClass);
                countDown();
            }
        }
    }

    const countDown = useCallback(() => {
        let interval = setInterval(() => {
            if (useStudentClasses.secondsLeft === 0) {
                setNextClassHappening(false)
                useStudentClasses.secondsLeft = 0
                useStudentClasses.nextClassHappening = false;
                setReadyToJoinClass(null);
                clearInterval(interval);
                fectchClassses();
            } else {
                useStudentClasses.secondsLeft = useStudentClasses.secondsLeft - 1;
            }
        }, 1000)
    })

    useEffect(() => {
        if (studentUpcomingClasses &&
            studentUpcomingClasses.length) {
            let interval = setInterval(() => {
                if (useStudentClasses.secondsLeft === 0) checkNextClassHappening(studentUpcomingClasses);
                else clearInterval(interval);
            }, 1000)
        }
    }, [studentUpcomingClasses])


    useEffect(() => {
        claims && fetchClassRosters();
        claims && fectchClassses();
    }, [classRosters])

    useEffect(() => {
        if (claims) {
            fetchStudentProfile();
            fetchClassRosters();
            fectchClassses();
        }
        return clearStudentClassStoarge();
    }, [claims, currentUserRole]);

    const clearStudentClassStoarge = () => {
        setStudentProfileDetails({})
        setStudentUpcomingClasses(null);
        setUpcomingClassesPages(null);
        setPreviousClassesPages(null);
        setBookedClasses([]);
        setInterestedClasses([]);
        setStudentPreviousClasses(null);
    }
 
    return {
        studentProfileDetails,
        studentUpcomingClasses,
        studentPreviousClasses,
        upcomingClassesPages,
        previousClassesPages,
        classRosters,
        setStudentProfileDetails,
        handlePageChange,
        interestedClasses,
        bookedClasses,
        checkBookedClass,
        clearStudentClassStoarge,
        searchedClasses,
        handlSearchClass,
        setSearchedClasses,
        cancelClass,
        nextClassHappening
    }

}


export default useStudentClasses;