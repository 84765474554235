import moment from 'moment';
import lodash from 'lodash';
import firebase from "../firebase";
import Teacher from './teacher';
import ClassRoster from './classRoster';

export default class Class {
   static collectionRef() {
    return firebase.db.collection('Classes');
  }

  static getAllClasses(){
    return new Promise((resolve, reject) => { 
      Class.collectionRef().get().then(snapshot => {
         if (snapshot.size) {
             return resolve(snapshot.docs.map(d => new Class(d)))
         }
         else resolve([])
     }).catch((e) => {
         reject()
     })
     })
  }

  static getAllClassesWithTeacherDetails(){
    const currentDate = firebase.timestamp.fromDate(moment().toDate());
    return new Promise((resolve, reject) => { 
      Class.collectionRef()
      .where('date', '>', currentDate)
      .where('deleted', '==', false)
      .get().then(snapshot => {
         if (snapshot.size) {
          let trPromises = snapshot.docs.map(async(d) => {
            let c = new Class(d);
            let t = c.teacherId ? await Teacher.getProfileByUserId(c.teacherId): {};
            return {c, t}
          })
          return resolve(Promise.all(trPromises))
         }
         else resolve([])
     }).catch((e) => {
         reject()
     })
     })
  }

  static getAllUpcomingClasses(){
    const currentDate = firebase.timestamp.fromDate(moment().toDate());
    return new Promise((resolve, reject) => { 
      Class.collectionRef()
      .where('date', '>', currentDate)
      .where('deleted', '==', false)
      .get().then(snapshot => {
         if (snapshot.size) {
             return resolve(snapshot.docs.map(d => new Class(d)))
         }
         else resolve([])
     }).catch((e) => {
         reject()
     })
     })
  }

  static getAllClassesByDate(date){
    const startingDate = firebase.timestamp.fromDate(moment.utc(date).startOf('day').toDate());
    const endingDate = firebase.timestamp.fromDate(moment.utc(date).endOf('day').toDate());
    return new Promise((resolve, reject) => { 
      Class.collectionRef()
      .where('date', '>=', startingDate)
      .where('date', '<=', endingDate)
      .where('deleted', '==', false)
      .get().then(snapshot => {

         if (snapshot.size) {
             return resolve(snapshot.docs.map(d => new Class(d)))
         }
         else resolve([])
     }).catch((e) => {
         reject()
     })
     })
  }


  static getAllClassesByDateWithTeacherDetails(date){
    const startingDate = firebase.timestamp.fromDate(moment.utc(date).startOf('day').toDate());
    const endingDate = firebase.timestamp.fromDate(moment.utc(date).endOf('day').toDate());
    return new Promise((resolve, reject) => { 
      Class.collectionRef()
      .where('date', '>=', startingDate)
      .where('date', '<=', endingDate)
      .where('deleted', '==', false)
      .get()
      .then(snapshot => {

         if (snapshot.size) {
          let trPromises = snapshot.docs.map(async(d) => {
            let c = new Class(d);
            let t =  c.teacherId ? await Teacher.getProfileByUserId(c.teacherId): {};
            return {c, t}
          })
          return resolve(Promise.all(trPromises))
         }
         else resolve([])
     }).catch((e) => {
         reject()
     })
     })
  }

  static getAllPreviousClasses(){
    const currentDate = firebase.timestamp.fromDate(moment().toDate());
    return new Promise((resolve, reject) => { 
      Class.collectionRef()
      .where('date', '<', currentDate)
      .where('deleted', '==', false)
      .get().then(snapshot => {
         if (snapshot.size) {
             return resolve(snapshot.docs.map(d => new Class(d)))
         }
         else resolve([])
     }).catch((e) => {
         reject()
     })
     })
  }

  static getUpcomingClasses(teacherId){
    const currentDate = firebase.timestamp.fromDate(moment().toDate());
    return Class.collectionRef()
    .where('teacherId', '==', teacherId)
    .where('date', '>', currentDate)
    .where('deleted', '==', false)
    .orderBy('date', 'asc');
  }

  static getPreviousClasses(teacherId){
    const currentDate = firebase.timestamp.fromDate(moment().toDate());
    return Class.collectionRef()
    .where('teacherId', '==', teacherId)
    .where('date', '<', currentDate)
    .where('deleted', '==', false)
    .orderBy('date', 'asc');
  }

  static getAllUpcomingClassesByTeacher(teacherId){
    return new Promise((resolve, reject) => {
      Class.getUpcomingClasses(teacherId).get().then(snapshot => {
         if (snapshot.size) {
            resolve(snapshot.docs.map(d => new Class(d)))
         }
         else resolve([])
     }).catch((e) => {
         reject()
     })
     })
  }

  static getAllPreviousClassesByTeacher(teacherId){
    return new Promise((resolve, reject) => {
      Class.getPreviousClasses(teacherId).get().then(snapshot => {
         if (snapshot.size) {
          resolve(snapshot.docs.map(d => new Class(d)))
         }
         else resolve([])
     }).catch((e) => {
         reject()
     })
     })
  }

  static getAllUpcomingClassesByTeacherWithAuth(teacherId){
    return new Promise((resolve, reject) => {
      Class.getUpcomingClasses(teacherId).get().then(snapshot => {
         if (snapshot.size) {
          let Promises = snapshot.docs.map(async(d) => {
            let c = new Class(d);
            let t =  c.id ? await ClassRoster.getAllCallRosterByClassId(c.id): "";
            c.studentsEnrolled = t ? t.length: 0
            return c
          })
          return resolve(Promise.all(Promises))
         }
         else resolve([])
     }).catch((e) => {
         reject()
     })
     })
  }

  static getAllPreviousClassesByTeacherWithAuth(teacherId){
    return new Promise((resolve, reject) => {
      Class.getPreviousClasses(teacherId).get().then(snapshot => {
         if (snapshot.size) {
          let Promises = snapshot.docs.map(async(d) => {
            let c = new Class(d);
            let t =  c.id ? await ClassRoster.getAllCallRosterByClassId(c.id): "";
            c.totalEarnings = t.length ? lodash.sumBy(t, (cr) => Number(cr.amountPaid) ) : 0
            c.studentsEnrolled = t ? t.length: 0
            return c
          })
          return resolve(Promise.all(Promises))
         }
         else resolve([])
     }).catch((e) => {
         reject()
     })
     })
  }

  static getAllClassesByTeacher(teacherId){
     return new Promise((resolve, reject) => {
         
     Class.collectionRef()
     .where("teacherId","==",teacherId)
     .where('deleted', '==', false)
     .get().then(snapshot => {
        if (snapshot.size) {
            return resolve(snapshot.docs.map(d => new Class(d)))
        }
        else resolve([])
    }).catch((e) => {
        reject()
    })
    })
  }

  static getById(id){
    return new Promise((resolve, reject) => {
      Class.collectionRef()
        .doc(id)
        .get()
        .then((snapshot) => {
          if (snapshot.exists) resolve(new Class(snapshot));
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static uploadImage(file, id){
    return new Promise ((resolve, reject) => {
      const rootRef = firebase.storage.ref();
      const fileRef = rootRef.child(`classes/${id}/`+file.name);
      fileRef.put(file).then(async snapshot => {
        if(snapshot){
          const imageUrl = await snapshot.ref.getDownloadURL();
        resolve(imageUrl)
        }
        console.log('upload correcto', snapshot);
      }).catch(err => {
        reject(err);
        console.log('error while uploading', err);
      });
    }) 
  }

  static getImage(name){
    return new Promise ((resolve, reject) => {
      const rootRef = firebase.storage.ref();
      rootRef.child(name).getDownloadURL().then( url => {
        resolve(url);
      }).catch(err => {
        reject('');
        console.log('error while getting image', err);
      })
    })
  }

  static async add(params) {
    const newClass = new Class();
    try{
    if( params.classImage) newClass.classImage = await Class.uploadImage(params.classImage, newClass.id);
    if (params.teacherId) newClass.teacherId = params.teacherId;
    if (params.className) newClass.className = params.className;
    if (params.classDescription) newClass.classDescription = params.classDescription;
    newClass.classSize = params.classSize? params.classSize: 100;
    if (params.propsNeeded) newClass.propsNeeded = params.propsNeeded;
    if (params.difficultyLevel) newClass.difficultyLevel = params.difficultyLevel;
    if (params.yogaType) newClass.yogaType = params.yogaType;
    if (params.recurring) newClass.recurring = params.recurring;
    if (params.recurring != 'Never'){
      newClass.recurringCycle = 30;
      if(params.recurringSchedule) newClass.recurringSchedule = params.recurringSchedule;
    }
    if (params.date) newClass.date = params.date;
    if (params.time) newClass.time = params.time;
    if (params.timeZone) newClass.timeZone = params.timeZone;
    if (params.duration) newClass.duration = params.duration;
    if (params.price) newClass.price = params.price;
    if (params.feeCancelationPolicy) newClass.feeCancelationPolicy = params.feeCancelationPolicy;
    if (params.promoCode) newClass.promoCode = params.promoCode;
    if (params.classStreamingType) newClass.classStreamingType =  params.classStreamingType;
    if (params.streamingUrl) newClass.streamingUrl =  params.streamingUrl;
    newClass.deleted = false;
    const batch = firebase.db.batch(); 
    batch.set(newClass.ref, newClass.data);
    await batch.commit();
    } catch(err){
      throw err
    }
  }

  constructor(snapshot) {
    if (snapshot) {
      this._id = snapshot.id;
      this._data = snapshot.data() || {};
      this._ref = snapshot.ref;
    } else {
      this._ref = Class.collectionRef().doc();
      this._id = this._ref.id;
      this._data = {};
    }
  }

   static async update(id) {
    const Class = new Class();
    if (this.teacherId) Class.teacherId = this.teacherId;
    if (this.className) Class.className = this.className;
    if (this.classDescription) Class.classDescription = this.classDescription;
    if (this.classSize) Class.classSize = this.classSize;
    if (this.difficultyLevel) Class.difficultyLevel = this.difficultyLevel;
    if (this.yogaType) Class.yogaType = this.yogaType;
    if (this.duration) Class.duration = this.duration;
    if (this.recurring) Class.recurring = this.recurring;
    if (this.feeCancelationPolicy) Class.feeCancelationPolicy = this.feeCancelationPolicy;
    if (this.promoCode) Class.promoCode = this.promoCode;
    const batch = firebase.db.batch();
    batch.set(Class.ref, Class.data);
    await batch.commit();
  }

   save() {
    const _self = this;
    return new Promise((resolve, reject) => {
      _self._ref.set(this.data).then(() => {
        resolve(_self);
      });
    });
  }

   static async delete(id) {
    return Class.collectionRef()
      .doc(id)
      .update({
        deleted: true
      })
  }

  get id() {
    return this._id;
  }

  get ref() {
    return this._ref;
  }

  get data() {
    return lodash.pickBy(this._data, (v) => v !== null && v !== undefined);
  }

  set data(data) {
     this._data = lodash.pickBy(data, (v) => v !== null && v !== undefined);;
  }


  get teacherId() {
    return this._data.teacherId;
  }

  set teacherId(teacherId) {
    this._data.teacherId = teacherId;
  }


  get classDescription() {
    return this._data.classDescription;
  }

  set classDescription(classDescription) {
   this._data.classDescription = classDescription;
  }

  get className() {
    return this._data.className;
  }

  set className(className) {
    this._data.className = className;
   }

  get classImage() {
    return this._data.classImage;
  }

  set classImage(classImage) {
   this._data.classImage = classImage;
  }

  get classSize() {
    return this._data.classSize;
  }

  set classSize(classSize) {
   this._data.classSize = classSize;
  }

  get difficultyLevel() {
    return this._data.difficultyLevel;
  }

  set difficultyLevel(difficultyLevel) {
    this._data.difficultyLevel = difficultyLevel;
   }

  get yogaType() {
    return this._data.yogaType;
  }

  set yogaType(yogaType) {
    this._data.yogaType = yogaType;
   }

  get date() {
    return moment(this._data.date.toDate());
  }

  set date(date) {
    this._data.date = firebase.timestamp.fromDate(moment(date).toDate());
   }

   get time() {
    return moment(this._data.date.toDate());
  }

  set time(time) {
    let date = this.date.set({
      'hour': moment(time).get('hour'),
      'minute': moment(time).get('minute')
    })
    this._data.date = firebase.timestamp.fromDate(moment(date).toDate());
   }

  get duration() {
    return this._data.duration;
  }

  set duration(duration) {
    this._data.duration = duration;
   }

   get timeZone() {
    return this._data.duration;
  }

  set timeZone(timeZone) {
    this._data.timeZone = timeZone;
   }

   get recurring() {
    return this._data.recurring;
  }

  set recurring(recurring) {
    this._data.recurring = recurring;
   }

   get price() {
    return this._data.price;
  }

  set price(price) {
    this._data.price = price;
   }

   get propsNeeded() {
    return this._data.propsNeeded;
  }

  set propsNeeded(propsNeeded) {
    this._data.propsNeeded = propsNeeded;
   }

  get feeCancelationPolicy() {
    return this._data.feeCancelationPolicy;
  }

  set feeCancelationPolicy(feeCancelationPolicy) {
    this._data.feeCancelationPolicy = feeCancelationPolicy;
   }

  get promoCode() {
    return this._data.promoCode;
  }

  set promoCode(promoCode) {
    this._data.promoCode = promoCode;
   }

   get classStreamingType() {
    return this._data.classStreamingType;
  }

  set classStreamingType(classStreamingType) {
    this._data.classStreamingType = classStreamingType;
   }

   get streamingUrl() {
    return this._data.streamingUrl;
  }

  set streamingUrl(streamingUrl) {
    this._data.streamingUrl = streamingUrl;
   }

  get canceled() {
     return this._data.canceled;
   }

   set canceled(canceled) {
    this._data.canceled = canceled;
   }
  
   get deleted() {
    return this._data.deleted
  }

  set deleted(deleted) {
    this._data.deleted = deleted
  }

  get studentsEnrolled() {
    return this._data.studentsEnrolled
  }

  set studentsEnrolled(studentsEnrolled) {
    this._data.studentsEnrolled = studentsEnrolled;
  }

  get totalEarnings() {
    return this._data.totalEarnings
  }

  set totalEarnings(totalEarnings) {
    this._data.totalEarnings = totalEarnings;
  }

  get recurringSchedule() {
    return this._data.recurringSchedule
  }

  set recurringSchedule(recurringSchedule) {
    this._data.recurringSchedule = recurringSchedule;
  }

  get recurringCycle() {
    return this._data.recurringCycle
  }

  set recurringCycle(recurringCycle) {
    this._data.recurringCycle = recurringCycle;
  }


}