import React, { Component } from 'react';

class Careers extends Component{
    render() {
        return(
            <header className="App-header">
                Careers
            </header>
        );
    }
}

export default Careers;