import lodash from 'lodash';
import { uuid } from 'uuidv4';
import moment from 'moment';

export const formatTeacherRegisterValues = (obj) => {
  return lodash.pickBy({
    plan: obj.plan && obj.plan.trim(),
    firstName: obj.firstName && obj.firstName.trim(),
    lastName: obj.lastName && obj.lastName.trim(),
    password: obj.password && obj.password.trim(),
    email: obj.password && obj.email.trim(),
    addressLine1: obj.addressLine1 && obj.addressLine1.trim(),
    addressLine2: obj.addressLine2 && obj.addressLine2.trim(),
    city: obj.city && obj.city.trim(),
    state: obj.state && obj.state.trim(),
    zip: obj.zip && obj.zip,
    ccNumber: obj.ccNumber && obj.ccNumber,
    inputYear: obj.inputYear && obj.inputYear.trim(),
    inputMonth: obj.inputMonth && obj.inputMonth.trim(),
    cvvNumber: obj.cvvNumber && obj.cvvNumber
  }, function (value, key) {
    return !(value === undefined);
  });

}

export const formatStudentRegisterValues = (obj) => {
  return lodash.pickBy({
    firstName: obj.firstName && obj.firstName.trim(),
    lastName: obj.lastName && obj.lastName.trim(),
    password: obj.password && obj.password.trim(),
    email: obj.email && obj.email.trim(),
    zip: obj.zip && obj.zip,
    ccNumber: obj.ccNumber && obj.ccNumber,
    inputYear: obj.inputYear && obj.inputYear.trim(),
    inputMonth: obj.inputMonth && obj.inputMonth.trim(),
    cvvNumber: obj.cvvNumber && obj.cvvNumber
  }, function (value, key) {
    return !(value === undefined);
  });

}

export const calculatePagination = (list, limit) => {
  let first = 0;
  let last = first + limit;
  let totalPages = Math.ceil(list.length / limit)
  let pages = [...Array(totalPages).keys()];
  return { first, last, totalPages, pages, currrentPage: 0 };
}

export const generateClassStreamingURl = () => {
  const ranndomString = uuid();
  return `https://meet.flexie.yoga/${ranndomString}`
}

export const getFormattedDate = (date, time) => {
  if (moment(date) > moment(time)) {
    let d = moment(date).set({
      hour: moment(time).get('hour'),
      minute: moment(time).get('minute')
    })
    return d.toDate()
  }
  else return time;
}

export const formatDuration = (duration) => {
  if (duration <= 60) return `${duration} min.`
  else {
    let hours = (Number(duration) / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return `${rhours} hour ${rminutes ? `${rminutes} min` : ""}.`
  }
}

export const checkClassCancelStatus = (date) => {
  let duration = moment.duration(moment(date).diff(moment()));
  return (duration && duration.asMinutes() < 60)
}

export const formatError = (err) => {

  let message = !err.response ?
    err.message :
    (err.response.data ?
      (err.response.data.error
        ? err.response.data.error
        : (err.response.data.message
          ? (typeof err.response.data.message === 'object'? err.response.data.message.message: err.response.data.message)
          : ""))
      : "")
      return message;
}

export const pageNames = {

    '/student/profile': 'Profile',
    '/student/dashboard': 'Dashboard',
    '/student/payment': 'Payment',
    '/student/upgrade-role': 'UpgradeRole',
    '/teacher/profile': 'Profile',
    '/teacher/billing': 'Billing',
    '/teacher/classes': 'Classes',
    '/teacher/class-attendance': 'ClassAttendence',
    '/teacher/class-builder': 'ClassBuilder',
    '/teacher/dashboard': 'Dashboard',
    '/teacher/resources':'Resources',
    '/about': 'About',
    '/account-verification': 'AccountVerification',
    '/careers':'Careers',
    '/teachers':'Trachers',
    '/profile/teacher':'Profile',
    '/class/details':'ClassDetails',
    '/class-finder':'ClassFinder',
    '/contact':'Contact',
    '/faq':'FAQ',
    '/':'FlexieHome',
    '/intro-student':'StudentIntro',
    '/intro-teacher':'TeacherIntro',
    '/legal':'Legal',
    '/login':'Login',
    '/password-recovery':'PasswordRecovery',
    '/press':'Press',
    '/register': 'Register',
    '/student-registration':'StudentRegistration',
    '/teacher-registration':'TeacherRegistration',
    '/support':'Support'
  }

  export const classBookMessage = 'Congatulations! You are on your way to practicing yoga. Check your email for information on how to access your class, or come back and check your upcoming classes.'