import React, { useState, useContext } from 'react';
import { Image, Container, Row, Col } from 'react-bootstrap'
import Slider from 'react-rangeslider'

import FlexiIcon from '../../../../img/FlexieIcon-reverse.svg'
import SuccessCalculatorImage from '../../../../img/success-calculator.svg'
import TeacherBenifitSplashImage from '../../../../img/teacher_benefits-splash-1.png'
import TeacherBenifitSplashImage2 from '../../../../img/teacher_benefits-splash-2.png'
import TeacherBenifitSplashImage3 from '../../../../img/teacher_benefits-splash-3.png'
import TeacherBenifitSplashImage4 from '../../../../img/teacher_benefits-splash-4.png'
import BenifitsIcon from '../../../../img/benefits.svg'
import ClassContext from '../Context';

const IntroTeacher = () => {
    
    const { selectorValue, handleSelector} = useContext(ClassContext);

    const calulateMonthlyPayout = () => {
        const creditCardFees = ((selectorValue.classValue * selectorValue.priceValue * selectorValue.studentValue) * 0.035).toFixed(2)
        const subscriptionFees = 29.95;
        const monthlySalesTotal = selectorValue.classValue * selectorValue.priceValue * selectorValue.studentValue
        return (monthlySalesTotal - subscriptionFees - creditCardFees).toFixed(2)
    }
    
        return (
            <div className="teacher-intro">
                <div className="sub-inner-banner">
                    <Container>
                        <Row>
                            <Col xs={12} className="d-flex align-items-center">
                                <h1 className="reverse center large">
                                    yoga teachers! <br /> meet
                                    <span className="green"> flexie for yoga </span>
                                </h1>
                            </Col>
                        </Row>
                        <div className="sub-banner-bottom-btn mt-4 position-relative">
                            <Row className="justify-content-center">
                                <Col xs="auto">
                                    <a href="/teacher-registration" className="btn btn-salmon">
                                        <Image className="btn-icon" src={FlexiIcon} />
                                        Join Today!
                                    </a>
                                </Col>
                                <Col xs="auto">
                                    <a href="/login" className="btn btn-primary">
                                        <Image className="btn-icon" src={FlexiIcon} />
                                        Log In Now
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                <section className="content white-bg pt-5 pb-5 mt-0">
                    <Container>
                        <Row className="align-items-center">
                            <Col xs={12} md={6} className="text-center text-right-desktop">
                                <div className="yellow-spot-bg">
                                    <h2 className="salmon large">a flexible <br /> online tool</h2>
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <h4 className="green-alt mt-4">You set the rates, the times, and the class style.</h4>
                                <ul className="pl-4">
                                    <li><strong>No more chasing unpaid donations/payments.</strong></li>
                                    <li><strong>Easy to use.</strong> Automate class registration and payments</li>
                                    <li><strong>More students.</strong> Extend your practice.</li>
                                    <li><strong>Make more money in less time.</strong></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="yellow-bg pt-5 pb-5">
                    <Container>
                        <Row className="row-eq-height align-items-center">
                            <Col xs={12}>
                                <Image src={SuccessCalculatorImage} className="img-fluid w-75" />
                                <Col xs={12} md={8} className="ml-auto mr-auto mt-4">
                                    <div className="success-sliders mb-3">
                                        <div className="d-flex w-100 justify-content-between align-items-center">
                                            <h4 className="green-alt mt-3 w-50"># of classes per week </h4>
                                            <div className="range-selector w-50 d-flex">
                                                <h4 class="green">1</h4>
                                                <Slider
                                                    value={selectorValue.classValue}
                                                    orientation="horizontal"
                                                    min={1}
                                                    max={14}
                                                    step={1}
                                                    handleLabel={`${selectorValue.classValue}`}
                                                    onChange={(value) => handleSelector(value, 'classValue')}
                                                />
                                                <h4 class="green">14</h4>
                                            </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between align-items-center">
                                            <h4 className="green-alt mt-3 w-50">average price per class</h4>
                                            <div className="range-selector w-50 d-flex">
                                            <h4 class="green">$5</h4>
                                                <Slider
                                                    value={selectorValue.priceValue}
                                                    min={5}
                                                    max={25}
                                                    step={1}
                                                    handleLabel={`$${selectorValue.priceValue}`}
                                                    onChange={(value) => handleSelector(value, 'priceValue')}
                                                    orientation="horizontal"
                                                />
                                              <h4 class="green">$25</h4>
                                            </div>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between align-items-center">
                                            <h4 className="green-alt mt-3 w-50"># students per class</h4>
                                            <div className="range-selector w-50 d-flex">
                                            <h4 class="green">1</h4>
                                                <Slider
                                                    value={selectorValue.studentValue}
                                                    min={1}
                                                    max={40}
                                                    step={1}
                                                    handleLabel={`${selectorValue.studentValue}`}
                                                    orientation="horizontal"
                                                    onChange={(value) => handleSelector(value, 'studentValue')}
                                                />
                                              <h4 class="green">40</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="success-totals">
                                        <h4 className="rounded-25 green-bg reverse w-100 m-0 p-2 pl-3 pr-3 mb-4 d-flex justify-content-between align-items-center">
                                            monthly sales total:
                                            <span className="larger">${selectorValue.classValue * selectorValue.priceValue * selectorValue.studentValue}</span>
                                        </h4>
                                        <h4 className="salmon mb-4">
                                            monthly flexie subscription
                                            <span className="green float-right">($29.95)</span>
                                        </h4>
                                        <h4 className="salmon mb-4">
                                            credit card processing fees
                                            <span className="green float-right">(${((selectorValue.classValue * selectorValue.priceValue * selectorValue.studentValue) * 0.035).toFixed(2)})</span>
                                        </h4>
                                        <h4 className="rounded-25 green-bg reverse w-100 m-0 p-2 pl-3 pr-3 d-flex justify-content-between align-items-center">
                                            total monthly payout:
                                            <span className="larger">$ {calulateMonthlyPayout()}</span>
                                        </h4>
                                    </div>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="salmon-green-alt-50">
                    <Container>
                        <Row className="row-eq-height align-items-center">
                            <Col xs={12} md={6}>
                                <Image src={TeacherBenifitSplashImage} className="w-100 m-2" />
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="p-4">
                                    <Image src={BenifitsIcon} className="img-fluid w-75" />
                                    <h2 className="yellow">freedom</h2>
                                    <h3 className="green">Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h3>
                                    <ul className="reverse pl-4">
                                        <li>Lorem ipsum consectetur. Ut enim ad minim veniam, quis nostrud exercitation aliquip ex commodo.</li>
                                        <li>Lorem ipsum consectetur. Ut enim ad minim veniam, quis nostrud exercitation aliquip ex commodo.</li>
                                        <li>Lorem ipsum consectetur. Ut enim ad minim veniam, quis nostrud exercitation aliquip ex commodo.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="green-alt-salmon-50">
                    <Container>
                        <Row className="row-eq-height align-items-center">
                            <Col xs={12} md={6} className="order-2 order-lg-1">
                                <div className="p-4">
                                    <h2 className="yellow">administration</h2>
                                    <h3 className="green">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h3>
                                    <ul className="reverse pl-4">
                                        <li>Lorem ipsum consectetur. Ut enim ad minim veniam, quis nostrud exercitation aliquip ex commodo.</li>
                                        <li>Lorem ipsum consectetur. Ut enim ad minim veniam, quis nostrud exercitation aliquip ex commodo.</li>
                                        <li>Lorem ipsum consectetur. Ut enim ad minim veniam, quis nostrud exercitation aliquip ex commodo.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="order-1 order-lg-2">
                                <Image src={TeacherBenifitSplashImage2} className="w-100 m-2" />
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section class="salmon-green-alt-50">
                    <Container>
                        <Row className="row-eq-height align-items-center">
                            <Col xs={12} md={6}>
                                <Image src={TeacherBenifitSplashImage3} className="w-100 m-2" />
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="p-4">
                                    <h2 className="yellow">marketing</h2>
                                    <h3 className="green">Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h3>
                                    <ul className="reverse pl-4">
                                        <li>Lorem ipsum consectetur. Ut enim ad minim veniam, quis nostrud exercitation aliquip ex commodo.</li>
                                        <li>Lorem ipsum consectetur. Ut enim ad minim veniam, quis nostrud exercitation aliquip ex commodo.</li>
                                        <li>Lorem ipsum consectetur. Ut enim ad minim veniam, quis nostrud exercitation aliquip ex commodo.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section class="green-alt-salmon-50">
                    <Container>
                        <Row className="row-eq-height align-items-center">
                            <Col xs={12} md={6} className="order-2 order-lg-1">
                                <div className="p-4">
                                    <h2 className="yellow">transparency/reports</h2>
                                    <h3 className="green">Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h3>
                                    <ul className="reverse pl-4">
                                        <li>Lorem ipsum consectetur. Ut enim ad minim veniam, quis nostrud exercitation aliquip ex commodo.</li>
                                        <li>Lorem ipsum consectetur. Ut enim ad minim veniam, quis nostrud exercitation aliquip ex commodo.</li>
                                        <li>Lorem ipsum consectetur. Ut enim ad minim veniam, quis nostrud exercitation aliquip ex commodo.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="order-1 order-lg-2">
                                <Image src={TeacherBenifitSplashImage4} className="w-100 m-2" />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        );
    
}

export default IntroTeacher;