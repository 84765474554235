import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Image, Card, FormGroup, Modal, Spinner } from 'react-bootstrap';

import FlexieIcon from '../../../../img/FlexieIcon-reverse.svg';
import firebase from "../../../../firebase";

const schema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup
        .string()
        .email("Please enter a valid email"),
    message: yup.string().required('Please enter the meesage')
});


const Support = () => {
    const { register, handleSubmit, errors, reset } = useForm({ validationSchema: schema });
    const [firebaseError, setFirebaseError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toggle, handleToggle] = useState(false)

    const onSubmit = async (data) => {
        try {
            setLoading(!loading);
            await firebase.sendSupportMail(data).then((data) => {
                if (data) {
                    reset();
                    setLoading(false);
                    handleToggle(!toggle)
                }
            })
        } catch (err) {
          let message = err.message ? err.message : 'Something went wrong'
            setLoading(false);
            setFirebaseError(message);
            handleToggle(!toggle)
        }
    }

    return (
        <div className="contact-page">
            <div className="contact-header-bg"></div>
            <Container>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mt-5 mb-4">
                        <Col xs={8} sm={6} className="yellow-spot-bg left-spot small-spot alt-spot">
                            <h1 className="green large mb-0"> <i className="fas fa-mail-bulk mr-4"></i>Support</h1>
                        </Col>
                        <Col xs={4} sm={6} className="d-flex justify-content-start align-items-center">
                            <div className="contect-head-right-social d-flex justify-content-end">
                                <i className="fab fa-instagram fa-2x salmon mr-3"></i>
                                <i className="fab fa-facebook-square fa-2x salmon"></i>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <h3 className="salmon">Lorem ipsum dolor sit amet</h3>
                            <p className="green mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur.
                                <Link className="text-underline" to='/contact'>Click here for Contact</Link></p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} lg={8} className="mb-5">
                            <Card className="card-shadow green-alt-bg green-alt-border mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} className="mt-4">
                                            <FormGroup>
                                                <Form.Label>Issue Type:<i className="yellow ml-1">*</i></Form.Label>
                                                <Form.Control as="select" required name="issueType" ref={register}>
                                                    <option value="Billing">Billing</option>
                                                    <option value="Registration">Registration</option>
                                                    <option value="Class Technical Issues">Class Technical Issues</option>
                                                    <option value="Teacher Feedback">Teacher Feedback</option>
                                                    <option value="Other">Other</option>
                                                </Form.Control>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <FormGroup>
                                                <Form.Label>First Name<i className="yellow ml-1">*</i></Form.Label>
                                                <Form.Control type="text" required name="firstName" ref={register} />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <FormGroup>
                                                <Form.Label>Last Name <i className="yellow ml-1">*</i></Form.Label>
                                                <Form.Control type="text" required name="lastName" ref={register} />
                                            </FormGroup></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <FormGroup>
                                                <Form.Label>Email Address<i className="yellow ml-1">*</i></Form.Label>
                                                <Form.Control type="email" required name="email" ref={register} />
                                            </FormGroup>
                                            {(errors['email'] && errors['email'].type === "email") && <span className="alert">{errors['email'].message}</span>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group>
                                                <Form.Label>Message<i className="yellow ml-1">*</i></Form.Label>
                                                <Form.Control className="text-area"
                                                    required
                                                    type="textarea"
                                                    as="textarea"
                                                    rows="10"
                                                    name="message"
                                                    ref={register}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Group controlId="className" className="prop-checkbox d-flex justify-content-md-between flex-wrap flex-md-wrap">
                                                <Form.Check
                                                    required
                                                    className="custom-checkbox"
                                                    label='I am not a robot'
                                                    type='checkbox'
                                                    name="notRobot"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className="d-flex justify-content-end reverse"> *Required Field</Col>
                                    </Row>
                                    <Button
                                        className="btn-salmon btn-float"
                                        type="submit"
                                    >
                                        <Image className="btn-icon" src={FlexieIcon} />
                                         Submit
                                        </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <Container className="mb-5 text-center">
                {loading ? <Modal show={true} className="loading-modal text-center">
                    <Spinner animation="border" variant="primary" />
                </Modal> : ""}
            </Container>
            <Modal show={toggle} onHide={() => handleToggle(!toggle)}>
            <Modal.Body>{firebaseError ? firebaseError: 'support mail send successfully.'}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleToggle(!toggle)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Support;