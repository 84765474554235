import { useState, useEffect, useContext, useCallback } from 'react';
import lodash from 'lodash';
import moment from 'moment';

import Teacher from '../../../../firebase/model/teacher';
import { calculatePagination } from '../../../../utils/helper'
import Class from '../../../../firebase/model/Classes';
import ClassContext from '../../Main/Context';
useTeacherClasses.secondsLeft = 0;
useTeacherClasses.nextClassHappening = false;

function useTeacherClasses() {


    const { claims, ShowModal, currentUserRole } = useContext(ClassContext)
    const [teacherProfileDetails, setTeacherProfileDetails] = useState({});
    const [order, setOrder] = useState({ "upcoming": false, "previous": false })
    const [secondsLeft, setSecondsLeft] = useState(0)
    const [teacherUpcomingClasses, setTeacherUpcomingClasses] = useState(null);
    const [nextClassHappening, setNextClassHappening] = useState(false);
    const [nextClass, setNextClass] = useState({});
    const [upcomingClassesPages, setUpcomingClassesPages] = useState(null);
    const [previousClassesPages, setPreviousClassesPages] = useState(null);
    const [teacherPreviousClasses, setTeacherPreviousClasses] = useState(null);

    const fetchTeacherProfile = async () => {
        const teacherProfileDetails = await Teacher.getProfileByUserId(claims.user_id);
        setTeacherProfileDetails(teacherProfileDetails);
    }

    const fectchClassses = async () => {
        let upcomingClasses = await Class.getAllUpcomingClassesByTeacherWithAuth(claims.user_id);
        let previousClasses = await Class.getAllPreviousClassesByTeacherWithAuth(claims.user_id)
        setUpcomingClassesPages(calculatePagination(upcomingClasses, 5))
        setPreviousClassesPages(calculatePagination(previousClasses, 5))
        setTeacherUpcomingClasses(lodash.orderBy(upcomingClasses, 'date', 'asc'))
        setTeacherPreviousClasses(lodash.orderBy(previousClasses, 'date', 'desc'))

    }

    const handlePageChange = (index, list) => {
        let first = index * 5
        let last = first + 5
        if (list === 1) setUpcomingClassesPages({ ...upcomingClassesPages, first, last, currrentPage: index })
        else setPreviousClassesPages({ ...previousClassesPages, first, last, currrentPage: index })
    }

    const handleSort = (key, listOrder, list) => {
        if (list === "upcoming") setTeacherUpcomingClasses(lodash.orderBy(teacherUpcomingClasses, key, listOrder ? 'asc' : 'desc'));
        else setTeacherPreviousClasses(lodash.orderBy(teacherPreviousClasses, key, listOrder ? 'asc' : 'desc'))
        setOrder({ ...order, [list]: listOrder })
    }

    const deleteClass = async (classId) => {
        await Class.delete(classId);
        let upcomingClasses = teacherUpcomingClasses.filter(c => c.id !== classId)
        setTeacherUpcomingClasses(upcomingClasses);
    }

    const checkNextClassHappening = (classes) => {
            const nextClass = classes && classes.length && classes[0]
            if (nextClass) {
                let duration = moment.duration(moment(nextClass.date).diff(moment()));
                if (duration && duration.asMinutes() < 16) {
                    setNextClass(nextClass);
                    setNextClassHappening(true)
                    useTeacherClasses.nextClassHappening = true;
                    useTeacherClasses.secondsLeft =  duration.asSeconds()
                    setSecondsLeft(duration.asSeconds())
                    countDown();
                }
            }
        
    }

    const countDown = useCallback(() => {
        let interval = setInterval(() => {
            if (useTeacherClasses.secondsLeft == 0) {
                setNextClassHappening(false)
                useTeacherClasses.secondsLeft = 0
                setSecondsLeft(0);
                useTeacherClasses.nextClassHappening = false;
                clearInterval(interval);
                fectchClassses();
            } else {
                useTeacherClasses.secondsLeft = useTeacherClasses.secondsLeft - 1;
                setSecondsLeft(useTeacherClasses.secondsLeft)
            }
        }, 1000)
    })

    useEffect(() => {
        if (teacherUpcomingClasses &&
            teacherUpcomingClasses.length && claims && claims.teacher) {
               let interval =  setInterval(() => {
                    if(useTeacherClasses.secondsLeft == 0) checkNextClassHappening(teacherUpcomingClasses);
                    else clearInterval(interval); 
                }, 1000)
        }
    }, [teacherUpcomingClasses, nextClassHappening])


    useEffect(() => {
        if (claims && claims.teacher) {
            fetchTeacherProfile();
            fectchClassses();
        }
        return clearTeacherClassStoarge();
    }, [claims, currentUserRole]);

    const clearTeacherClassStoarge = () => {
        setTeacherProfileDetails({})
        setTeacherUpcomingClasses(null);
        setUpcomingClassesPages(null);
        setPreviousClassesPages(null);
        setTeacherPreviousClasses(null);
    }

    return {
        order,
        teacherProfileDetails, 
        teacherUpcomingClasses,
        teacherPreviousClasses,
        upcomingClassesPages,
        previousClassesPages,
        setTeacherProfileDetails,
        handlePageChange,
        clearTeacherClassStoarge,
        deleteClass,
        handleSort,
        ShowModal,
        fectchClassses,
        nextClassHappening,
        secondsLeft,
        nextClass
    }

}


export default useTeacherClasses;