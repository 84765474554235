import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom'
import { Navbar, Container, Row, Col, Image, Dropdown } from 'react-bootstrap';

import { FirebaseContext } from "../../../firebase";
import ClassContext from '../../../Containers/Views/Main/Context/index';
import Logo from '../../../img/Flexie-logo_FINAL.svg';
import GreenLogo from '../../../img/Flexie-logo_FINAL-green-alt.svg';
import { pageNames } from '../../../utils/helper';
import './Index.scss';

function Header() {
    const [toggle, toggleHandler] = useState(null)
    const [scrollPosition, setSrollPosition] = useState(0);
    const location = useLocation();
    const history = useHistory();
    const { authUser, claims, firebase, switchUser, currentUserRole, setCurrentUserRole } = React.useContext(FirebaseContext);
    const { handleBookDetails, clearStorage } = useContext(ClassContext);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setSrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        toggleHandler(false)
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location]);

    history.listen((location) => {
        const path = (location.pathname.split('/')).splice(0, 3).join('/')
        window.scrollTo(0, 0)
        document.title = pageNames[path]
    })

    const handleLogout = () => {
        handleBookDetails(null);
        clearStorage();
        setCurrentUserRole(null);
        firebase.logout();
    }

    const onSwitchUser = (e) => {
        const role = e.target.text;
        if(claims.email_verified && claims.profileComplete){
        switchUser(role);
        history.push(role === 'Student' ? '/student/dashboard'
        :  (claims.teacher?'/teacher/dashboard': '/student/upgrade-role'))
        }
    }

    let header = scrollPosition > 30 ?
        (toggle ? "top-header scrolled fix-menu-show" : "top-header scrolled ")
        : ((location.pathname !== '/' ?
            (toggle ? "top-header header-logo-left top-header-sub menu-open"
                : "top-header header-logo-left top-header-sub")
            : (toggle ? "top-header menu-open" : "top-header")))
    return (
        <header className={header}>
            <Navbar fixed="top"
                bg={authUser ? "" : "dark"}
                variant={!authUser || (claims && !!claims.teacher) ? "" : "nav-bg"}
                expand="lg"
            >
                <Container>
                    <Row className="no-gutters d-flex justify-content-between w-100">
                        <Col xs={4} className="text-center had-logo">
                            <Link to="/" className="p-0 d-inline-block">
                                <Image className="header-logo" src={toggle ? GreenLogo : Logo} />
                            </Link>
                        </Col>
                        <Col lg={6} className="d-lg-flex ml-auto text-right anonymous-menu order-3 order-lg-2">
                            {authUser ? (
                                <>
                                    <Col xs={12} lg={10} md={10} className="d-flex ml-0 ml-lg-auto text-right order-3 order-lg-2 teacher-menu">
                                        <div className="teacher-nav ml-auto mr-auto ml-lg-auto mr-lg-0 d-flex flex-row align-items-sm-center justify-content-between w-100 pr-1">
                                            <Dropdown className="nav-item">
                                                <Dropdown.Toggle id="dropdown-custom-1" variant="link" className="nav-link btn btn-info">
                                                    <Link>
                                                        {currentUserRole && currentUserRole === 'Teacher' ? 'Teacher' : 'Student'}</Link></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Link className="dropdown-item" onClick={onSwitchUser}>
                                                        {currentUserRole && currentUserRole === 'Teacher' ? 'Student' : 'Teacher'}</Link>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {currentUserRole && currentUserRole === 'Teacher' ?
                                                <ul className="d-flex justify-content-between w-100 mb-0 pl-1 teacher-nav-ul flex-wrap">
                                                    <li className="nav-item">
                                                        <Link className="active" to="/teacher/dashboard" data-toggle="tooltip" data-placement="bottom" title="Dashboard"><i className="fas fa-th fa-2x"></i></Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to="/teacher/classes" data-toggle="tooltip" data-placement="bottom" title="Class Manager"><i className="fas fa-calendar-alt fa-2x"></i></Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to="/teacher/dashboard" data-toggle="tooltip" data-placement="bottom" title="Earnings"><i className="fas fa-piggy-bank fa-2x"></i></Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to={"/teacher/dashboard"} data-toggle="tooltip" data-placement="bottom" title="Resources"><i className="fas fa-book fa-2x"></i></Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to={"/teacher/profile"} title="Profile"><i className="fas fa-user-circle fa-2x"></i></Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link onClick={handleLogout} to='/'><i className="fas fa-sign-out-alt fa-2x"></i></Link>
                                                    </li>
                                                </ul>
                                                :
                                                <ul className="d-flex justify-content-between w-100 mb-0 pl-1 teacher-nav-ul flex-wrap">
                                                    <li className="nav-item">
                                                        <Link className="active" to="/student/dashboard" data-toggle="tooltip" data-placement="bottom" title="Dashboard"><i className="fas fa-th fa-2x"></i></Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to="/class-finder" data-toggle="tooltip" data-placement="bottom" title="Class Manager"><i className="fas fa-search fa-2x"></i></Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to={"/student/profile"} title="Profile"><i className="fas fa-user-circle fa-2x"></i></Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link onClick={handleLogout} to='/'><i className="fas fa-sign-out-alt fa-2x"></i></Link>
                                                    </li>
                                                </ul>
                                            }
                                        </div>
                                    </Col>
                                </>
                            ) : (
                                    !claims && <>
                                        <ul id="not-logged-menu" className={toggle ? "d-flex flex-row ml-auto anonymous-nav menu-open" : "d-flex flex-row ml-auto anonymous-nav "}>
                                            <li>
                                                <Link className="nav-link" to='/login'>Log In</Link>
                                            </li>
                                            <li>
                                                <Link className="btn btn-info" to='/register'>Register</Link>
                                            </li>
                                        </ul>
                                    </>
                                )}
                        </Col>
                        <Col xs={2} className="text-center toggler-container order-2 order-lg-3">
                            <button className="navbar-toggle" type="button" data-target="#mainMenu" aria-controls="mainMenu" aria-expanded="false" aria-label="Toggle Main Menu" onClick={() => toggleHandler(!toggle)}>
                                {toggle ? <i className="fas fa-times yellow fa-2x"></i>
                                    : <i className="fas fa-bars yellow fa-2x"></i>}
                            </button>
                        </Col>
                    </Row>
                </Container>
            </Navbar>
            {toggle && <section id="main-menu" className={toggle ? "main-menu-container position-fixed show" : "main-menu-container position-fixed"}>
                <Container>
                    <Row className="menu-row">
                        <Col xs={12}>
                            <ul className="navbar-nav main-menu-items d-flex justify-content-center align-items-center">
                                <li className="nav-item"><Link className="nav-link active" to="/">home</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/class-finder">class finder</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/intro-teacher">teacher benefits</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/">student benefits</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/faq">FAQ</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/">about us</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/contact">contact us</Link></li>
                            </ul>
                        </Col>
                    </Row>
                    {!claims && <Row className="d-flex d-lg-none">
                        <Col className="col-auto ml-auto mr-auto">
                            <ul className="d-flex flex-row ml-auto anonymous-nav">
                                <li>
                                    <Link className="yellow" to='/login'>Log In</Link>
                                </li>
                                <li>
                                    <Link className="btn btn-info" to='/register'>Register</Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>}
                </Container>
            </section>
            }
        </header>
    );
}

export default Header;