import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import {Nav, Container, Row, Col, Image} from 'react-bootstrap';
import FooterLogo from '../../../img/Flexie-logo_FINAL-salmon.svg';

class Footer extends Component{
    render() {
        return(
            <section className="green-bg">
            <footer className="footer">
                <Container>
                    <Row>
                        <Col xs={12} md={6} className="order-1 order-md-2">
                            <div className="center mt-3 mb-4">
                                <Image className="img-fluid footer-logo" src={FooterLogo} />
                            </div>
                        </Col>
                        <Col xs={6} md={3}  className="order-2 order-md-1 pt-4">
                            <div className="d-flex">
                                <Nav.Link className="p-0" href="#"><i className="fab fa-instagram fa-2x"></i></Nav.Link>
                                <Nav.Link className="p-0 ml-3" href="#"><i className="fab fa-facebook-square fa-2x"></i></Nav.Link>
                            </div>
                            <p className="small mt-4">© 2020 Eleven11 LLC. All rights reserved.
                            Flexie Yoga is a trademark of Eleven11 LLC
                            Terms and conditions, features, support, pricing, and
                            service options subject to change without notice.</p>
                        </Col>
                        
                        <Col xs={6} md={3} className="col-6 col-md-3 order-3 order-md-3 footer-nav">
                            <ul className="mt-4">
                                <li><Link className="p-0" to='/support'>Support</Link></li>
                                <li><Link className="p-0" to='/careers'>Careers</Link></li>
                                <li><Link className="p-0" to='/press'>Press</Link></li>
                                <li><Link className="p-0" to='/legal'>Legal</Link></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </section>
        );
    }
}

export default Footer;