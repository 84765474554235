import React, { useState, useContext } from 'react';
import { Container, Row, Col, Card, FormGroup, Form, Image, Button, Nav, Accordion, Spinner } from 'react-bootstrap';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';
import { Link } from 'react-router-dom';
import lodash from 'lodash';

import TeacherThumb1 from '../../../../img/Teacher1_90x90px.jpg';
import ClassCard1 from '../../../../img/class-card_1.jpg';
import { FirebaseContext } from "../../../../firebase";
import { formatDuration } from '../../../../utils/helper';
import ClassContext from '../Context';
import { StarRatingComponent } from '../../../../Components/Common/startRating';
import BookModal from '../BookModal';

const ClassFinder = (props) => {

    const Level = ['Bigenners', 'Intermediate', 'Advanced'];
    const Type = ['Acro', 'Vinyasa', 'Vin'];
    const TeacherCertification = ['200hr', '500hr', '1000hr'];
    const Language = ['English', 'French', 'Spanish', 'Italian'];
    const { claims, currentUserRole } = useContext(FirebaseContext);

    const { filteredClasses, handleBookDetails,
        setFilteredClasses, classPages, setPagination,
        filters, setFilter, classRosters, handleBookClass } = useContext(ClassContext);

    const classIdLists = classRosters ? classRosters.map(cr => cr.classId) : [];
    const [collapseItem, handleCollapse] = useState({ 1: false })
    const [toggle, handleToggle] = useState(false);

    const handleItemCollapse = (e) => {
        const { id } = e.target
        handleCollapse({ ...collapseItem, [id]: !collapseItem[id] })
    }

    const handleSort = (key, order) => {
        setFilteredClasses(lodash.orderBy(filteredClasses, key, order === 'upcoming' ? 'desc' : 'asc'))
    }

    const handleFilterChange = (filter, value) => {
        if (filter === 'date')
            setFilter({ [filter]: value || new Date() })
        else setFilter({ [filter]: value })

    }

    const handlePageChange = (index) => {
        let first = index * 6
        let last = first + 6
        setPagination({ ...classPages, first, last, currrentPage: index })
    }

    const handleBooking = (classId, amount) => {
        handleBookDetails({ classId, amount: amount || 0 });
        if (claims && currentUserRole === 'Student') {
            if (amount && amount > 0)
                props.history.push('/student/payment');
            else handleBookClass({ classId, amount });
        }
        else handleToggle(!toggle)

    }

    const checkBookingDisabled = (c) => {
        return { bookText: c.canceled ? 'Cancelled': (classIdLists.includes(c.id)? 'Booked' : 'Book'),
                 disable:  Boolean(c.date < new Date() || classIdLists.includes(c.id) || c.canceled) }
    }

   
    return (
        <div className="class-finder-page">
            <div className="header-salmon-bg" />
            <section className="content white-bg">
                <Container className="mb-5">
                    <Row className="mt-4 mb-4">
                        <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                            <h1 className="green-alt large"><i className="fas fa-search mr-3" />Class Finder</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} lg={3}>
                            <h1 className="green">search criteria</h1>
                            <Card className="yellow-bg left-side-card">
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <FormGroup className="date-filter d-flex flex-column">
                                                <Form.Label className="green">Date</Form.Label>
                                                <DateTimePicker
                                                    value={(filters && filters.date ? filters.date : moment(moment(), 'MM/DD/YYYY').toDate())}
                                                    onChange={(value) => handleFilterChange('date', value)} />
                                            </FormGroup></Col>
                                    </Row>
                                    {/* <Row>
                                        <Col>
                                            <FormGroup className="date-filter date-filter-time d-flex flex-column">
                                                <Form.Label className="green">Time</Form.Label>
                                                <DateTimePicker />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <h5 className="sidebar-title">duration</h5>
                                            <div className="duration-time">
                                                <ul className="d-flex align-items-center">
                                                    <li>
                                                        <Nav.Link className="link-nav active" href="#">15</Nav.Link>
                                                    </li>
                                                    <li>
                                                        <Nav.Link className="link-nav" href="#">30</Nav.Link>
                                                    </li>
                                                    <li>
                                                        <Nav.Link className="link-nav" href="#">45</Nav.Link>
                                                    </li>
                                                    <li>
                                                        <Nav.Link className="link-nav" href="#">60</Nav.Link>
                                                    </li>
                                                    <li>
                                                        <Nav.Link className="link-nav" href="#">90+</Nav.Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h5 className="sidebar-title">Level</h5>
                                            <Form.Group className="checkbox-list" name="Level">
                                                {Level.map((prop, i) =>
                                                    <Form.Check
                                                        className="custom-checkbox"
                                                        key={i}
                                                        inline
                                                        label={prop}
                                                        type='checkbox'
                                                        id={prop}
                                                        name="Level"
                                                        value={prop} />)}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Accordion defaultActiveKey="0" className="mb-4">
                                                <Accordion.Toggle id={1} as={Card.Header} eventKey="0" className="sidebar-title" onClick={handleItemCollapse}>
                                                    Type
                                                        <i id={1} className={collapseItem[1] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Form.Group className="checkbox-list" name="Type">
                                                        {Type.map((prop, i) =>
                                                            <Form.Check
                                                                className="custom-checkbox"
                                                                inline
                                                                key={i}
                                                                inline
                                                                label={prop}
                                                                type='checkbox'
                                                                id={prop}
                                                                name="Type"
                                                                value={prop} />)}
                                                    </Form.Group>
                                                </Accordion.Collapse>
                                            </Accordion>

                                        </Col></Row>
                                    <Row> <Col>
                                        <Accordion defaultActiveKey="0" className="mb-4">
                                            <Accordion.Toggle id={2} as={Card.Header} eventKey="0" className="sidebar-title" onClick={handleItemCollapse}>
                                                Teacher
                                                    <i id={2} className={collapseItem[2] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                                <Form.Control type="text" name="teacherName" />
                                            </Accordion.Collapse>
                                        </Accordion>

                                    </Col></Row>
                                    <Row>
                                        <Col>
                                            <Accordion defaultActiveKey="0" className="mb-4">
                                                <Accordion.Toggle id={3} as={Card.Header} eventKey="0" className="sidebar-title" onClick={handleItemCollapse}>
                                                    Teacher Certification
                                                        <i id={3} className={collapseItem[3] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Form.Group className="checkbox-list" name="teacherCertification">
                                                        {TeacherCertification.map((prop, i) =>
                                                            <Form.Check
                                                                className="custom-checkbox"
                                                                inline
                                                                key={i}
                                                                inline
                                                                label={prop}
                                                                type='checkbox'
                                                                id={prop}
                                                                name="Type"
                                                                value={prop} />)}
                                                    </Form.Group>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Accordion defaultActiveKey="0" className="mb-4">
                                                <Accordion.Toggle id={4} as={Card.Header} eventKey="0" className="sidebar-title" onClick={handleItemCollapse}>
                                                    Teacher Rating
                                                        <i id={4} className={collapseItem[4] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Form.Group className="rating-fill-start" name="teacherCertification">
                                                        <StarRatingComponent value={4} size={'1x'} />
                                                    </Form.Group>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Accordion defaultActiveKey="0" className="mb-4">
                                                <Accordion.Toggle id={5} as={Card.Header} eventKey="0" className="sidebar-title" onClick={handleItemCollapse}>
                                                    Price
                                                        <i id={5} className={collapseItem[5] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <div className="price-min-max">
                                                        <Form.Group className="" name="teacherCertification">
                                                            <Form.Label>Min</Form.Label>
                                                            <Form.Control type="text" name="price" />
                                                        </Form.Group>
                                                        <span>to</span>
                                                        <Form.Group>
                                                            <Form.Label>Max</Form.Label>
                                                            <Form.Control type="text" name="teacherName" />
                                                        </Form.Group>
                                                    </div>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                    <Row> 
                                        <Col>
                                            <Accordion defaultActiveKey="0" className="mb-4">
                                                <Accordion.Toggle id={6} as={Card.Header} eventKey="0" className="sidebar-title" onClick={handleItemCollapse}>
                                                    Language
                                                        <i id={6} className={collapseItem[6] ? "icon-plus fas fa-plus-circle" : "icon-minus fas fa-minus-circle"}></i>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Form.Group className="checkbox-list" name="teacherCertification">
                                                        {Language.map((prop, i) =>
                                                            <Form.Check
                                                                className="custom-checkbox"
                                                                inline
                                                                key={i}
                                                                inline
                                                                label={prop}
                                                                type='checkbox'
                                                                id={prop}
                                                                name="language"
                                                                value={prop} />)}
                                                    </Form.Group>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        </Col>
                                    </Row>*/}
                                </Card.Body>
                                <Button variant="secondary" className="btn btn-float" onClick={() => setFilter({ 'none': 'none' })}>
                                    <i className="fas fa-redo icon-btn"></i> Reset
                                </Button>
                            </Card>
                        </Col>
                        <Col xs={12} md={8} lg={9}>
                            <div className="d-flex justify-content-between middle-head flex-lg-row flex-column mt-5 mt-md-0">
                                <span>
                                    <h1 className="salmon big mb-0">{filters.date ? moment(filters.date).format('MMMM Do, h:mm a'):'Upcoming Classes'}</h1>
                                    <p className="green">{`Found: ${filteredClasses ? filteredClasses.length : 0} 
                                     ${filteredClasses && filteredClasses.length > 1 ? 'classes' : 'class'}  that meets your criteria`}</p>
                                </span>
                                {/* <div className="sortby-dropdown">
                                    <span className="d-block mb-2">Sort by:</span>
                                    <Form.Group controlId="exampleForm.SelectCustom">
                                        <Form.Control as="select" custom name="Sort by:" onChange={({ target: { value } }) => handleSort('date', value)}>
                                            <option value="upcoming" >Upcoming</option>
                                            <option value="previous" >Previous</option>
                                        </Form.Control>
                                    </Form.Group>
                                </div> */}
                            </div>
                            {/* <Card className="full-detail-card card-shadow">
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} lg={7} xl={8}>
                                            <div className="card-img">
                                                <Image src={ClassCard1} />
                                                <div className="tag-img">
                                                    <div className="img">
                                                        <Image src={TeacherThumb1} />
                                                    </div>
                                                    <div className="tag-link d-flex">
                                                        <i className="fas fa fa-user green-alt mr-2 mt-1" />
                                                        <div className="name-link">
                                                            <span>Lorem Ipsum</span>
                                                            <div className="rating-link-row">
                                                                <StarRatingComponent value={4} size={'1x'} editing={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={5} xl={4}>
                                            <div className="detail-right">
                                                <div className="creative-flow-content">
                                                    <h4 className="green">Quick Yoga</h4>
                                                    <p className="green">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                                        </p>
                                                    <div className="flow-content-list">
                                                        <i className="fas fa-calendar-alt green-alt" />
                                                        <strong className="green">Time: </strong>
                                                        <span className="green">June 4, 2020 2:00PM EDT</span>
                                                    </div>
                                                    <div className="flow-content-list">
                                                        <i className="fas fa fa-signal green-alt" />
                                                        <strong className="green">Difficulty: </strong>
                                                        <span className="green">Intermediate</span>
                                                    </div>
                                                    <div className="flow-content-list">
                                                        <i className="fas fa-clock green-alt" />
                                                        <strong className="green">Duration: </strong>
                                                        <span className="green">20 min</span>
                                                    </div>
                                                    <div className="flow-content-list">
                                                        <i className="fas fa-comment green-alt" />
                                                        <strong className="green">Language: </strong>
                                                        <span className="green">English</span>
                                                    </div>
                                                    <div className="flow-content-list">
                                                        <i className="fas fa-dollar-sign green-alt" />
                                                        <strong className="green">Price: </strong>
                                                        <span className="green">$5</span>
                                                    </div>
                                                </div>
                                                <div className="detail-btn mt-3 d-flex justify-content-center">
                                                    <Link to="/teacher/dashboard" className="btn btn-secondary mb-2 mb-md-0 mr-0 mr-md-2 d-flex align-items-center">
                                                        <i className="fas fa-list" />
                                                                Details
                                                            </Link>
                                                    <Link to="/teacher/dashboard" className="btn btn-salmon btn-secondary d-flex align-items-center">
                                                        <i className="fas fa-calendar-plus" />
                                                                Book
                                                            </Link>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card> */}
                            <Container className="mt-5 px-0 more-detail-list-block">
                                <Row>
                                    {(filteredClasses ? (filteredClasses.length ? filteredClasses.slice(classPages.first, classPages.last).map((c, i) =>
                                        <Col xs={12} lg={6} xl={4} key={i}>
                                            <Card className="custom-card card-shadow mb-5">
                                                <Card.Header>
                                                    <Card.Img variant="top" src={c.c.classImage} />
                                                    <div className="default-image-profile auth-user">
                                                        {c.t.profileImage ? <Image src={c.t.profileImage} className="img-fluid rounded-circle salmon-border small-border" />
                                                            : <i class="fas fa-user fa-3x reverse mt-4"></i>}
                                                    </div>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Card.Title className="center green">{c.c.className}</Card.Title>
                                                    <ul className="fa-ul">
                                                        <li className="green"><span className="fa-li"><i className="fas fa-calendar-alt green-alt"></i></span> <strong>Time: </strong>{c.c.date && moment(c.c.date).format('MMMM Do, h:mm a')}</li>
                                                        <li className="green">
                                                            <span className="fa-li"><i className="fas fa-user green-alt"></i></span>
                                                            <strong>Instructor: </strong>
                                                            <div className="link-rating d-flex flex-column">
                                                                <Link className="p-0 d-inline-block" to={`/profile/teacher/${c.t.id}`}>{c.t.fullName && c.t.fullName}</Link>
                                                                <div className="rating-link-row">
                                                                    <StarRatingComponent size={'1x'}
                                                                        value={4} />
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="green"><span className="fa-li"><i className="fas fa-signal green-alt"></i></span> <strong>Difficulty: </strong> {c.c.difficultyLevel}</li>
                                                        <li className="green"><span className="fa-li"><i className="fas fa-clock green-alt"></i></span> <strong>Duration: </strong> {formatDuration(c.c.duration)}</li>
                                                        <li className="green"><span className="fa-li"><i className="fas fa-dollar-sign green-alt"></i></span> <strong>Price: </strong>{c.c.price ? c.c.price : '---'} </li>
                                                    </ul>
                                                    <div className="btn-float d-flex align-items-center justify-content-center w-100">
                                                        <Button variant="primary" className="btn-secondary mr-2 d-flex justify-content-center align-items-center"> <i className="fas fa-list mr-2" /><Link className="reverse" to={`/class/details/${c.c.id}`}>Details</Link></Button>
                                                        <Button variant="primary"
                                                            className="btn-salmon d-flex justify-content-center align-items-center"
                                                            onClick={() => handleBooking(c.c.id, c.c.price)}
                                                            disabled={checkBookingDisabled(c.c).disable}
                                                        >
                                                           {!c.c.canceled ? <i className="fas fa-calendar-plus mr-2" /> : <i className="fas fa-times mr-2" />}
                                                           {checkBookingDisabled(c.c).bookText}
                                                        </Button>


                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>) : <div className="text-center green large">No Classes found</div>)
                                        :
                                        <Col className="text-center spinner-block justify-content-center">
                                            <Spinner animation="border" variant="primary" />
                                        </Col>)}
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="jump-nav mt-24 d-flex align-items-center justify-content-end">

                                            {classPages && classPages.totalPages > 1 ?
                                                <div className="custom-pagination d-flex justify-content-right green">
                                                    <span>Jump to:</span>
                                                    <ul className="d-flex align-items-center pl-3">
                                                        <span className={classPages.currrentPage <= 0 ? "ml-2 disabled" : "ml-2"} onClick={() => handlePageChange(classPages.currrentPage - 1, 1)} >{'<'} </span>
                                                        {classPages.pages.map((i) => <li key={i}>
                                                            <span className={classPages.currrentPage === i ? "link-nav active" : "link-nav"} onClick={() => handlePageChange(i, 1)} >{`${i * 6 + 1} - ${i * 6 + 6}`} </span>
                                                        </li>)}
                                                        <span className={classPages.currrentPage === classPages.totalPages - 1 ? "ml-2 disabled" : "ml-2"} onClick={() => handlePageChange(classPages.currrentPage + 1, 1)} >{'>'}</span>
                                                    </ul>
                                                </div>
                                                : ""}
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    {(toggle && !claims) && <BookModal
                        {...props}
                        toggle={toggle}
                        handleToggle={handleToggle} />}
                </Container>
            </section>
        </div>
    );
}


export default ClassFinder;