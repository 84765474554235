import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import Routers from "../Router";
import useAuth from "../Components/Auth/useAuth";
import useClass from '../Containers/Views/Main/Context/useClass';
import ClassContext from '../Containers/Views/Main/Context';
import firebase, { FirebaseContext } from "../firebase";
import './App.scss';


function App() {
  const authProps = useAuth();
  const classProps  = useClass();
  return (
    <BrowserRouter>
      <FirebaseContext.Provider value={{ ...authProps, firebase }}>
        {/* <Header /> */}
        <ClassContext.Provider value={{ ...classProps }}>
        <div className="App">
          <Routers />
        </div>
        </ClassContext.Provider>
        {/* <Footer /> */}
      </FirebaseContext.Provider>
    </BrowserRouter>

  );  
}

export default App;