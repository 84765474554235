import React, { useState } from "react";
import { Spinner, Modal, Container, Row, Col, Button, Form, Image } from 'react-bootstrap';
import FlexieIcon from '../../../img/FlexieIcon-reverse.svg';
import FirebaseContext from "../../../firebase/context";

function PasswordRecovery() {
    const { firebase } = React.useContext(FirebaseContext);
    const [recoveryEmail, setRecoveryEmail] = useState('');
    const [isRecoverySent, setIsRecoverySent] = useState(false);
    const [recoveryError, setRecoveryError] = useState(null);
    const [loading, setLoadingState] = useState(false);

    async function handlePasswordRecovery(e) {
        e.preventDefault();
        setRecoveryError(null);
        setLoadingState(!loading);
        try {
            await firebase.resetPassword(recoveryEmail);
            setIsRecoverySent(true);
            setLoadingState(false);
        } catch ({ response }) {
            setLoadingState(false);
            let errMessage = response ? response.data.error : "error while trying to reset password try again"
            setRecoveryError(errMessage);
            setIsRecoverySent(false);   
        }
    }

    return (

        <div className="login-page">
            <div className="login-header-bg" />
            {loading ? <Modal show={true} className="loading-modal text-center">
                <Spinner animation="border" variant="primary" />
            </Modal> : ""}
            <Container>
                <Form onSubmit={handlePasswordRecovery}>
                    <Row className="mt-4 mb-4">
                        <Col xs={12} className="yellow-spot-bg left-spot small-spot alt-spot">
                            <h1 className="green"> forgot password</h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mb-5">
                        <Col xs={12} lg={8} className="mb-5" >
                            <div className="rounded-40 green-alt-bg pr-5 pl-5 text-center card-body mb-5">
                                <Form.Group className="row pt-lg-3">
                                    <Form.Label className=" col-sm-4 col-lg-3 col-form-label green">Email Address</Form.Label>
                                    <Col sm={8} lg={9}>
                                        <Form.Control
                                            type="email"
                                            onChange={event => setRecoveryEmail(event.target.value)}
                                            name="email"
                                            required
                                            autoComplete="off"
                                        />
                                    </Col>
                                </Form.Group>
                                {isRecoverySent && <p> An email has been sent to the provided email address to reset your password!</p>}
                                {recoveryError && <p className="alert">{recoveryError}</p>}
                                <Button
                                    className="btn btn-salmon btn-float"
                                    type="submit"
                                    variant="info"
                                    disabled={handlePasswordRecovery}
                                >
                                    <Image className="btn-icon" src={FlexieIcon} />
                   Reset Password
                  </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    )
}

export default PasswordRecovery;