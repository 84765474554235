import React, { useState, useEffect, useContext } from "react";
import { render } from 'react-dom';
import { Modal, Button } from 'react-bootstrap';
import lodash from 'lodash';
import Class from '../../../../firebase/model/Classes';
import ClassRoster from '../../../../firebase/model/classRoster';
import { calculatePagination, classBookMessage } from '../../../../utils/helper'

function useClass() {
    
    const classDetails = localStorage.getItem('classDetails') ? JSON.parse(localStorage.getItem('classDetails')) : null
    const [classDetail, setClass] = useState(classDetails);
    const [filteredClasses, setFilteredClasses] = useState(null);
    const [upcomingClasses, setUpcomingClassses] = useState(null);
    const [classPages, setPagination] = useState(null)
    const [readyToJoinClass, setReadyToJoinClass]  = useState(null);
    const [filters, setFilter] = useState({ 'upcoming': 'upcoming' });
    const [classRosters, setClassRosters] = useState([]);
    const [ selectorValue, setSelectorValue ] = useState({classValue: 1,  priceValue: 5, studentValue: 1});
    const claims = localStorage.getItem('claims') && JSON.parse(localStorage.getItem('claims'));
    const currentUserRole  = localStorage.getItem('currentUserRole')

    const handleBookDetails = (classDetail) => {    
        setClass(classDetail);
        localStorage.setItem('classDetails', JSON.stringify(classDetail));
    }

    const handleBookClass = async (classDetail) => {
        const userId = claims? claims.user_id: classDetail.userId;
        await ClassRoster.add({ ...classDetail, userId })
        ShowModal(classBookMessage);
        handleBookDetails(null);
        localStorage.removeItem('classDetails');
        fetchClassRosters(userId);
    }

    const handleSelector = (value, criteria) => {
        setSelectorValue({ ...selectorValue, [criteria]: value  })
    }

    const fetchClasses = async (filter) => {
        let filteredClasses = [];
        if (filter === 'date')
            filteredClasses = await Class.getAllClassesByDateWithTeacherDetails(filters.date);
        else{
            filteredClasses = await Class.getAllClassesWithTeacherDetails(filters.date);
            setUpcomingClassses(filteredClasses);
        }
        let c = calculatePagination(filteredClasses, 6);
        setPagination(calculatePagination(filteredClasses, 6));
        setFilteredClasses(lodash.orderBy(filteredClasses, [(filteredClasses) => filteredClasses.c.date], 'asc'));
    }

    const fetchClassRosters = async (id) => {
        const classRosters = await ClassRoster.getAllClassRosterByUserId(id);
        setClassRosters(classRosters);
    }

    const handleModal = () => {
        const node = document.getElementsByName('message-modal')
        node[0].parentNode.classList.remove('modal-open')
        node[0].parentNode.removeAttribute('style');
        node[0].parentNode.removeChild(node[0]);
        const childNode = document.getElementsByClassName('fade modal show')
        childNode[0].parentNode.removeChild(childNode[0]);
        const childNodeBackDrop = document.getElementsByClassName('fade modal-backdrop show')
        childNodeBackDrop[0].parentNode.removeChild(childNodeBackDrop[0]);
    }

    const dispatchModal = (message) => {
        const containerDomNode = document.createElement('div')
        containerDomNode.setAttribute('name', 'message-modal')
        document.body.appendChild(containerDomNode)
        render(
            <Modal show={true} onHide={() => handleModal()}>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleModal()}>
                    Okay
             </Button>
            </Modal.Footer>
        </Modal>,
          containerDomNode
        )
        return null
      }
      
    const ShowModal = (message) => dispatchModal(message);

    const clearStorage = () => {
        setFilteredClasses(null);
        setPagination(null);
        setFilter({ 'upcoming': 'upcoming' });
        setClassRosters(null);
        setUpcomingClassses(null);
    }

    useEffect(() => {
        fetchClasses(Object.keys(filters)[0]);
        claims && currentUserRole === 'Student' && fetchClassRosters(claims.user_id);    
    }, [filters, currentUserRole]);

    return {
        claims,
        handleBookDetails,
        classDetail,
        setFilteredClasses,
        filteredClasses,
        classPages,
        setPagination,
        setFilter,
        filters,
        setClass,
        classRosters,
        setClassRosters,
        handleBookClass,
        clearStorage,
        ShowModal,
        selectorValue,
        handleSelector,
        upcomingClasses,
        currentUserRole,
        setReadyToJoinClass,
        readyToJoinClass
    };
}

export default useClass;