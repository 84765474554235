import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Form, Container, Row, Col, Modal, Spinner } from 'react-bootstrap';
import * as yup from "yup";
import MaskedInput from 'react-text-mask';
import { formatStudentRegisterValues } from '../../../../utils/helper';
import mask from '../../../../utils/mask';
import firebase, { FirebaseContext } from "../../../../firebase";
import { ReactComponent as FlexieLogo_green_alt } from '../../../../img/Flexie-logo_FINAL-green-alt.svg';
import { ReactComponent as FlexieIcon } from '../../../../img/FlexieIcon.svg';
import { ReactComponent as FlexieIcon_reverse } from '../../../../img/FlexieIcon-reverse.svg';


export default function UpgradeRole(props) {

    const schemaWithPayment = yup.object().shape({
        inputYear: yup.string().required('expiration year is required'),
        inputMonth: yup.string().required('expiration month is required'),
        cvvNumber: yup.number().required('Enter cvv Number'),
        zip: yup.number().required("Zip Code is required"),
        ccNumber: yup.number().required('Enter credit card Number')
    });

    const { authUser, setClaims, claims } = useContext(FirebaseContext);
    const { register, handleSubmit, watch, errors, control, reset } = useForm({
        validationSchema: schemaWithPayment
    });

    const [firebaseError, setFirebaseError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ plan, setPlan] = useState("");
    const [toggle, handleToggle] = useState(false)

    async function onSubmit(data) {
        try {
            setLoading(!loading);
            let result = formatStudentRegisterValues({...data, plan, email: authUser.email});
            await firebase.upgradeToTeacherRole(result)
                .then(async ({ data }) => {
                    if (data) {
                        await firebase.auth.currentUser.getIdToken(true)
                        setLoading(false);
                        setClaims({...claims, ...data})
                        setFirebaseError(null);
                        handleToggle(!toggle)
                        reset();
                    }
                })
        } catch (err) {
            let message = !err.response ? err.message :
                ((err.response.data && err.response.data.error)
                    ? err.response.data.error
                    : "Error while upgrading user try again")
            setLoading(false);
            setFirebaseError(message);
            handleToggle(!toggle)
        }
    }

    const handleDialogClose = () => {
        handleToggle(!toggle)
        props.history.push('/student/dashboard')
    }

    return (
        <div className="body-header-space">
            <Container className="mb-4">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={10} lg={8}>
                            <div className="card green-bg mt-3">
                                <div className="card-body pb-1">
                                    <h4 className="yellow">Step 1: Pick your plan</h4>
                                    <Row className="row-eq-height">
                                        <Col xs={12} sm={6}>
                                            <div className={`card ${plan === 'byo' ? 'yellow-bg':'green-bg green-alt-border'} h-100`}>
                                                <Row className="card-body d-flex flex-row row-eq-height justify-content-center align-items-center">
                                                    <Col xs={6}>
                                                        <h2 className="green-alt text-center w-100">BYO</h2>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <FlexieLogo_green_alt className="img-fluid" />
                                                    </Col>
                                                    <button type="button" className="btn btn-info btn-float green select-btn" onClick={() => setPlan('byo')} >
                                                        <FlexieIcon className="btn-icon" />Select</button>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <div className={`card ${plan === 'full setup' ? 'yellow-bg':'green-bg green-alt-border'} mt-5 mt-sm-0 h-100`}>
                                                <Row className="card-body d-flex flex-row row-eq-height justify-content-center align-items-center">
                                                    <Col xs={6}>
                                                        <h2 className="green-alt text-center w-100">Full Setup</h2>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <FlexieLogo_green_alt className="img-fluid" />
                                                    </Col>
                                                  <button type="button" className="btn btn-info btn-float green select-btn" onClick={() => setPlan('full setup')}>
                                                        <FlexieIcon className="btn-icon" />Select</button>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Form.Group className="row mt-5">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label mt-3 mt-sm-0">Referral Code</Form.Label>
                                        <Col sm={8} lg={9}>
                                            <Form.Control
                                                name="referalCode"
                                                type="text"
                                                placeholder="Plan"
                                                autoComplete="off"
                                                ref={register}>
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} sm={10} lg={8}>
                            <div class="card salmon-bg mt-3">
                                <div class="card-body">
                                    <h4 class="green">Step 2: Payment Info</h4>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Credit Card #</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <Controller
                                                as={
                                                    <MaskedInput
                                                        name="ccNumber"
                                                        className="form-control"
                                                        mask={mask}
                                                    />}
                                                control={control}
                                                name="ccNumber" />
                                            {(errors['ccNumber'] && errors['ccNumber'].type === "required") && <span className="alert">Enter a credit card number</span>}
                                            {(errors['ccNumber'] && errors['ccNumber'].type === "typeError") && <span className="alert">Please enter valid credit card number</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Exp. Date</Form.Label>
                                        <Col sm={4} lg={3}>
                                            <Controller
                                                as={
                                                    <Form.Control
                                                        required
                                                        name="inputMonth"
                                                        as="select"
                                                    >
                                                        <option>Month</option>
                                                        <option>01</option>
                                                        <option>02</option>
                                                        <option>03</option>
                                                        <option>04</option>
                                                        <option>05</option>
                                                        <option>06</option>
                                                        <option>07</option>
                                                        <option>08</option>
                                                        <option>09</option>
                                                        <option>10</option>
                                                        <option>11</option>
                                                        <option>12</option>
                                                    </Form.Control>
                                                }
                                                name="inputMonth"
                                                control={control}
                                            />
                                        </Col>
                                        <Col sm={4} lg={3} className="mt-3 mt-sm-0">
                                            <Controller
                                                as={
                                                    <Form.Control
                                                        required
                                                        name="inputYear"
                                                        as="select"
                                                    >
                                                        <option selected>Year</option>
                                                        <option>2020</option>
                                                        <option>2021</option>
                                                        <option>2022</option>
                                                        <option>2023</option>
                                                        <option>2024</option>
                                                        <option>2025</option>
                                                        <option>2026</option>
                                                        <option>2027</option>
                                                        <option>2028</option>
                                                        <option>2029</option>
                                                    </Form.Control>
                                                }
                                                name="inputYear"
                                                control={control}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Col sm={4} lg={3} />
                                        <Col className="mt-3 mt-sm-0">{((errors['inputMonth'] || errors['inputYear'])
                                            && ((errors['inputYear'] && errors['inputYear'].type === "required") || (errors['inputMonth'] && errors['inputMonth'].type === "required"))) && <span className="alert">please select date of credit card expiration</span>}</Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">CVV <i className="fas fa-question-circle" data-toggle="tooltip" data-placement="top"></i></Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <Form.Control
                                                required
                                                name="cvvNumber"
                                                type="text"
                                                ref={register}
                                            />
                                            {(errors['cvvNumber'] && errors['cvvNumber'].type === "typeError") && <span className="alert">Enter valid cvv number</span>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="row">
                                        <Form.Label className="col-sm-4 col-lg-3 col-form-label">Billing Zip Code</Form.Label>
                                        <Col sm={8} lg={9} className="col-sm-8 col-lg-9">
                                            <Form.Control
                                                required
                                                name="zip"
                                                type="text"
                                                placeholder="Zip Code"
                                                autoComplete="off"
                                                ref={register}
                                            />
                                            {(errors['zip'] && errors['zip'].type === "typeError") && <span className="alert">Enter valid zip code</span>}
                                        </Col>
                                    </Form.Group>
                                    <Button type="submit" className="btn-float">
                                        <FlexieIcon_reverse className="btn-icon" />Submit
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
                {loading ? <Modal show={true} className="loading-modal text-center">
                    <Spinner animation="border" variant="primary" />
                </Modal> : ""}
            </Container>
            <Modal show={toggle} onHide={() => handleToggle(!toggle)}>
                <Modal.Body>{firebaseError ? firebaseError : 'upgrade to teacher role done successfully.'}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={firebaseError ? () => handleToggle(!toggle) : handleDialogClose}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

