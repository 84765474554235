import React from 'react'
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'

//Layout components
import Main from '../Containers/Layouts/Main';
import Student from '../Containers/Layouts/Student';
import Teacher from '../Containers/Layouts/Teacher';

//Auth components
import Login from '../Components/Auth/Login';
import PasswordRecovery from '../Components/Auth/PasswordRecovery';
import Register from '../Components/Auth/Register';
import StudentRegistration from '../Components/Auth/StudentRegistration';
import TeacherRegistration from '../Components/Auth/TeacherRegistration';

//Main components
import PublicRoute from './publicRoute';
import About from '../Containers/Views/Main/About';
import AccountVerification from '../Containers/Views/Main/AccountVerification';
import Careers from '../Containers/Views/Main/Careers';
import ClassFinder from '../Containers/Views/Main/ClassFinder';
import Contact from '../Containers/Views/Main/Contact';
import FAQ from '../Containers/Views/Main/FAQ';
import HomePage from '../Containers/Views/Main/HomePage';
import IntroStudent from '../Containers/Views/Main/IntroStudent';
import IntroTeacher from '../Containers/Views/Main/IntroTeacher';
import Legal from '../Containers/Views/Main/Legal';
import Press from '../Containers/Views/Main/Press';
import Support from '../Containers/Views/Main/Support';
import TeacherProfile from '../Containers/Views/Main/TeacherProfile';
import ClassDetails from '../Containers/Views/Main/ClassDetails';
import TeachersList from '../Containers/Views/Main/TeacherList';


//Student components
import UnverifiedUserRoute from './unverifiedUserRoute';
import StudentRoute from './studentRoute';
import PaymentRoute from './paymentRoute';
import AccountProfileStudent from '../Containers/Views/Student/AccountProfile';
import DashboardStudent from '../Containers/Views/Student/Dashboard';
import Payment from '../Containers/Views/Student/Payment'
import useStudentClasses from '../Containers/Views/Student/Context/useStudentClasses';
import StudentClassContext from '../Containers/Views/Student/Context';
import UpgradeRole from '../Containers/Views/Student/UpgradeRole';

//Teacher components
import TeacherRoute from './teacherRoute';
import AccountProfileTeacher from '../Containers/Views/Teacher/AccountProfile';
import Billing from '../Containers/Views/Teacher/Billing';
import ClassAttendance from '../Containers/Views/Teacher/ClassAttendance';
import ClassBuilder from '../Containers/Views/Teacher/ClassBuilder';
import DashboardTeacher from '../Containers/Views/Teacher/Dashboard';
import Resources from '../Containers/Views/Teacher/Resources';
import ClassList from '../Containers/Views/Teacher/ClassList';
import TeacherClassDetails from '../Containers/Views/Teacher/ClassDetails';
import TeacherClassContext from '../Containers/Views/Teacher/Context';
import useTeacherClasses from '../Containers/Views/Teacher/Context/useTeacherClasses';
import { pageNames } from '../utils/helper';

export default () => {  
    const studentClassProps  = useStudentClasses();
    const teacherClassProps = useTeacherClasses();
    const location = useLocation();
    const path =  (location.pathname.split('/')).splice(0, 3).join('/')
    document.title = pageNames[path];  
   
    return (
        <Router>
            <Switch>
                <Route path='/student/:path?' exact>
                <StudentClassContext.Provider value={{ ...studentClassProps }}>
                    <Student>
                        <Switch>
                            <UnverifiedUserRoute path='/student/profile' exact component={AccountProfileStudent} />
                            <StudentRoute path='/student/dashboard' exact component={DashboardStudent} />
                            <PaymentRoute path="/student/payment" exact component={Payment} />
                            <StudentRoute path="/student/upgrade-role" exact component={UpgradeRole}/>
                        </Switch>
                    </Student>
                    </StudentClassContext.Provider>
                </Route>

                <Route path='/teacher/:path?/:id?' exact>
                <TeacherClassContext.Provider value={{ ...teacherClassProps }}>
                    <Teacher>
                        <Switch>
                            <UnverifiedUserRoute path='/teacher/profile' exact component={AccountProfileTeacher} />
                            <TeacherRoute path='/teacher/billing' exact component={Billing} />
                            <TeacherRoute path='/teacher/classes' exact component={ClassList} />
                            <TeacherRoute path='/teacher/classes/:id' exact component={TeacherClassDetails} />
                            <TeacherRoute path='/teacher/class-attendance' exact component={ClassAttendance} />
                            <TeacherRoute path='/teacher/class-builder' exact component={ClassBuilder} />
                            <TeacherRoute path='/teacher/class-builder/:id' component={ClassBuilder} />
                            <TeacherRoute path='/teacher/dashboard' exact component={DashboardTeacher} />
                            <TeacherRoute path='/teacher/resources' exact component={Resources} />
                        </Switch>
                    </Teacher>
                    </TeacherClassContext.Provider>
                </Route>
                <Route path="/:path?/:id?/:id1?">
                    <Main>
                        <Switch>
                            <Route path="/about" exact component={About} />
                            <Route path="/account-verification" exact component={AccountVerification} />
                            <Route path="/careers" exact component={Careers} />
                            <Route path="/teachers" exact component={TeachersList} />
                            <Route path="/profile/teacher/:id" exact component={TeacherProfile} />
                            <Route path="/class/details/:id" exact component={ClassDetails} />
                            <Route path="/class-finder" exact component={ClassFinder} />
                            <Route path="/contact" exact component={Contact} />
                            <Route path="/faq" exact component={FAQ} />
                            <PublicRoute path="/" exact component={HomePage} />
                            <Route path="/intro-student" exact component={IntroStudent} />
                            <Route path="/intro-teacher" exact component={IntroTeacher} />
                            <Route path="/legal" exact component={Legal} />
                            <PublicRoute path="/login" exact component={Login} />
                            <PublicRoute path="/password-recovery" exact component={PasswordRecovery} />
                            <Route path="/press" exact component={Press} />
                            <PublicRoute path="/register" exact component={Register} />
                            <PublicRoute path="/student-registration" exact component={StudentRegistration} />
                            <PublicRoute path="/teacher-registration" exact component={TeacherRegistration} />
                            <Route path="/support" exact component={Support} />
                        </Switch>
                    </Main>
                </Route>

            </Switch>
        </Router>
    )
}