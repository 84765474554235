
import React, { useState } from 'react' 
import { Modal, Col, Row, Container, Button, Image } from 'react-bootstrap';
import ButtonImage from '../../../../img/FlexieIcon-secondary.svg';


const BookModal = (props) => {
    const {toggle, handleToggle} = props;

    return  <Modal show={toggle} onHide={() => handleToggle(!toggle)} centered>
        <Modal.Header closeButton />
        <Modal.Body>
            <Container className="text-center" >
                <Row className="mt-2 mb-2">
                    <Col xs={12}>
                        <h5 className="green"> Login to your account to book class </h5>
                    </Col>
                    <Col xs={12}>
                        <Button
                            className="salmon-bg btn-float"
                            type="submit"
                            variant="info"
                            onClick={() => props.history.push('/login')}
                        >
                            <Image className="btn-icon" src={ButtonImage} />
                     Login
          </Button>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={12}>
                        <h5 className="green">Or register here to create your account</h5>
                    </Col>
                    <Col xs={12}>
                        <Button
                            className="salmon-bg btn-float"
                            type="submit"
                            variant="info"
                            onClick={() => props.history.push('/student-registration')}
                        >
                            <Image className="btn-icon" src={ButtonImage} />
                Register
          </Button>
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
    </Modal>

}

export default BookModal;